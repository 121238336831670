import { ReactNode, createContext, useContext } from 'react'
import { Reporter, createSentryReporter } from '../../../../reporter'
import { assertNotNull } from '../../../../typeguards'

export const ReporterContext = createContext<Reporter>(null!)

export function ReporterProvider({
  reporter,
  children,
}: {
  reporter?: Reporter
  children: JSX.Element | ReactNode
}) {
  return (
    <ReporterContext.Provider value={reporter || createSentryReporter()}>
      {children}
    </ReporterContext.Provider>
  )
}

export function useReporter() {
  const reporter = useContext(ReporterContext)
  assertNotNull(reporter)
  return reporter
}
