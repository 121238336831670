import * as Sentry from '@sentry/react'
import { Config } from './config'
import {
  ConfigProvider,
  DepsProvider,
  FeatureFlagProvider,
  ReporterProvider,
} from './modules/common/contexts'
import { UserApp } from './modules/user/app'
import { StripeProvider } from './modules/user/contexts'
import { OpsApp } from './modules/ops/app'
import { Route, Routes } from 'react-router-dom'
import { AdminApp } from './modules/admin/app'

function App({ config }: { config: Config }) {
  return (
    <>
      <ConfigProvider config={config}>
        <DepsProvider>
          <ReporterProvider>
            <StripeProvider>
              <FeatureFlagProvider>
                {/**
                 * check this out for nested routes
                 * https://reactrouter.com/en/main/start/faq#how-do-i-nest-routes-deep-in-the-tree
                 */}
                <Routes>
                  <Route path="/admin/*" element={<AdminApp />} />
                  <Route path="/ops/*" element={<OpsApp />} />
                  <Route path="/*" element={<UserApp />} />
                </Routes>
              </FeatureFlagProvider>
            </StripeProvider>
          </ReporterProvider>
        </DepsProvider>
      </ConfigProvider>
    </>
  )
}

export default Sentry.withProfiler(App)
