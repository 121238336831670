import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  ja: {
    translation: {
      Admin: '管理者',
      'Are you sure you want to delete this print device?': 'このプリンターを削除しますか？',
      'Are you sure you want to delete this print queue?': 'このプリンターキューを削除しますか？',
      'Are you sure you want to reset this print device?': 'このプリンターをリセットしますか？',
      'Are you sure you want to reset this print queue?':
        'このプリンターキューをリセットしますか？',
      Block: '保留',
      Blocked: '保留中',
      'Broadcast Histories': '送信履歴',
      Broadcast: 'メッセージ一斉送信',
      'Bulk Message': 'メッセージ一斉送信',
      Cancel: 'キャンセル',
      calculating: '算定中',
      'Card Number': 'カード番号',
      'checked in': '受付済み',
      Clinic: 'クリニック',
      'Clinic Affiliations': '所属クリニック',
      'Clinic changed': 'クリニックが変更されました',
      Close: '閉じる',
      'Check-In List': 'チェックイン一覧',
      CheckedInAt: 'チェックイン時刻',
      CheckInID: 'チェックインID',
      Checkout: '会計',
      'Checkout notification': '会計通知',
      'Checkout without notification': '会計通知しない',
      CloudPrnt: 'CloudPrnt',
      Create: '作成',
      'Create Print Device': 'プリンター登録',
      'Create Print Queue': 'プリンターキュー登録',
      CreatedAt: '作成日',
      CVC: 'セキュリティコード',
      Dashboard: 'ダッシュボード',
      Date: '日付',
      Datetime: '日時',
      Delete: '削除',
      'Delete Print Device': 'プリンター削除',
      'Delete Print Queue': 'プリンターキュー削除',
      'Enter CVC': 'CVC',
      Email: 'Eメール',
      Expiration: '有効期限',
      'Failed to change clinic': 'クリニックの変更に失敗しました\n{{error}}',
      'Failed to download receipt': '領収書の取得に失敗しました\n{{error}}',
      'Failed to get consultations': '診察の取得に失敗しました\n{{error}}',
      'Failed to get staff': 'スタッフの取得に失敗しました\n{{error}}',
      'Failed to initialize staff': 'スタッフの初期化に失敗しました\n{{error}}',
      'Failed to login': 'ログインに失敗しました\n{{error}}',
      'Failed to perform action': '{{action}}に失敗しました\n{{error}}',
      'Failed to send messsages': 'メッセージ送信に失敗しました',
      'Failed to block consultation': '診察の保留中への変更に失敗しました。\n{{error}}',
      'Failed to unblock consultation': '診察の保留状態からの変更に失敗しました。\n{{error}}',
      Family: 'ファミリー',
      'No Line Account': 'LINE登録無し',
      'Last consultation': '前回',
      'logging in ...': 'ログイン中 ...',
      Login: 'ログイン',
      Logout: 'ログアウト',
      'Initial consultation': '初診',
      'in consultation': '診察中',
      Insurance: '保険',
      'Interview Form': '問診あり',
      'Invalid PatientID': '{{value}} は不正な患者番号です',
      'Invoice Number': '請求書番号: {{value}}',
      'Notify checkout': '会計通知する',
      'Make Payment': '支払う',
      Message: 'メッセージ',
      Messages: 'メッセージ',
      Name: '名前',
      Password: 'パスワード',
      PatientID: '患者番号',
      PatientIDs: '患者番号',
      PatientUUID: '患者UUID',
      PatientUUIDs: '患者UUID',
      'Patient List': '患者一覧',
      'Patient Not Found': '患者が見つかりません',
      'Pay with Credit Card': 'クレジットカードでお支払い',
      'payment completed': '会計済み',
      'Prescription Token': '処方箋引換券',
      'Print Devices': 'プリンター',
      'Print Queues': 'プリンターキュー',
      'Print job submitted successfully': '印刷ジョブを送信しました',
      'Regenerate Receipt': '領収書の再発行',
      'Reception Number': '受付番号',
      'Receipt not found or set undisplayed':
        '領収書が「表示しない」に設定されている、もしくは見つかりません',
      Register: '登録',
      RegisteredAt: '登録日',
      ReservationID: '予約ID',
      ReserveWithImpersonation: '代理予約',
      Reset: 'リセット',
      'Reset Print Device': 'プリンターリセット',
      'Reset Print Queue': 'プリンターキューリセット',
      'Clinicplus Admin': 'Clinic+ 管理',
      Send: '送信',
      'Send Broadcast': '一斉送信',
      Search: '検索',
      'Select Date': '日付選択',
      'Self-pay': '自費',
      Setting: '設定',
      'Sign in': 'ログイン',
      Signage: 'サイネージ',
      Sokushin: '即診',
      'staff registration required': 'スタッフの登録が必要です',
      Staffs: 'スタッフ',
      'Submit Print Job': '印刷ジョブを送信',
      'Succeeded in sending messsages': 'メッセージを送信しました',
      'Succeeded to block consultation': '診察が保留中に変更されました',
      'Succeeded to initialize staff': 'スタッフの初期化に成功しました',
      'Succeeded to login': 'ログインしました',
      'Succeeded to unblock consultation': '診察が保留状態から戻されました',
      'This field is required': '必須項目です',
      Unblock: '保留解除',
      'unknown error': '不明なエラーが発生しました',
      Update: '更新',
      'Update Staff': 'スタッフ更新',
      'in payment': '会計中',
    },
  },
}

export const configureI18n = () => {
  i18n.use(initReactI18next).init({
    resources,
    lng: 'ja',
  })
}
