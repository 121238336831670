import { useAuthState } from 'react-firebase-hooks/auth'
import { useGoogleLogin } from '@react-oauth/google'
import { GoogleAuthProvider, User, signInWithCredential, signOut } from 'firebase/auth'
import { createAuth } from '../../../config/firebase'
import { useConfig } from '../contexts'

export function useAuth() {
  const { config } = useConfig()
  const auth = createAuth(config)

  const [user, loading, error] = useAuthState(auth)

  const getIDToken = (user: User): Promise<string> => {
    return user.getIdToken()
  }

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const credential = GoogleAuthProvider.credential(null, tokenResponse.access_token)
      signInWithCredential(auth, credential)
    },
  })

  const logout = () => signOut(auth)

  return { currentUser: user, getIDToken, loading, error, login, logout }
}
