import { Container, createTheme, Grid, ThemeProvider, Typography } from '@mui/material'
import { Logo } from '../../common/components/Logo'
import { useLocation } from 'react-router-dom'
import { FormValue } from '../components/TransferToFamilyForm'

const theme = createTheme({
  typography: {
    fontSize: 11,
  },
})

export const TransferToFamilyCompletePage = () => {
  const location = useLocation()
  const formValue = location.state as FormValue

  function formatPhoneNumber(phoneNumber: string): string {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '')
    let match = null

    if (cleaned.length === 10) {
      match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/)
    } else if (cleaned.length === 11) {
      match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/)
    }

    if (match) {
      return match.slice(1).join('-')
    }
    return phoneNumber
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Grid container direction={'column'} alignItems="center" rowSpacing={0.5} sx={{ my: 1 }}>
          <Grid item>
            <Logo />
          </Grid>
          <Grid item>
            <Typography variant="h5" align="center">
              ショートメッセージを
            </Typography>
            <Typography variant="h5" align="center">
              {formatPhoneNumber(formValue.phoneNumber)}へ送信しました
            </Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} sx={{ my: 1 }}>
            <Typography>
              移行許諾のショートメッセージを入力された電話番号にお送りしました。届いたメッセージから移行許可をお願いします。
            </Typography>
            <br />
            <Typography>画面を閉じてください。</Typography>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}
