import { useEffect } from 'react'
import { useClinicsActions, useStaffsActions } from '../../common/features'
import { useAppDispatch, useAppSelector } from '../store'
import { useAuth } from '../../common/hooks'
import { useNotification } from '../../common/contexts'
import { useTranslation } from 'react-i18next'

export function useClinics({ clinicID }: { clinicID?: string | null } = {}) {
  const { currentUser, getIDToken } = useAuth()
  const dispatch = useAppDispatch()
  const { getStaff } = useStaffsActions()
  const { getClinic, getClinics } = useClinicsActions()
  const { notify } = useNotification()
  const { t } = useTranslation()
  const staff = useAppSelector((state) => state.staffs.current)
  const clinics = useAppSelector((state) => state.clinics.entries)
  const currentClinic = useAppSelector((state) => state.clinics.current)
  const loading = useAppSelector((state) => state.clinics.loading)

  const changeCurrentClinic = (clinicID: string) => {
    if (currentUser) {
      const change = async () => {
        try {
          const token = await getIDToken(currentUser)
          await dispatch(getClinic({ token, staffID: currentUser.uid, clinicID }))
          notify({
            message: t('Clinic changed'),
            severity: 'info',
          })
        } catch (e) {
          const error = e instanceof Error ? e.message : t('unknown error')
          notify({
            message: t('Failed to change clinic', { error }),
            severity: 'error',
          })
        }
      }
      change()
    }
  }

  useEffect(() => {
    if (currentUser) {
      getIDToken(currentUser).then((idToken) => {
        dispatch(getClinics({ token: idToken }))
      })
    }
  }, [currentUser])

  useEffect(() => {
    if (currentUser) {
      getIDToken(currentUser).then((idToken) => {
        dispatch(getStaff({ token: idToken, staffID: currentUser.uid }))
      })
    }
  }, [currentUser])

  useEffect(() => {
    if (currentUser && clinicID) {
      getIDToken(currentUser).then((idToken) => {
        dispatch(getClinic({ token: idToken, staffID: currentUser.uid, clinicID }))
      })
    }
  }, [clinicID, currentUser])

  const staffClinics = (clinics || []).filter((clinic) =>
    staff?.clinics?.some((c) => c.clinicID === clinic.clinicID && c.enabled)
  )

  return {
    clinics: staffClinics,
    currentClinic,
    changeCurrentClinic,
    loading,
  }
}
