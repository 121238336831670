import { useTranslation } from 'react-i18next'
import {
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { AdminTemplate } from '../templates'
import { useNotification } from '../../common/contexts'
import { useAuth } from '../../common/hooks'
import { useAppDispatch, useAppSelector } from '../store'
import { useClinicsActions, useStaffsActions } from '../../common/features'
import { useEffect, useState } from 'react'
import { UpdateStaffDialog } from '../components/UpdateStaffDialog'

export function StaffPage() {
  const { notify } = useNotification()
  const { currentUser, getIDToken } = useAuth()
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const offset = page * rowsPerPage

  const dispatch = useAppDispatch()
  const { getClinics } = useClinicsActions()
  const { getStaffs, updateStaff } = useStaffsActions()
  const result = useAppSelector((state) => state.staffs.page)
  const clinics = useAppSelector((state) => state.clinics.entries)

  const handleGetClinics = async () => {
    if (currentUser) {
      try {
        const token = await getIDToken(currentUser)
        await dispatch(getClinics({ token }))
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to get clinics', { error }),
          severity: 'error',
        })
      }
    }
  }

  const handleGetStaffs = async (params: { offset?: number; limit?: number }) => {
    if (currentUser) {
      try {
        const token = await getIDToken(currentUser)
        await dispatch(getStaffs({ token, offset: params.offset, limit: params.limit }))
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to get staffs', { error }),
          severity: 'error',
        })
      }
    }
  }

  const handleUpdateStaff = async ({
    staffID,
    clinics,
  }: {
    staffID: string
    clinics: Array<{ clinicID: string }>
  }) => {
    if (currentUser) {
      try {
        const token = await getIDToken(currentUser)
        await dispatch(updateStaff({ token, staffID, clinics }))
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to update staff', { error }),
          severity: 'error',
        })
      }
    }
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    handleGetClinics()
  }, [])

  useEffect(() => {
    handleGetStaffs({ offset, limit: rowsPerPage })
  }, [offset, rowsPerPage])

  console.log(offset, page, rowsPerPage)
  console.log(result)

  return (
    <AdminTemplate>
      <Stack spacing={3}>
        <Box sx={{ width: '100%' }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2" fontWeight="bold">
                {t('Staffs')}
              </Typography>
            </Grid>
          </Grid>
          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight="bold">{t('Name')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">{t('Email')}</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {result &&
                  result.entries.map((staff) => (
                    <TableRow key={staff.uid}>
                      <TableCell>{staff.name}</TableCell>
                      <TableCell>{staff.email}</TableCell>
                      <TableCell>
                        <UpdateStaffDialog
                          staff={staff}
                          clinics={clinics || []}
                          onSubmit={handleUpdateStaff}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ pt: 3 }}
          >
            <Grid item xs={12}>
              <TablePagination
                component="div"
                count={result?.count || 0}
                page={offset}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </AdminTemplate>
  )
}
