import { Alert, Box, Button, Container, CssBaseline, Grid } from '@mui/material'
import { useAuth } from '../../common/hooks'
import { useAppDispatch, useAppSelector } from '../store'
import { useStaffsActions } from '../../common/features'
import { Logo } from '../../common/components'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useNotification } from '../../common/contexts'

export function StaffInitPage() {
  const { currentUser, getIDToken } = useAuth()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { notify } = useNotification()
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const { initStaff, getStaff } = useStaffsActions()
  const { current: staff, loading, error } = useAppSelector((state) => state.staffs)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (currentUser) {
      const submit = async () => {
        try {
          const token = await getIDToken(currentUser)
          await dispatch(initStaff({ token }))
          notify({
            message: t('Succeeded to initialize staff'),
            severity: 'success',
          })
        } catch (e) {
          const error = e instanceof Error ? e.message : t('unknown error')
          notify({
            message: t('Failed to initialize staff', { error }),
            severity: 'success',
          })
        }
      }
      submit()
    }
  }

  useEffect(() => {
    if (currentUser) {
      const fetch = async () => {
        try {
          const token = await getIDToken(currentUser)
          await dispatch(getStaff({ token, staffID: currentUser.uid }))
        } catch (e) {
          const error = e instanceof Error ? e.message : t('unknown error')
          notify({
            message: t('Failed to get staff', { error }),
            severity: 'error',
          })
        }
      }
      fetch()
    }
  }, [currentUser, staff])

  useEffect(() => {
    if (staff !== null) {
      navigate({
        pathname: '/ops/dashboard',
        search: searchParams.toString(),
      })
    }
  }, [staff])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <Container sx={{ padding: '2em' }} maxWidth="lg">
          <Grid container alignItems="center" justifyContent="center" direction="column">
            <Grid item xs={12} sx={{ mb: '2em' }}>
              <Logo />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ width: '100%' }}>
                {error && error.status === 404 && (
                  <Box sx={{ mb: 2 }}>
                    <Alert severity="warning">{t('staff registration required')}</Alert>
                  </Box>
                )}
                <Button
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  fullWidth
                  onClick={handleSubmit}
                >
                  {t('Register')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
