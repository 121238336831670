import { isAfter, isBefore, isEqual, parseISO } from 'date-fns'
import { InvalidValueError } from '../../../../errors'
import { isString } from '../../../../typeguards'

export const validatePatientUUID = (v: string) => {
  if (!isString(v)) {
    throw new InvalidValueError(v)
  }
}

export const birthdayToAge = (birthday: string): number => {
  const today = new Date()
  const d = parseISO(birthday)
  const birthdayThisYear = new Date(today.getFullYear(), d.getMonth(), d.getDate())
  const age = today.getFullYear() - d.getFullYear()
  if (isBefore(today, birthdayThisYear)) {
    return age - 1
  }
  return age
}

const isEqualOrAfter = (d1: Date, d2: Date) => {
  return isEqual(d1, d2) || isAfter(d1, d2)
}

/**
 * 和暦の誕生日に変換する
 */
export const birthdayInImperialYear = (birthday: string): string => {
  const d = parseISO(birthday)

  const eras = [
    // 令和: May 1, 2019
    {
      name: '令和',
      cond: (d: Date) => isEqualOrAfter(d, parseISO('2019-05-01')),
      start: 2018,
    },
    // 平成: Jan 8, 1989
    {
      name: '平成',
      cond: (d: Date) =>
        isBefore(d, parseISO('2019-05-01')) && isEqualOrAfter(d, parseISO('1989-01-08')),
      start: 1988,
    },
    // 昭和: Dec 25, 1926
    {
      name: '昭和',
      cond: (d: Date) =>
        isBefore(d, parseISO('1989-01-08')) && isEqualOrAfter(d, parseISO('1926-12-25')),
      start: 1925,
    },
    // 大正: Jul 30, 1912
    {
      name: '大正',
      cond: (d: Date) =>
        isBefore(d, parseISO('1926-12-25')) && isEqualOrAfter(d, parseISO('1912-07-30')),
      start: 1911,
    },
    // 明治: Jan 25, 1868
    {
      name: '明治',
      cond: (d: Date) => isBefore(d, parseISO('1912-07-30')),
      start: 1867,
    },
  ]

  for (const { name, cond, start } of eras) {
    if (cond(d)) {
      const diff = d.getFullYear() - start
      const year = diff === 1 ? '元' : diff
      const month = d.getMonth() + 1
      const date = d.getDate()
      return `${name}${year}年${month}月${date}日`
    }
  }

  throw new Error('not supported')
}
