class DomainError extends Error {
  code: string

  constructor(errorCode: string, message: string) {
    super(message)
    this.code = errorCode
    this.name = new.target.name
    Object.setPrototypeOf(this, new.target.prototype)
  }

  toJson() {
    return {
      error: {
        code: this.code,
        message: this.message,
      },
    }
  }
}

export class InvalidValueError extends DomainError {
  constructor(value: unknown) {
    super('invalid value', `${value} is invalid`)
  }
}

export class ValidationError extends DomainError {
  value: unknown
  errors: Array<string>

  constructor(value: unknown, errors: Array<string>) {
    super('validation error', `${value} is invalid`)
    this.value = value
    this.errors = errors
  }

  toJson() {
    return {
      error: {
        code: this.code,
        message: this.message,
        detail: this.errors,
      },
    }
  }
}
