import { ReactNode } from 'react'
import { Typography, TypographyProps } from '@mui/material'

export function Title({
  children,
  ...props
}: {
  children: JSX.Element | ReactNode | string
} & TypographyProps & {
    'data-cy'?: string
  }) {
  return (
    <Typography variant="h5" fontWeight="bold" {...props}>
      {children}
    </Typography>
  )
}
