import { useTranslation } from 'react-i18next'
import { Chip } from '@mui/material'
import CreditCardIcon from '@mui/icons-material/CreditCard'

export function SokushinChip() {
  const { t } = useTranslation()

  return (
    <Chip icon={<CreditCardIcon fontSize="small" />} label={t('Sokushin')} variant="outlined" />
  )
}
