import { useTranslation } from 'react-i18next'
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { styled, useTheme } from '@mui/material/styles'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LogoutIcon from '@mui/icons-material/Logout'
import TvIcon from '@mui/icons-material/Tv'
import MenuIcon from '@mui/icons-material/Menu'
import { useDrawerToggle } from '../../common/contexts'
import { useAuth } from '../../common/hooks'
import { Profile } from '../../common/components'
import { useClinics } from '../hooks'
import { Link } from 'react-router-dom'

const drawerWidth = 240

const Main = styled(Grid, { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  maxWidth: '100%',
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export function ClinicTemplate({
  clinicID,
  children,
}: {
  clinicID: string | null | undefined
  children: any
}) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { logout } = useAuth()
  const { currentClinic, clinics, changeCurrentClinic } = useClinics({ clinicID })
  const { open, setOpen } = useDrawerToggle()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const onChangeClinic = (e: SelectChangeEvent<string>) => {
    if (e.target?.value) {
      changeCurrentClinic(e.target.value)
    }
  }

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Grid item xs={12}>
          <AppBar position="sticky" open={open}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap fontWeight="bold" component="div">
                {t('Clinicplus Admin')}
              </Typography>
              <Box sx={{ display: { xs: 'none', sm: 'block' }, marginLeft: 'auto' }}>
                <Select
                  labelId="clinic-select-label"
                  value={currentClinic?.clinicID || ''}
                  label={currentClinic?.displayName || ''}
                  style={{ color: '#fff ' }}
                  onChange={onChangeClinic}
                >
                  {clinics?.map((clinic) => (
                    <MenuItem key={clinic.clinicID} value={clinic.clinicID}>
                      {clinic.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Profile />
            </Toolbar>
          </AppBar>
        </Grid>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader style={{ minHeight: 0 }}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItemButton
              component={Link}
              to={'/ops/signage?clinic_id=' + currentClinic?.clinicID}
            >
              <ListItemIcon>
                <TvIcon />
              </ListItemIcon>
              <ListItemText primary={t('Signage')} />
            </ListItemButton>
            <ListItemButton onClick={() => logout()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={t('Logout')} />
            </ListItemButton>
          </List>
          <Divider />
        </Drawer>
        <Main open={open}>
          <DrawerHeader style={{ minHeight: 0 }} />
          {children}
        </Main>
      </Grid>
    </>
  )
}
