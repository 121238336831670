import { Action, configureStore, combineReducers, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { printDevicesSliceReducer, printQueuesSliceReducer } from './features'
import { APIClient } from '../../api'
import { clinicsSliceReducer, staffsSliceReducer } from '../common/features'

const adminReducer = combineReducers({
  clinics: clinicsSliceReducer,
  printDevices: printDevicesSliceReducer,
  printQueues: printQueuesSliceReducer,
  staffs: staffsSliceReducer,
})

export const createStore = (deps: { api: APIClient }) =>
  configureStore({
    reducer: adminReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: { extraArgument: deps },
      }),
  })

export type AppStore = ReturnType<typeof createStore>
export type OpsState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, OpsState, unknown, Action<string>>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<OpsState> = useSelector
