import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Clinic, ErrorMessage } from '../../../common/types'
import { createContext, useContext } from 'react'
import { toErrorMessage } from '../../utils/errors'
import { APIClient } from '../../../../api'

type ClinicsState = {
  current: Clinic | null
  entries: Array<Clinic> | null
  loading: boolean
  error: ErrorMessage | null
}

const initialState: ClinicsState = {
  current: null,
  entries: [],
  loading: false,
  error: null,
}

type ThunkAPI = {
  extra: {
    api: APIClient
  }
}

const getClinic = createAsyncThunk<
  Clinic,
  {
    token: string
    staffID: string
    clinicID: string
  },
  ThunkAPI
>('clinics/getClinic', async ({ token, staffID, clinicID }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    const res = await api.getStaffClinic({
      token,
      staffID,
      clinicID,
    })
    return res
  } catch (e) {
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const getClinics = createAsyncThunk<
  { entries: Array<Clinic> },
  {
    token: string
  },
  ThunkAPI
>('clinics/getClinics', async ({ token }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    const res = await api.getClinics({
      token,
    })
    return res
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.log('rejected ...', e.cause)
    }
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const clinicsSlice = createSlice({
  name: 'clinics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClinics.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getClinics.fulfilled, (state, action) => {
        state.loading = false
        state.entries = action.payload.entries
        state.error = null
      })
      .addCase(getClinics.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(getClinic.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getClinic.fulfilled, (state, action) => {
        state.loading = false
        state.current = action.payload
        state.error = null
      })
      .addCase(getClinic.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })
  },
})

export const clinicsActions = {
  getClinics,
  getClinic,
}

export const clinicsSliceReducer = clinicsSlice.reducer

export const ClinicsActionsContext = createContext(clinicsActions)

export const useClinicsActions = () => useContext(ClinicsActionsContext)

export const ClinicsActionsProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ClinicsActionsContext.Provider value={clinicsActions}>
      {children}
    </ClinicsActionsContext.Provider>
  )
}
