import { ReactNode, createContext, useContext } from 'react'
import {
  APIClient as V2APIClient,
  ClinicplusAPIClient as ClinicplusV2APIClient,
} from '../../../api'
import { APIClient, ClinicplusAPIClient } from '../../../clients/apiclient'
import { useConfig } from './Config'
import { ILocalStorage, LocalStorage } from '../../../localStorage'
import { ConsoleLogger, Logger, LoggerWithSentry } from '../../../logger'
import { DateTimeUtil } from '../../../utils/dateTime'
import { FullNameFormatter, JPFullNameFormatter } from '../../../utils/formatters'
import { HTTPClient, IHTTPClient } from '../../../clients'
import { createAuth } from '../../../config/firebase'
import { assertNotNull } from '../../../typeguards'

export type Deps = {
  auth: ReturnType<typeof createAuth>
  httpClient: IHTTPClient
  apiClient: APIClient
  api: V2APIClient
  localStorage: ILocalStorage
  logger: Logger
  dateTimeUtil: DateTimeUtil
  fullNameFormatter: FullNameFormatter
}

export const DepsContext = createContext<Partial<Deps>>({})

export const DepsProvider = ({ children, deps }: { children: ReactNode; deps?: Partial<Deps> }) => {
  const { config } = useConfig()
  const auth = createAuth(config)
  const httpClient = HTTPClient.create()
  const apiClient = ClinicplusAPIClient.create({
    baseURL: config.apiBaseURL,
    httpClient: (deps || {}).httpClient || httpClient,
  })
  const api = ClinicplusV2APIClient.create({
    baseURL: config.apiBaseURL,
  })
  const localStorage = new LocalStorage()
  const logger = new LoggerWithSentry({
    logger: new ConsoleLogger(),
  })
  const dateTimeUtil = new DateTimeUtil({ timeZone: 'Asia/Tokyo' })
  const fullNameFormatter = new JPFullNameFormatter()
  const value = {
    auth,
    httpClient,
    apiClient,
    api,
    localStorage,
    logger,
    dateTimeUtil,
    fullNameFormatter,
    ...deps,
  }
  return <DepsContext.Provider value={value}>{children}</DepsContext.Provider>
}

export const useDeps = () => {
  const {
    auth,
    httpClient,
    apiClient,
    api,
    localStorage,
    logger,
    dateTimeUtil,
    fullNameFormatter,
  } = useContext(DepsContext)
  assertNotNull(auth)
  assertNotNull(httpClient)
  assertNotNull(apiClient)
  assertNotNull(api)
  assertNotNull(localStorage)
  assertNotNull(logger)
  assertNotNull(dateTimeUtil)
  assertNotNull(fullNameFormatter)
  return {
    auth,
    httpClient,
    apiClient,
    api,
    localStorage,
    logger,
    dateTimeUtil,
    fullNameFormatter,
  }
}
