export const isString = (v: unknown): v is string => {
  return typeof v === 'string'
}

export const isObject = (v: unknown): v is Object => {
  return v !== null && (typeof v === 'object' || typeof v === 'function')
}

export const isDefined = <T>(v: T | undefined | null): v is T => {
  return v !== undefined && v !== null
}

export function assertNotNull<T>(v: T | undefined | null): asserts v is T {
  if (!isDefined(v)) {
    throw new Error('assertion error')
  }
}
