import React, { createContext, useContext } from 'react'
import { Config } from '../../../config/config'

export const ConfigContext = createContext<{ config: Config }>({
  config: Config.testing(),
})

export const ConfigProvider = ({
  config,
  children,
}: {
  config: Config
  children: React.ReactNode
}) => {
  return <ConfigContext.Provider value={{ config }}>{children}</ConfigContext.Provider>
}

export const useConfig = () => useContext(ConfigContext)
