import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
} from '@mui/material'
import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField } from '@mui/material'
import { HelpIcon } from '../../common/components'

type Inputs = {
  name: string
  position: number | string
  designTemplate: string
}

type OnSubmit = (data: Inputs) => Promise<void>

export function CreatePrintQueueDialog(props: { onSubmit: OnSubmit }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    props.onSubmit(data)
    handleClose()
  }

  return (
    <>
      <Button onClick={handleClickOpen} color="primary" variant="contained">
        {t('Create')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="action-dialog-title"
        aria-describedby="action-dialog-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="action-dialog-title">{t('Create Print Queue')}</DialogTitle>
          <DialogContent>
            <Box p={2}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label={t('Name')}
                    fullWidth
                    {...register('name', { required: true })}
                  />
                  {errors.name && <span>This field is required</span>}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Position')}
                    fullWidth
                    {...register('position', { required: false })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Design Template')}
                    fullWidth
                    multiline
                    rows={10}
                    {...register('designTemplate', { required: false })}
                  />
                  <HelpIcon
                    helperText={
                      <Link
                        href="https://star-m.jp/products/s_print/sdk/StarDocumentMarkup/manual/en/about.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://star-m.jp/products/s_print/sdk/StarDocumentMarkup/manual/en/about.html
                      </Link>
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" sx={{ color: '#333' }}>
              {t('Cancel')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('Create')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
