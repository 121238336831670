import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ErrorMessage } from '../../../common/types'
import { APIClient } from '../../../../api'
import { PrintQueue } from '../../types'
import { toErrorMessage } from '../../../common/utils/errors'
import { createContext, useContext } from 'react'

type PrintQueuesState = {
  entries: Array<PrintQueue>
  loading: boolean
  error: ErrorMessage | null
}

const initialState: PrintQueuesState = {
  entries: [],
  loading: false,
  error: null,
}

type ThunkAPI = {
  extra: {
    api: APIClient
  }
}

const createPrintQueue = createAsyncThunk<
  void,
  {
    token: string
    name: string
    position: number
    designTemplate: string
  },
  ThunkAPI
>('printQueues/createPrintQueue', async ({ token, name, position, designTemplate }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    await api.createPrintQueue({
      token,
      name,
      position,
      designTemplate,
    })
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.log('rejected ...', e.cause)
    }
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const getPrintQueues = createAsyncThunk<
  { entries: Array<PrintQueue> },
  {
    token: string
  },
  ThunkAPI
>('printQueues/getPrintQueues', async ({ token }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    const res = await api.getPrintQueues({
      token,
    })
    return res
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.log('rejected ...', e.cause)
    }
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const deletePrintQueue = createAsyncThunk<
  void,
  {
    token: string
    queueID: number
  },
  ThunkAPI
>('printQueues/deletePrintQueue', async ({ token, queueID }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    await api.deletePrintQueue({
      token,
      queueID,
    })
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.log('rejected ...', e.cause)
    }
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const resetPrintQueue = createAsyncThunk<
  void,
  {
    token: string
    queueID: number
  },
  ThunkAPI
>('printQueues/resetPrintQueue', async ({ token, queueID }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    await api.resetPrintQueue({
      token,
      queueID,
    })
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.log('rejected ...', e.cause)
    }
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const printQueuesSlice = createSlice({
  name: 'printQueues',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPrintQueue.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createPrintQueue.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(createPrintQueue.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(getPrintQueues.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getPrintQueues.fulfilled, (state, action) => {
        state.loading = false
        state.entries = action.payload.entries
        state.error = null
      })
      .addCase(getPrintQueues.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(deletePrintQueue.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deletePrintQueue.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(deletePrintQueue.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(resetPrintQueue.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(resetPrintQueue.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(resetPrintQueue.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })
  },
})

export const printQueuesActions = {
  createPrintQueue,
  getPrintQueues,
  deletePrintQueue,
  resetPrintQueue,
}

export const printQueuesSliceReducer = printQueuesSlice.reducer

export const PrintQueuesActionsContext = createContext(printQueuesActions)

export const usePrintQueuesActions = () => useContext(PrintQueuesActionsContext)

export const PrintQueuesActionsProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <PrintQueuesActionsContext.Provider value={printQueuesActions}>
      {children}
    </PrintQueuesActionsContext.Provider>
  )
}
