import { useEffect, useState } from 'react'
import { useDeps } from '../../common/contexts'

type PatientPartial = {
  address: string
  city: string
  phoneNumber: string
  prefecture: string
  zipcode: string
}

export type Patient = PatientPartial & {
  firstName: string
  lastName: string
  firstNameKana: string
  lastNameKana: string
  birthday: string
  gender: '男性' | '女性'
  healthInsuranceCard?: {
    name: string
    fileKey: string
  }
}

export const usePatient = (idToken: string | null, patientUUID: string | null) => {
  const { apiClient } = useDeps()
  const [patient, setPatient] = useState<Patient | null>(null)

  useEffect(() => {
    const func = async () => {
      if (!idToken || !patientUUID) {
        return
      }
      try {
        const res = await apiClient.getPatient({
          idToken,
          patientUUID,
        })
        setPatient(res)
      } catch (e) {
        console.error(e)
      }
    }
    func()
  }, [])

  return patient
}

export const usePatientMe = (idToken: string | null) => {
  const { apiClient } = useDeps()
  const [patient, setPatient] = useState<PatientPartial | null>(null)

  useEffect(() => {
    const func = async () => {
      if (!idToken) {
        return
      }
      try {
        const res = await apiClient.getPatientMe({
          lineIDToken: idToken,
        })
        setPatient(res)
      } catch (e) {
        console.error(e)
      }
    }
    func()
  }, [])

  return patient
}
