import { ReactNode } from 'react'
import { Backdrop, Box, CircularProgress } from '@mui/material'

export function Loading({ message }: { message?: ReactNode }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        {message}
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}
