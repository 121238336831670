import { Context, FallbackFunction } from './interface'

export const createFallbackFunction = (
  v: boolean | FallbackFunction | undefined
): FallbackFunction => {
  if (v == null) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (name: string, context: Context) => false
  }
  if (typeof v === 'boolean') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (name: string, context: Context) => v
  }
  return v
}
