export class APIClientError extends Error {
  constructor(message: string) {
    super(message)
    this.name = new.target.name
    Object.setPrototypeOf(this, new.target.prototype)
  }

  toJson() {
    return {
      error: {
        message: this.message,
      },
    }
  }
}

export class Unauthenticated extends APIClientError {
  constructor(message: string = 'unauthenticated') {
    super(message)
  }
}

export class IDTokenExpiredError extends Unauthenticated {
  constructor() {
    super('id token expired')
  }
}

export class Conflict extends APIClientError {
  constructor(message: string = 'conflict') {
    super(message)
  }
}

export class NotFound extends APIClientError {
  constructor(message: string = 'not found') {
    super(message)
  }
}
