import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { useConfig } from '../../common/contexts'
import { Loading } from '../../common/components'

export const StripeContext = createContext<{
  stripe?: Stripe
}>({})

export const StripeProvider = ({ children }: { children: JSX.Element | ReactNode }) => {
  const { config } = useConfig()
  const [stripe, setStripe] = useState<Stripe | null>(null)

  useEffect(() => {
    loadStripe(config.stripePublishableKey)
      .then((value) => setStripe(value))
      .catch((e) => {
        console.error(e)
        throw e
      })
  }, [config, setStripe])

  if (!stripe) {
    return <Loading />
  }

  return (
    <StripeContext.Provider
      value={{
        stripe,
      }}
    >
      {children}
    </StripeContext.Provider>
  )
}

export function useStripe() {
  const { stripe } = useContext(StripeContext)

  if (!stripe) {
    throw new Error('stripe is not set')
  }

  return { stripe }
}
