import React from 'react'
import { FormControlLabel, Radio, RadioGroup, RadioProps } from '@mui/material'
import useGetClinics from '../hooks/useGetClinics'
import { useFormContext } from 'react-hook-form'

type SelectClinicFieldProps = {
  name?: string
} & RadioProps

const rules = {
  required: '選択してください',
}

export const SelectClinicField: React.FC<SelectClinicFieldProps> = React.forwardRef(
  (props, ref) => {
    const name = props.name || 'clinicID'
    const { register } = useFormContext()
    const clinics = useGetClinics()

    const registerReturn = register(name, rules)

    return (
      <RadioGroup row>
        {clinics.map((clinic) => (
          <FormControlLabel
            key={clinic.clinicID}
            value={clinic.clinicID}
            label={clinic.clinicShortName}
            data-cy={`clinic-option-${clinic.clinicID}`}
            ref={ref}
            control={<Radio {...registerReturn} inputProps={{ ...props.inputProps }} />}
          />
        ))}
      </RadioGroup>
    )
  }
)
