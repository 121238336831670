import { useLocation } from 'react-router-dom'
import { Alert, Box, Button, Grid, Typography } from '@mui/material'
import { useConfig } from '../../common/contexts'
import { QRCodeImage } from '../components/QRCodeImage'
import { BrowserView, MobileView, isAndroid, isIOS } from 'react-device-detect'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'

export function LandingPage() {
  const search = useLocation().search
  const queryParams = new URLSearchParams(search)
  const { config } = useConfig()
  const qrcodeValue = `${config.liffBaseRedirectURL}/landing?${queryParams.toString()}`

  let deepLinkURL: string
  if (isAndroid) {
    deepLinkURL =
      qrcodeValue.replace('https://', 'intent://') +
      '#Intent;scheme=https;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=jp.naver.line.android;end'
  } else if (isIOS) {
    deepLinkURL =
      'line://app/' + config.liffID + '?' + queryParams.toString() + '&redirect_path=landing'
  } else {
    deepLinkURL = qrcodeValue
  }

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID || '')
    ReactGA.event('landing_page_transitioned', {
      queryParams: queryParams.toString(),
      client_id: queryParams.get('client_id'),
      session_id: queryParams.get('session_id'),
      deepLinkURL,
    })
  }, [])

  return (
    <>
      <BrowserView>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          direction="column"
          sx={{ mt: 4 }}
        >
          <Grid item xs={12}>
            <Typography>スマートフォンでバーコードを読み取ってください</Typography>
            <Typography>LINEに遷移します</Typography>
          </Grid>
          <Grid item xs={12} sx={{ p: 0 }}>
            <QRCodeImage value={qrcodeValue} />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', mt: 6 }}>
          <Typography>スマートフォンで閲覧中の方は</Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', m: 2 }}>
          <Button
            href={deepLinkURL}
            variant="contained"
            sx={{
              borderColor: '#008500',
              backgroundColor: '#00B900',
              boxShadow: 0,
              height: 70,
              width: '300px',
              fontSize: 20,
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#00B900',
              },
            }}
          >
            LINEアプリを開く
          </Button>
        </Grid>
      </BrowserView>
      <MobileView>
        <Grid container alignItems="center" justifyContent="center" sx={{ mt: 4 }}>
          <Grid item xs={12}>
            <Alert icon={<></>} severity="info" sx={{ mx: 2, fontSize: 16 }}>
              ボタンを押してもLINEアプリに遷移しない場合は、Chromeで開いてください。
            </Alert>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Box
              component="img"
              sx={{
                height: 100,
                width: 100,
                my: 3,
                align: 'center',
              }}
              src="/images/LINE_ICON.png"
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">LINEアプリを開いて</Typography>
            <Typography variant="h5">続行してください。</Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', m: 2 }}>
            <Button
              href={deepLinkURL}
              variant="contained"
              fullWidth
              sx={{
                borderColor: '#008500',
                backgroundColor: '#00B900',
                boxShadow: 0,
                height: 70,
                fontSize: 20,
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#00B900',
                },
              }}
            >
              アプリで開く
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', mx: 2 }}>
            <Button
              href="https://line.me/ja/"
              variant="outlined"
              fullWidth
              sx={{
                borderColor: '#666666',
                color: '#666666',
                fontSize: 16,
              }}
            >
              LINEアプリをダウンロード
            </Button>
          </Grid>
        </Grid>
      </MobileView>
    </>
  )
}
