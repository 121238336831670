import { createContext, ReactNode, useContext, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { buildStrategiesFromSettings, FeatureFlag, IFeatureFlag } from '../../../../featureFlag'
import { useDeps } from '../Deps'
import { useConfig } from '../Config'
import { assertNotNull } from '../../../../typeguards'

type FeatureFlagState = {
  loading: boolean
  featureFlag?: IFeatureFlag
}

export const FeatureFlagContext = createContext<FeatureFlagState>({
  loading: true,
})

export function FeatureFlagProvider({ children }: { children: JSX.Element | ReactNode }) {
  const { config } = useConfig()
  const { apiClient } = useDeps()
  const [state, setState] = useState<{
    settings: Array<any>
    initialized: boolean
  }>({
    settings: [],
    initialized: false,
  })

  useEffectOnce(() => {
    const load = async () => {
      const res = await apiClient.getFeatureFlagSettings({})
      setState({
        settings: res,
        initialized: true,
      })
    }
    load()
  })

  const strategies = buildStrategiesFromSettings(config, state.settings)
  const featureFlag = new FeatureFlag({ strategies })

  return (
    <FeatureFlagContext.Provider
      value={{
        loading: !state.initialized,
        featureFlag,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}

export function useFeatureFlag() {
  const { loading, featureFlag } = useContext(FeatureFlagContext)
  assertNotNull(featureFlag)
  return { loading, featureFlag }
}
