'use client'

import { forwardRef, useState, useRef, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import MuiFormLabel, { FormLabelProps } from '@mui/material/FormLabel'
import MuiTextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import PanoramaIcon from '@mui/icons-material/Panorama'
import { ErrorMessage } from './ErrorMessage'
import PostalCodeField, { Address } from './PostalCodeField'
import { Logo } from '../../common/components/Logo'
import { useDeps } from '../../common/contexts'
import { usePatient } from '../hooks/useGetPatient'
import { useGetImageUrl } from '../hooks/useGetImageUrl'
import { useFocus } from '../hooks/useFocus'

type FormValue = {
  formID: string
  zipcode: string
  prefecture: string
  city: string
  address: string
  phoneNumber: string
  healthInsuranceCardFileKey?: string
}

const FormLabel = styled(MuiFormLabel)<FormLabelProps>(() => ({
  fontWeight: 700,
  fontSize: '14px',
}))

const requiredRules = {
  required: '入力してください',
}

const phoneNumberRulesMessage = '数字のみを10または11文字で入力してください'
const phoneNumberRules = {
  required: phoneNumberRulesMessage,
  pattern: {
    value: /^[0-9]{10,11}$/,
    message: phoneNumberRulesMessage,
  },
}

export type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const InputImage = forwardRef<HTMLInputElement, Props>(({ onChange }, ref) => {
  return <input ref={ref} type="file" accept="image/*" onChange={onChange} hidden />
})

const TextField = styled(MuiTextField)({
  '.Mui-disabled': {
    background: '#eaeaea',
  },
})

export function UpdateForm() {
  const search = useLocation().search
  const queryParams = new URLSearchParams(search)
  const { apiClient } = useDeps()

  const methods = useForm<FormValue>({ mode: 'onTouched' })
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid, isDirty },
  } = methods
  const [phoneNumberChanging, setPhoneNumberChanging] = useState(false)
  const [addressChanging, setAddressChanging] = useState(false)
  const [healthInsuranceFile, setHealthInsuranceFile] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { ref: phoneNumberRef, triggerFocus: phoneNumberTriggerFocus } =
    useFocus<HTMLInputElement>()
  const { ref: zipcodeRef, triggerFocus: zipcodeTriggerFocus } = useFocus<HTMLInputElement>()

  const lineIDToken = queryParams.get('id_token') || ''

  const handleHealthInsuranceFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget.files[0]) {
      const imageFile = e.currentTarget.files[0]

      const res = await apiClient.uploadImage({
        lineIDToken,
        imageFile,
        fileName: imageFile.name,
      })

      setValue('healthInsuranceCardFileKey', res.fileKey)
      trigger()
      setHealthInsuranceFile(imageFile)
    }
  }

  const { imageUrl: healthInsuranceFileUrl } = useGetImageUrl({ file: healthInsuranceFile })

  const navigate = useNavigate()

  const patient = usePatient(lineIDToken, queryParams.get('patient_uuid'))

  useEffect(() => {
    if (!isDirty && patient) {
      setValue('zipcode', patient.zipcode)
      setValue('phoneNumber', patient.phoneNumber)
      setValue('prefecture', patient.prefecture)
      setValue('city', patient.city)
      setValue('address', patient.address)
    }
  }, [patient])

  const handlezipcodeSuccess = (address: Address | null) => {
    if (address) {
      setValue('prefecture', address.pref)
      setValue('city', address.city)
      setValue('address', address.town)
      trigger('address')
    }
  }
  const handlezipcodeError = (message: string | null) => {
    if (message) {
      setValue('prefecture', '')
      setValue('city', '')
    }
  }

  const onSubmit = async (data: FormValue) => {
    try {
      await apiClient.sendWebhook(data)
      navigate('/patients/update/complete', { replace: true })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction={'column'} alignItems="center" rowSpacing={0.5} sx={{ my: 1 }}>
          <Grid item>
            <Logo />
          </Grid>
          <Grid item>
            <Typography variant="h5">お客様情報の変更</Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} sx={{ my: 1 }}>
            <Typography>
              今後、保健所への申請や、クリニックからの連絡にこちらの登録情報を利用します。
              <br />
              正確な情報入力のご協力をよろしくお願いします。
            </Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} sx={{ my: 1 }}>
            <Typography sx={{ fontSize: 14 }}>登録されている情報</Typography>
            <Card sx={{ boxShadow: 0, border: 1, backgroundColor: '#eee' }}>
              <CardContent sx={{ p: 2 }}>
                <Typography sx={{ fontSize: 14 }}>
                  {patient?.lastName} {patient?.firstName}
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  {patient?.lastNameKana} {patient?.firstNameKana}
                </Typography>
                <Typography sx={{ fontSize: 14 }}>{patient?.birthday}</Typography>
                <Typography sx={{ fontSize: 14 }}>{patient?.gender}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid container item rowSpacing={0}>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 16, fontWeight: 700 }}>電話番号</Typography>
              <Typography>
                例）09012341234
                <br />
                ※数字のみを入力してください
              </Typography>
              <ErrorMessage errors={errors} name="phoneNumber" />
            </Grid>
            <Grid item xs={6}>
              <Grid my="auto" justifyContent="end" container>
                <Button
                  sx={{ fontSize: 14 }}
                  value="check"
                  variant="outlined"
                  disabled={phoneNumberChanging}
                  aria-label="changePhoneNumber"
                  onClick={() => {
                    if (!phoneNumberChanging) {
                      setPhoneNumberChanging(true)
                      phoneNumberTriggerFocus()
                    }
                  }}
                >
                  変更
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register('phoneNumber', phoneNumberRules)}
                error={!!errors.phoneNumber}
                disabled={!phoneNumberChanging}
                variant="outlined"
                margin="dense"
                inputRef={phoneNumberRef}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '^[0-9]{10,11}$',
                  'aria-label': 'phoneNumber',
                }}
              />
            </Grid>
          </Grid>
          <Grid container item rowSpacing={0}>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 16, fontWeight: 700 }}>住所</Typography>
              <FormLabel>郵便番号</FormLabel>
              <Typography>
                例)1550033
                <br />
                ※数字のみを入力してください
              </Typography>
              <ErrorMessage errors={errors} name="zipcode" />
            </Grid>
            <Grid item xs={6}>
              <Grid my="auto" justifyContent="end" container>
                <Button
                  sx={{ fontSize: 14 }}
                  value="check"
                  variant="outlined"
                  disabled={addressChanging}
                  aria-label="changeAddress"
                  onClick={() => {
                    if (!addressChanging) {
                      setAddressChanging(true)
                      zipcodeTriggerFocus()
                    }
                  }}
                >
                  変更
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item rowSpacing={0}>
            <Grid item xs={6}>
              <PostalCodeField
                name="zipcode"
                disabled={!addressChanging}
                onSuccess={handlezipcodeSuccess}
                onFailure={handlezipcodeError}
                variant="outlined"
                margin="dense"
                ref={zipcodeRef}
                sx={{
                  '.Mui-disabled': {
                    background: '#eaeaea',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container item columnSpacing={2}>
            <Grid item xs={6}>
              <FormLabel>都道府県</FormLabel>
              <TextField
                {...register('prefecture', requiredRules)}
                inputProps={{
                  'aria-label': 'prefecture',
                }}
                disabled
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel>市区町村</FormLabel>
              <TextField
                {...register('city', requiredRules)}
                inputProps={{
                  'aria-label': 'city',
                }}
                disabled
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>番地 建物名・部屋番号</FormLabel>
            <Typography>例）北沢2丁目24-45 シモキタフロント201</Typography>
            <ErrorMessage errors={errors} name="address" />
            <TextField
              {...register('address', requiredRules)}
              error={!!errors.address}
              fullWidth
              variant="outlined"
              disabled={!addressChanging}
              margin="dense"
              inputProps={{
                'aria-label': 'address',
              }}
            />
          </Grid>
          <Grid item container>
            <Grid item xs={6}>
              <FormLabel>保険証</FormLabel>
            </Grid>
            <Grid item xs={6} justifyContent="end" container>
              <Typography sx={{ fontSize: 14 }} fontWeight={700}>
                {patient?.healthInsuranceCard ? '登録済み' : '未登録'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container rowSpacing={1}>
            <Grid item xs={12} sx={{ my: 1, px: 5 }}>
              <Typography fontWeight={700}>
                変更がある場合は、保険証画像をアップロードしてください。
              </Typography>
              <Typography>※表面を設定してください。</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Button
              color="primary"
              variant="outlined"
              sx={{ px: 'auto', fontSize: 14 }}
              startIcon={<PanoramaIcon />}
              component="label"
              aria-label="changeHealthInsuranceCard"
            >
              保険証を変更する
              <InputImage
                ref={fileInputRef}
                onChange={handleHealthInsuranceFileChange}
              ></InputImage>
            </Button>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            {healthInsuranceFileUrl && healthInsuranceFile && (
              <Grid item container xs={12} justifyContent="center">
                <Grid item xs={12} justifyContent="center">
                  <img
                    src={healthInsuranceFileUrl}
                    alt="アップロード画像"
                    style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            item
            style={{
              backgroundColor: '#fbefea',
              margin: '30px calc(50% - 50vw) 0',
              padding: '0 calc(50vw - 50%)',
              width: '100vw',
            }}
          >
            <Grid
              container
              item
              xs={2}
              justifyContent={'flex-end'}
              alignItems={'center'}
              paddingRight={'10px'}
            >
              <InfoIcon color="warning" sx={{ fontSize: 20 }} />
            </Grid>
            <Grid item xs={10} sx={{ py: 2 }}>
              <Typography color="primary" fontWeight={700}>
                今後の連絡にこちらの登録情報を利用します。
                <br />
                正確な情報入力か、今一度ご確認ください。
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pb: 3 }}>
              <Button
                aria-label="submit"
                type="submit"
                variant="contained"
                disabled={!isValid}
                fullWidth
                size="large"
                color="primary"
                sx={{
                  py: 1.5,
                  fontSize: 14,
                  fontWeight: 700,
                  '&.Mui-disabled': {
                    background: '#eaeaea',
                    color: '#fff',
                  },
                }}
              >
                内容を確認したので登録する
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <input type="hidden" {...register('formID')} value="patientUpdateForm" />
        {
          /* pass all query parameters to this form */
          // @ts-ignore
          (() => {
            const inputs: any = []
            queryParams.forEach((value, key) => {
              inputs.push(
                <input
                  type="hidden"
                  {
                    //@ts-ignore as 'key' here may be undefined in FormValue type
                    ...register(key)
                  }
                  key={`hidden-input-${key}`}
                  name={key}
                  value={value}
                  data-testid={`hidden-input-${key}`}
                />
              )
            })
            return inputs
          })()
        }
      </form>
    </FormProvider>
  )
}
