import React from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Tooltip } from '@mui/material'

export type HelpIconProps = {
  helperText?: React.ReactNode
}

export function HelpIcon(props: HelpIconProps) {
  if (!props.helperText) {
    return <HelpOutlineIcon />
  }

  return (
    <Tooltip title={props.helperText}>
      <HelpOutlineIcon />
    </Tooltip>
  )
}
