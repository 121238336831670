import React from 'react'
import { Container, Grid } from '@mui/material'
import { Logo } from '../../common/components/Logo'

export type PaymentTemplateProps = {
  LogoComponent?: React.ElementType
  title: React.ReactNode
  content?: React.ReactNode
  footer?: React.ReactNode
  backgroundColor?: string
}

export function PaymentTemplate({
  LogoComponent,
  title,
  content,
  footer,
  backgroundColor,
}: PaymentTemplateProps) {
  return (
    <Container sx={{ padding: '2em', backgroundColor }} data-cy="payment-template-root">
      <Grid container alignItems="center" justifyContent="center" direction="column">
        <Grid item xs={12} sx={{ mb: '2em' }}>
          {LogoComponent ? <LogoComponent /> : <Logo />}
        </Grid>
        <Grid item xs={12}>
          {title}
        </Grid>
        {content ? (
          <Grid item xs={12}>
            {content}
          </Grid>
        ) : null}
        {footer ? (
          <Grid item xs={12}>
            {footer}
          </Grid>
        ) : null}
      </Grid>
    </Container>
  )
}
