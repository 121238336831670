import { createContext, useContext, useState } from 'react'
import { Alert, Snackbar } from '@mui/material'

type Message = {
  message: string
  severity: 'info' | 'success' | 'warning' | 'error'
}

type Notify = (input: Message) => void

export const NotificationContext = createContext<{ notify?: Notify }>({})

export function NotificationProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [message, setMessage] = useState<Message | null>(null)

  const notify = (input: Message): void => {
    setMessage(input)
  }

  const handleClose = () => {
    setMessage(null)
  }

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      {message && (
        <Snackbar open autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={message.severity} sx={{ width: '100%' }}>
            {message.message}
          </Alert>
        </Snackbar>
      )}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  const { notify } = useContext(NotificationContext)

  if (!notify) {
    throw new Error('notify function is not set')
  }

  return { notify }
}
