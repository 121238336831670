import { useTranslation } from 'react-i18next'
import { Chip } from '@mui/material'
import AssignmentIcon from '@mui/icons-material/Assignment'

export function InterviewFormChip() {
  const { t } = useTranslation()

  return (
    <Chip
      icon={<AssignmentIcon fontSize="small" />}
      label={t('Interview Form')}
      variant="outlined"
    />
  )
}
