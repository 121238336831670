import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useForm, SubmitHandler, useFieldArray, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Staff } from '../../ops/types'
import { Clinic } from '../../common/types'

type Inputs = {
  staffID: string
  clinics: Array<{
    clinicID: string
    displayName: string
    enabled: boolean
  }>
}

type OnSubmit = (data: { staffID: string; clinics: Array<{ clinicID: string }> }) => Promise<void>

export function UpdateStaffDialog(props: {
  staff: Staff
  clinics: Array<Clinic>
  onSubmit: OnSubmit
}) {
  const { staff, clinics } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const { control, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      staffID: staff.uid,
      clinics: clinics.map((clinic) => ({
        clinicID: clinic.clinicID,
        displayName: clinic.displayName,
        enabled: staff.clinics.some(
          (staffClinic) => staffClinic.clinicID === clinic.clinicID && staffClinic.enabled
        ),
      })),
    },
  })

  const { fields } = useFieldArray({
    control,
    name: 'clinics',
  })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    props.onSubmit({
      staffID: data.staffID,
      clinics: data.clinics.map((clinic) => ({
        clinicID: clinic.clinicID,
        enabled: clinic.enabled,
      })),
    })
    handleClose()
  }

  return (
    <>
      <Button onClick={handleClickOpen} color="primary" variant="contained">
        {t('Update')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="action-dialog-title"
        aria-describedby="action-dialog-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="action-dialog-title">{t('Update Staff')}</DialogTitle>
          <DialogContent>
            <Box p={2}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <Box pt={2}>
                    <Typography variant="h6" fontWeight="bold">
                      {t('ID')}
                    </Typography>
                  </Box>
                  <Box pt={2}>
                    <Typography variant="h6">{staff.uid}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pt={2}>
                    <Typography variant="h6" fontWeight="bold">
                      {t('Name')}
                    </Typography>
                  </Box>
                  <Box pt={2}>
                    <Typography variant="h6">{staff.name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pt={2}>
                    <Typography variant="h6" fontWeight="bold">
                      {t('Email')}
                    </Typography>
                  </Box>
                  <Box pt={2}>
                    <Typography variant="h6">{staff.email}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pt={2}>
                    <Typography variant="h6" fontWeight="bold">
                      {t('Clinic Affiliations')}
                    </Typography>
                  </Box>
                  <Box pt={2}>
                    <FormGroup>
                      {fields.map((field, index) => (
                        <FormControlLabel
                          key={field.clinicID}
                          control={
                            <Controller
                              render={({ field }) => <Checkbox {...field} checked={field.value} />}
                              name={`clinics.${index}.enabled`}
                              control={control}
                            />
                          }
                          label={field.displayName}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" sx={{ color: '#333' }}>
              {t('Cancel')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('Update')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
