import { fromUnixTime, parseISO } from 'date-fns'
import { format } from 'date-fns-tz'

export class DateTimeUtil {
  timeZone: string

  constructor({ timeZone }: { timeZone: string }) {
    this.timeZone = timeZone
  }

  format = (timestampMs: number): string => {
    return format(fromUnixTime(timestampMs / 1000), 'yyyy-MM-dd HH:mm:ss', {
      timeZone: this.timeZone,
    })
  }

  formatFromDate = (d: Date): string => {
    return this.format(d.getTime())
  }

  formatFromString = (s: string): string => {
    return this.formatFromDate(parseISO(s))
  }
}
