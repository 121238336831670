import { useTranslation } from 'react-i18next'
import { Chip } from '@mui/material'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'

export function FamilyChip() {
  const { t } = useTranslation()

  return (
    <Chip icon={<FamilyRestroomIcon fontSize="small" />} label={t('Family')} variant="outlined" />
  )
}
