import { ThemeProvider } from '@mui/material/styles'
import Container from '@mui/material/Container'
import { UpdateForm } from '../components/UpdateForm'
import { theme } from './Registration'

export const UpdatePage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <UpdateForm />
      </Container>
    </ThemeProvider>
  )
}
