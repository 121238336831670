import { ReactNode, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from '../../common/hooks'
import { useAppDispatch, useAppSelector } from '../store'
import { useStaffsActions } from '../../common/features'

export function StaffGuard({ children }: { children: ReactNode }) {
  const { currentUser, getIDToken } = useAuth()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()
  const { getStaff } = useStaffsActions()
  const currentStaff = useAppSelector((state) => state.staffs.current)

  useEffect(() => {
    if (currentUser) {
      getIDToken(currentUser).then((idToken) => {
        dispatch(getStaff({ token: idToken, staffID: currentUser.uid }))
      })
    }
  }, [currentUser])

  useEffect(() => {
    if (currentStaff === null) {
      navigate({
        pathname: '/ops/staffs/init',
        search: searchParams.toString(),
      })
    }
  }, [currentStaff])

  return <>{children}</>
}
