import { useTranslation } from 'react-i18next'
import { Chip } from '@mui/material'
import NoAccountsIcon from '@mui/icons-material/NoAccounts'

export function NoLineAccountChip() {
  const { t } = useTranslation()

  return (
    <Chip
      icon={<NoAccountsIcon fontSize="small" />}
      label={t('No Line Account')}
      variant="outlined"
    />
  )
}
