import TextField, { TextFieldProps } from '@mui/material/TextField'
import { useFormContext } from 'react-hook-form'

export const birthdayFieldRules = {
  required: '数字のみを8文字で入力してください',
  pattern: {
    value: /^[0-9]{8}$/,
    message: '数字のみを8文字で入力してください',
  },
}

export const validator = (value: string, now?: Date): boolean => {
  const inputYear = value.substring(0, 4)
  const inputMonth = value.substring(4, 6)
  const inputDate = value.substring(6, 8)
  // get timestamps
  const min = new Date(`1900-01-01T00:00:00+0900`).getTime()
  const input = new Date(`${inputYear}-${inputMonth}-${inputDate}T00:00:00+0900`).getTime()
  const max = (now || new Date()).getTime()
  //
  if (Number.isNaN(input)) {
    throw new Error('日付の入力に誤りがあります')
  }
  if (new Date(input).getMonth() !== Number(inputMonth) - 1) {
    throw new Error('日付の入力に誤りがあります')
  }
  if (new Date(input))
    if (!(min <= input)) {
      throw new Error('1900年より前の日付は入力できません')
    }
  if (!(input <= max)) {
    throw new Error('未来の日付は入力できません')
  }
  return true
}

type BirthdayFieldProps = {
  name?: string
} & TextFieldProps

const BirthdayField = (props: BirthdayFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const name = props.name || 'birthday'

  const rules = {
    ...birthdayFieldRules,
    validate: (value: string) => {
      try {
        validator(value)
      } catch (e) {
        if (e instanceof Error) {
          return e.message
        }
        return 'invalid birthday'
      }
    },
  }

  const registerReturn = register(name, rules)

  return (
    <TextField
      {...registerReturn}
      error={!!errors[name]}
      fullWidth
      variant="outlined"
      margin="dense"
      id="birthdayInput"
      inputProps={{
        'aria-label': name,
        inputMode: 'numeric',
        pattern: '^[0-9]*$',
        maxLength: 8,
      }}
    />
  )
}

export default BirthdayField
