import { useRef } from 'react'

// MEMO: 参考記事（https://zenn.dev/toshimarnie/scraps/f60026fce34e84の検証4）
export const useFocus = <RefType extends HTMLElement>() => {
  const ref = useRef<RefType>(null)
  const triggerFocus = (timeout = 10) => {
    if (!ref.current) return
    const tempElement = document.createElement('input')
    tempElement.style.position = 'absolute'
    tempElement.style.top = ref.current.offsetTop + 'px'
    tempElement.style.left = ref.current.offsetLeft + 'px'
    tempElement.style.height = '0'
    tempElement.style.opacity = '0'
    document.body.appendChild(tempElement)
    tempElement.focus()

    setTimeout(() => {
      if (!ref.current) return
      ref.current.focus()
      ref.current.click()
      document.body.removeChild(tempElement)
    }, timeout)
  }
  return {
    ref,
    triggerFocus,
  }
}
