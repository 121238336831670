import { Chip, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

export type LastConsultationChipProps = {
  lastConsultation?: {
    consultationID: string
    date: string
    clinicDisplayName: string
  }
}

export function LastConsultationChip({ lastConsultation }: LastConsultationChipProps) {
  const { t } = useTranslation()

  if (!lastConsultation) {
    return null
  }

  return (
    <Chip
      label={
        <>
          {t('Last consultation')}
          <Typography fontSize="10px" component="span" sx={{ ml: 1 }}>
            {format(parseISO(lastConsultation.date), 'MM/dd')}
            {lastConsultation.clinicDisplayName.replace('クリニックプラス', '')}
          </Typography>
        </>
      }
      variant="outlined"
    />
  )
}
