export const Departments = [
  { code: '10', displayName: '内科（一般）' },
  { code: '11', displayName: '内科（発熱）' },
  { code: '12', displayName: 'PCR検査' },
  { code: '13', displayName: '睡眠時無呼吸症候群' },
  { code: '14', displayName: '健康診断' },
  { code: '15', displayName: 'ブライダルチェック' },
  { code: '16', displayName: '頭痛外来' },
  { code: '17', displayName: '高血圧・脂質異常症外来' },
  { code: '18', displayName: '生活習慣病外来' },
  { code: '19', displayName: '各種診断書' },
  { code: '20', displayName: '小児科' },
  { code: '30', displayName: '循環器専門' },
  { code: '31', displayName: '漢方外来' },
  { code: '40', displayName: '皮膚科' },
  { code: '41', displayName: '皮膚科専門外来' },
  { code: '42', displayName: '巻き爪' },
  { code: '43', displayName: 'ピアス' },
  { code: '44', displayName: 'パッチテスト' },
  { code: '45', displayName: '美容皮膚外来' },
  { code: '46', displayName: '多汗症ボトックス' },
  { code: '50', displayName: 'アレルギー' },
  { code: '51', displayName: '舌下免疫療法' },
  { code: '52', displayName: 'ゾレア' },
  { code: '53', displayName: 'ドロップスクリーン' },
  { code: '60', displayName: '呼吸器専門外来' },
  { code: '70', displayName: '病理診断外来' },
  { code: '80', displayName: 'オンライン診療' },
  { code: '90', displayName: '予防接種' },
  { code: '91', displayName: '自費診療' },
  { code: '92', displayName: 'インフル' },
  { code: '93', displayName: 'ピル' },
  { code: '94', displayName: 'AGA' },
  { code: '95', displayName: 'コロナワクチン' },
  { code: '96', displayName: 'プラセンタ' },
]

export const UnknownDepartment = { code: '-', displayName: '不明' }

export type Department = typeof Departments[number] | typeof UnknownDepartment

export type Reservation = {
  patientUUID: string
  reservationID: string
  clinicID: string
  reservationDatetime: string
  departmentCode: string
  physicianID: string
  bookingPageURL: string
}
