import { orderBy } from 'lodash'
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Box, Paper, Stack, Tab, Tabs, Typography } from '@mui/material'
import { ClinicTemplate } from '../templates'
import {
  CheckedInDialog,
  CalculatingDialog,
  InConsultationDialog,
  InPaymentDialog,
} from '../components/Dialogs'
import { DefaultCard } from '../components/Cards'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../store'
import { useConsultationReceiptActions, useConsultationsActions } from '../features'
import { useAuth } from '../../common/hooks'
import { useClinics } from '../hooks'
import { shallowEqual } from 'react-redux'
import { CardModal } from '../components/CardModal'
import { useSearchParams } from 'react-router-dom'
import { PaymentCompletedDialog } from '../components/Dialogs/PaymentCompleted'
import { endOfDay, startOfDay } from 'date-fns'
import { useDeps, useNotification } from '../../common/contexts'
import { Consultation } from '../types'
import { DatePicker } from '../components/DatePicker'
import { Setting } from '../components/Settings'

function a11yProps(index: number) {
  return {
    id: `status-tab-${index}`,
    'aria-controls': `status-tabpanel-${index}`,
  }
}

type TabPanelProps = {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  )
}

const defaultOrder = (consultations: Consultation[]) =>
  orderBy(consultations, ['createdAt'], ['asc'])

export function DashboardPage() {
  const lanes = [
    {
      status: 'checked in',
      DialogComponent: CheckedInDialog,
      CardComponent: DefaultCard,
      sorter: defaultOrder,
    },
    {
      status: 'in consultation',
      DialogComponent: InConsultationDialog,
      CardComponent: DefaultCard,
      sorter: defaultOrder,
    },
    {
      status: 'calculating',
      DialogComponent: CalculatingDialog,
      CardComponent: DefaultCard,
      sorter: defaultOrder,
    },
    {
      status: 'in payment',
      DialogComponent: InPaymentDialog,
      CardComponent: DefaultCard,
      sorter: (consultations: Consultation[]) => orderBy(consultations, ['updatedAt'], ['desc']),
    },
    {
      status: 'payment completed',
      DialogComponent: PaymentCompletedDialog,
      CardComponent: DefaultCard,
      sorter: (consultations: Consultation[]) => orderBy(consultations, ['createdAt'], ['desc']),
    },
  ]

  const { notify } = useNotification()
  const [searchParams, setSearchParams] = useSearchParams()

  const clinicID = searchParams.get('clinic_id')
  const status = searchParams.get('status')

  const [date, setDate] = useState(new Date())
  const from = searchParams.get('from') || startOfDay(date).toISOString()
  const to = searchParams.get('to') || endOfDay(date).toISOString()

  const { currentUser, getIDToken } = useAuth()
  const { api } = useDeps()
  const { currentClinic } = useClinics({ clinicID })

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { getConsultations, dispatchConsultationAction, patchConsultation } =
    useConsultationsActions()
  const { downloadReceipt, generateReceipt } = useConsultationReceiptActions()
  const consultations = useAppSelector((state) => state.consultations.entries, shallowEqual)
  const receipt = useAppSelector((state) => state.consultationReceipt.current, shallowEqual)
  const receiptError = useAppSelector((state) => state.consultationReceipt.error, shallowEqual)

  let initState = 0
  const idx = lanes.findIndex((lane) => lane.status === status)
  if (idx >= 0) {
    initState = idx
  }
  const [value, setValue] = useState(initState)

  const handleChange = useCallback((event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }, [])

  const handleToggleBlocked = useCallback(
    ({
      consultationID,
      blocked,
      blockedReason,
    }: {
      consultationID: string
      blocked: boolean
      blockedReason?: string
    }) => {
      if (currentUser && currentClinic) {
        const toggleBlock = async () => {
          try {
            const token = await getIDToken(currentUser)
            await dispatch(patchConsultation({ token, consultationID, blocked, blockedReason }))
            notify({
              message: blocked
                ? t('Succeeded to block consultation')
                : t('Succeeded to unblock consultation'),
              severity: 'success',
            })
          } catch (e) {
            const error = e instanceof Error ? e.message : t('unknown error')
            notify({
              message: blocked
                ? t('Failed to block consultation', { error })
                : t('Failed to unblock consultation', { error }),
              severity: 'error',
            })
          }
        }

        toggleBlock()
      }
    },
    [currentUser, currentClinic, dispatch]
  )

  const handleDownloadReceipt = useCallback(
    ({ consultationID }: { consultationID: string }) => {
      if (currentUser && currentClinic) {
        const download = async () => {
          try {
            const token = await getIDToken(currentUser)
            await dispatch(downloadReceipt({ token, consultationID }))
          } catch (e) {
            const error = e instanceof Error ? e.message : t('unknown error')
            notify({
              message: t('Failed to download receipt', { error }),
              severity: 'error',
            })
          }
        }

        download()
      }
    },
    [currentUser, currentClinic, dispatch]
  )

  const handleGenerateReceipt = useCallback(
    ({ consultationID }: { consultationID: string }) => {
      if (currentUser && currentClinic) {
        const generate = async () => {
          try {
            const token = await getIDToken(currentUser)
            await dispatch(generateReceipt({ token, consultationID }))
          } catch (e) {
            const error = e instanceof Error ? e.message : t('unknown error')
            notify({
              message: t('Failed to generate receipt', { error }),
              severity: 'error',
            })
          }
        }

        generate()
      }
    },
    [currentUser, currentClinic, dispatch]
  )

  const handleReserveWithImpersonation = async ({ patientUUID }: { patientUUID: string }) => {
    if (currentUser && currentClinic) {
      try {
        const token = await getIDToken(currentUser)
        const impersonationToken = await api.getImpersonationToken({ token, patientUUID })

        const url = new URL(currentClinic.reservationDepartmentURL)
        url.searchParams.set('patient_uuid', patientUUID)
        url.searchParams.set('clinic_id', currentClinic.clinicID)
        url.searchParams.set('id_token', impersonationToken)
        url.searchParams.set('ID_TOKEN', impersonationToken)

        window.open(url.toString(), '_blank')
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to redirect to impersonate reserve page.', { error }),
          severity: 'error',
        })
      }
    }
  }

  const handleDispatchConsultationAction = useCallback(
    ({ consultationID, action }: { consultationID: string; action: string }) => {
      if (currentUser && currentClinic) {
        const dispatchAction = async () => {
          try {
            const token = await getIDToken(currentUser)
            await dispatch(
              dispatchConsultationAction({
                token,
                consultationID,
                action: action as 'notify_checkout' | 'checkout',
              })
            )
          } catch (e) {
            let actionName = ''
            switch (action) {
              case 'notify_checkout':
                actionName = t('Checkout notification')
                break
              case 'checkout':
                actionName = t('Checkout')
                break
              default:
                break
            }
            const error = e instanceof Error ? e.message : t('unknown error')
            notify({
              message: t('Failed to perform action', { action: t(actionName), error }),
              severity: 'error',
            })
          }
        }

        dispatchAction()
      }
    },
    [currentUser, currentClinic, dispatch]
  )

  const handleGetConsultations = async () => {
    if (currentUser && currentClinic) {
      try {
        const token = await getIDToken(currentUser)
        await dispatch(getConsultations({ clinicID: currentClinic.clinicID, token, from, to }))
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to get consultations', { error }),
          severity: 'error',
        })
      }
    }
  }

  // This block of code ensures that the time span between requests
  // have at least `delay` seconds.
  // cf. https://ja.javascript.info/settimeout-setinterval#ref-618
  // cf. https://www.aaron-powell.com/posts/2019-09-23-recursive-settimeout-with-react-hooks/
  const pollerRef = useRef(handleGetConsultations)
  const delay = 5000 // five seconds

  useEffect(() => {
    pollerRef.current = handleGetConsultations
  }, [pollerRef, currentClinic, date])

  useEffect(() => {
    let id: NodeJS.Timeout
    function tick() {
      pollerRef.current().then(() => {
        id = setTimeout(tick, delay)
      })
    }
    id = setTimeout(tick, delay)
    return () => id && clearTimeout(id)
  }, [currentClinic, date])

  useEffect(() => {
    const sp = new URLSearchParams()
    const lane = lanes[value]
    if (lane) {
      sp.set('status', lane.status)
    }
    if (currentClinic) {
      sp.set('clinic_id', currentClinic.clinicID)
    }
    setSearchParams(sp.toString())
  }, [currentClinic, value])

  let receiptErrorMsg: string | null = null
  if (receiptError) {
    if (receiptError.status === 404) {
      receiptErrorMsg = t('Receipt not found or set undisplayed')
    } else {
      const error = receiptError.message || t('unknown error')
      receiptErrorMsg = t('Failed to download receipt', { error })
    }
  }

  return (
    <ClinicTemplate clinicID={clinicID}>
      <Stack spacing={1}>
        <Box sx={{ textAlign: 'right' }}>
          <Setting>
            <DatePicker
              value={date}
              onChange={(value: Date | null) => {
                if (value) {
                  setDate(value)
                }
              }}
            />
          </Setting>
        </Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            position: 'sticky',
            top: 0,
            width: '100%',
            margin: '0 auto',
            backgroundColor: '#fff',
          }}
          component={Paper}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
          >
            {lanes.map(({ status }, idx) => (
              <Tab
                key={status}
                label={<Typography fontWeight="bold">{t(status)}</Typography>}
                {...a11yProps(idx)}
              />
            ))}
          </Tabs>
        </Box>
        {lanes.map(({ status, DialogComponent, CardComponent, sorter }, idx) => (
          <Box key={status} sx={{ backgroundColor: '#eee' }} style={{ margin: 0 }}>
            <TabPanel value={value} index={idx}>
              {sorter(consultations.filter((c) => c.status === status)).map((consultation) => (
                <CardModal
                  key={consultation.consultationID}
                  consultation={consultation}
                  receipt={receipt}
                  handleToggleBlocked={handleToggleBlocked}
                  handleDownloadReceipt={handleDownloadReceipt}
                  handleGenerateReceipt={handleGenerateReceipt}
                  receiptError={receiptErrorMsg}
                  handleDispatchConsultationAction={handleDispatchConsultationAction}
                  handleReserveWithImpersonation={handleReserveWithImpersonation}
                  DialogComponent={DialogComponent}
                  CardComponent={CardComponent}
                />
              ))}
            </TabPanel>
          </Box>
        ))}
      </Stack>
    </ClinicTemplate>
  )
}
