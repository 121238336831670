import createClient from 'openapi-fetch'
import { APIClient } from './interface'
import { paths } from './schema'
import {
  transformConsultationReponse,
  transformConsultationsReponse,
  transformPrintDeviceResponse,
  transformPrintDevicesResponse,
  transformPrintQueueResponse,
  transformPrintQueuesResponse,
  transformStaffClinicResponse,
  transformStaffClinicsResponse,
} from './transformer'
import { Clinic } from '../modules/common/types'
import { Staff } from '../modules/ops/types'
import { Page, PrintDevice, PrintQueue } from '../modules/admin/types'

const c = createClient<paths>()
type Client = typeof c

function handleAPIError<T>(data: T | undefined, error: any, response: Response): asserts data is T {
  if (error) {
    console.error(error)
    throw new APIError(response.status, error.message)
  }
}

export class APIError extends Error {
  status: number

  constructor(status: number, message: string) {
    super(message)
    this.name = new.target.name
    this.status = status
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class ClinicplusAPIClient implements APIClient {
  baseURL: string
  client: Client

  constructor({ baseURL, client }: { baseURL: string; client: Client }) {
    this.baseURL = baseURL
    this.client = client
  }

  static create = ({ baseURL }: { baseURL: string }) => {
    return new this({
      baseURL,
      client: createClient<paths>({ baseUrl: baseURL }),
    })
  }

  getConsultations = async ({
    token,
    clinicID,
    from,
    to,
  }: {
    token: string
    clinicID?: string
    from?: string
    to?: string
  }) => {
    const { data, error, response } = await this.client.GET('/api/consultations', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        query: {
          clinic_id: clinicID,
          from,
          to,
        },
      },
    })
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
    if (!data) {
      throw new Error('data is empty')
    }
    return transformConsultationsReponse(data)
  }

  patchConsultation = async ({
    token,
    consultationID,
    blocked,
    blockedReason,
  }: {
    token: string
    consultationID: string
    blocked: boolean
    blockedReason?: string
  }) => {
    const { data, error, response } = await this.client.PATCH(
      '/api/consultations/{consultationId}',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          path: {
            consultationId: consultationID,
          },
        },
        body: {
          blocked,
          blocked_reason: blockedReason,
        },
      }
    )
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
    if (!data) {
      throw new Error('data is empty')
    }
    return transformConsultationReponse(data)
  }

  downloadConsultationReceipt = async ({
    token,
    consultationID,
  }: {
    token: string
    consultationID: string
  }): Promise<{ encodedPDF: string }> => {
    const { data, error, response } = await this.client.GET(
      '/api/consultations/{consultationId}/receipt',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          path: {
            consultationId: consultationID,
          },
        },
      }
    )
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
    if (!data) {
      throw new Error('data is empty')
    }
    return { encodedPDF: data.encoded_pdf }
  }

  generateConsultationReceipt = async ({
    token,
    consultationID,
  }: {
    token: string
    consultationID: string
  }): Promise<void> => {
    const { data, error, response } = await this.client.POST(
      '/api/consultations/{consultationId}/receipt/generate',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          path: {
            consultationId: consultationID,
          },
        },
        body: {},
      }
    )
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
    if (!data) {
      throw new Error('data is empty')
    }
  }

  dispatchConsultationAction = async ({
    token,
    consultationID,
    action,
  }: {
    token: string
    consultationID: string
    action: 'checkout' | 'notify_checkout'
  }): Promise<{ consultationID: string; status: string }> => {
    const { data, error, response } = await this.client.POST(
      '/api/consultations/{consultationId}/actions',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          path: {
            consultationId: consultationID,
          },
        },
        body: {
          action,
        },
      }
    )
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
    if (!data) {
      throw new Error('data is empty')
    }
    return {
      consultationID: data.consultation_id,
      status: data.status,
    }
  }

  getClinics = async ({ token }: { token: string }): Promise<Page<Clinic>> => {
    const { data, error, response } = await this.client.GET('/api/clinics', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
    if (!data) {
      throw new Error('data is empty')
    }
    return {
      count: data.count,
      offset: data.offset,
      entries: data.entries.map((entry) => ({
        clinicID: entry.clinic_id,
        displayName: entry.display_name,
        address: entry.address,
        access: entry.access,
        phoneNumber: entry.phone_number,
        businessHours: entry.business_hours,
        reservationDepartmentURL: entry.reservation_department_url,
        createdAt: entry.created_at,
        updatedAt: entry.updated_at,
      })),
    }
  }

  initStaff = async ({ token }: { token: string }): Promise<void> => {
    const { error, response } = await this.client.POST('/api/staffs/init', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
  }

  getStaffs = async ({
    token,
    offset,
    limit,
  }: {
    token: string
    offset?: number
    limit?: number
  }): Promise<Page<Staff>> => {
    const { data, error, response } = await this.client.GET('/api/staffs', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        query: {
          offset,
          limit,
        },
      },
    })
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
    if (!data) {
      throw new Error('data is empty')
    }
    return {
      count: data.count,
      offset: data.offset,
      entries: data.entries.map((entry) => ({
        uid: entry.uid,
        name: entry.name,
        email: entry.email,
        photoURL: entry.photo_url,
        clinics: entry.clinics.map((clinic) => ({
          clinicID: clinic.clinic_id,
          enabled: clinic.enabled == null ? true : clinic.enabled,
        })),
      })),
    }
  }

  getStaff = async ({ token, staffID }: { token: string; staffID: string }): Promise<Staff> => {
    const { data, error, response } = await this.client.GET('/api/staffs/{staffId}', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        path: {
          staffId: staffID,
        },
      },
    })
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
    if (!data) {
      throw new Error('data is empty')
    }
    return {
      uid: data.uid,
      name: data.name,
      email: data.email,
      photoURL: data.photo_url,
      clinics: data.clinics.map((clinic) => ({
        clinicID: clinic.clinic_id,
        enabled: clinic.enabled == null ? true : clinic.enabled,
      })),
    }
  }

  patchStaff = async ({
    token,
    staffID,
    clinics,
  }: {
    token: string
    staffID: string
    clinics: { clinicID: string; enabled: boolean }[]
  }): Promise<Staff> => {
    const { data, error, response } = await this.client.PATCH('/api/staffs/{staffId}', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        path: {
          staffId: staffID,
        },
      },
      body: {
        clinics: clinics.map((clinic) => ({
          clinic_id: clinic.clinicID,
          enabled: clinic.enabled,
        })),
      },
    })
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
    if (!data) {
      throw new Error('data is empty')
    }
    return {
      uid: data.uid,
      name: data.name,
      email: data.email,
      photoURL: data.photo_url,
      clinics: data.clinics.map((clinic) => ({
        clinicID: clinic.clinic_id,
        enabled: clinic.enabled == null ? true : clinic.enabled,
      })),
    }
  }

  getStaffClinics = async ({
    token,
    staffID,
  }: {
    token: string
    staffID: string
  }): Promise<{
    entries: Clinic[]
    offset: number
    count: number
  }> => {
    const { data, error, response } = await this.client.GET('/api/staffs/{staffId}/clinics', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        path: {
          staffId: staffID,
        },
      },
    })
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
    if (!data) {
      throw new Error('data is empty')
    }
    return transformStaffClinicsResponse(data)
  }

  getStaffClinic = async ({
    token,
    staffID,
    clinicID,
  }: {
    token: string
    staffID: string
    clinicID: string
  }): Promise<Clinic> => {
    const { data, error, response } = await this.client.GET(
      '/api/staffs/{staffId}/clinics/{clinicId}',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          path: {
            staffId: staffID,
            clinicId: clinicID,
          },
        },
      }
    )
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
    if (!data) {
      throw new Error('data is empty')
    }
    return transformStaffClinicResponse(data)
  }

  getImpersonationToken = async ({
    token,
    patientUUID,
  }: {
    token: string
    patientUUID: string
  }): Promise<string> => {
    const { data, error, response } = await this.client.POST('/api/auth/impersonate', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        patient_uuid: patientUUID,
      },
    })
    if (error) {
      console.error(error)
      throw new APIError(response.status, error.message)
    }
    if (!data) {
      throw new Error('data is empty')
    }
    return data.token
  }

  /**
   * CloudPrnt
   */
  submitPrintJob = async (params: {
    token: string
    clinicID: string
    printingParams: {
      patient_id: string
      department_name: string
      reception_number: string
      reception_date: string
      reception_time: string
    }
  }): Promise<void> => {
    const { data, error, response } = await this.client.POST('/api/cloudprnt/print', {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
      body: {
        clinic_id: params.clinicID,
        printing_params: params.printingParams,
      },
    })
    handleAPIError(data, error, response)
  }

  /**
   * Print Devices
   */
  getPrintDevices = async (params: { token: string }): Promise<Page<PrintDevice>> => {
    const { data, error, response } = await this.client.GET('/api/cloudprnt/devices', {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
    })
    handleAPIError(data, error, response)
    return transformPrintDevicesResponse(data)
  }

  createPrintDevice = async ({
    token,
    clinicID,
    macAddress,
    queueID,
  }: {
    token: string
    clinicID: string
    macAddress: string
    queueID: number
  }) => {
    const { data, error, response } = await this.client.POST('/api/cloudprnt/devices', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        clinic_id: clinicID,
        mac_address: macAddress,
        queue_id: queueID,
      },
    })
    handleAPIError(data, error, response)
  }

  getPrintDevice = async (params: { token: string; macAddress: string }): Promise<PrintDevice> => {
    const { data, error, response } = await this.client.GET('/api/cloudprnt/devices/{macAddress}', {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
      params: {
        path: {
          macAddress: params.macAddress,
        },
      },
    })
    handleAPIError(data, error, response)
    return transformPrintDeviceResponse(data)
  }

  deletePrintDevice = async (params: { token: string; macAddress: string }): Promise<void> => {
    const { data, error, response } = await this.client.DELETE(
      '/api/cloudprnt/devices/{macAddress}',
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
        params: {
          path: {
            macAddress: params.macAddress,
          },
        },
      }
    )
    handleAPIError(data, error, response)
  }

  resetPrintDevice = async (params: { token: string; macAddress: string }): Promise<void> => {
    const { data, error, response } = await this.client.POST(
      '/api/cloudprnt/devices/{macAddress}/reset',
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
        params: {
          path: {
            macAddress: params.macAddress,
          },
        },
      }
    )
    handleAPIError(data, error, response)
  }

  /**
   * Print Queues
   */
  getPrintQueues = async (params: { token: string }): Promise<Page<PrintQueue>> => {
    const { data, error, response } = await this.client.GET('/api/cloudprnt/queues', {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
    })
    handleAPIError(data, error, response)
    return transformPrintQueuesResponse(data)
  }

  createPrintQueue = async (params: {
    token: string
    name: string
    position: number
    designTemplate: string
  }): Promise<void> => {
    const { data, error, response } = await this.client.POST('/api/cloudprnt/queues', {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
      body: {
        name: params.name,
        position: params.position,
        design_template: params.designTemplate,
      },
    })
    handleAPIError(data, error, response)
  }

  getPrintQueue = async (params: { token: string; queueID: number }): Promise<PrintQueue> => {
    const { data, error, response } = await this.client.GET('/api/cloudprnt/queues/{queueId}', {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
      params: {
        path: {
          queueId: params.queueID,
        },
      },
    })
    handleAPIError(data, error, response)
    return transformPrintQueueResponse(data)
  }

  deletePrintQueue = async (params: { token: string; queueID: number }): Promise<void> => {
    const { data, error, response } = await this.client.DELETE('/api/cloudprnt/queues/{queueId}', {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
      params: {
        path: {
          queueId: params.queueID,
        },
      },
    })
    handleAPIError(data, error, response)
  }

  resetPrintQueue = async (params: { token: string; queueID: number }): Promise<void> => {
    const { data, error, response } = await this.client.POST(
      '/api/cloudprnt/queues/{queueId}/reset',
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
        params: {
          path: {
            queueId: params.queueID,
          },
        },
      }
    )
    handleAPIError(data, error, response)
  }
}
