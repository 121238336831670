import { useTranslation } from 'react-i18next'
import { Chip } from '@mui/material'

export type InvoiceNumberChipProps = {
  invoiceNumber: string
}

export function InvoiceNumberChip({ invoiceNumber }: InvoiceNumberChipProps) {
  const { t } = useTranslation()

  return <Chip label={t('Invoice Number', { value: invoiceNumber })} variant="outlined" />
}
