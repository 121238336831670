import { createTheme, ThemeProvider } from '@mui/material/styles'
import Container from '@mui/material/Container'
import { Box, Button, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { Logo, LogoWhite } from '../../common/components'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import axios from 'axios'
import { useConfig } from '../../common/contexts/Config'
import { useEffect, useState } from 'react'
import useGetClinics from '../hooks/useGetClinics'
import { FormProvider, useForm } from 'react-hook-form'
import { formatInTimeZone } from 'date-fns-tz'
import GoToLineButton from '../components/GoToLineButton'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#da5e29',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        fontSize: 11,
      },
    },
  },
  typography: {
    fontSize: 11,
  },
})

const CheckInTemplate = (props: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Stack alignItems="center" spacing={3} sx={{ my: 1, width: '100%' }}>
          {props.children}
        </Stack>
      </Container>
    </ThemeProvider>
  )
}

export const CheckInPage = () => {
  const navigate = useNavigate()
  const search = useLocation().search
  const queryParams = new URLSearchParams(search)
  const { config } = useConfig()

  const [patient, setPatient] = useState<Patient | null>(null)
  const [reservations, setReservations] = useState<Reservation[]>([])
  const [mainContents, setMainContents] = useState(<></>)

  // get patients
  useEffectOnce(() => {
    ;(async () => {
      axios
        .get<Family>(
          `${config.apiBaseURL}/api/patients/me/clinics/${queryParams.get('clinic_id')}`,
          {
            headers: {
              authorization: `bearer ${queryParams.get('id_token')}`,
            },
          }
        )
        .then((res) => {
          const family = res.data
          const patients = [family.primary, ...family.secondaries]
          if (patients.length === 0) {
            // unexpected
          } else if (patients.length === 1) {
            setPatient(patients[0])
          } else if (patients.length >= 2) {
            setMainContents(<SelectPatient family={family} handler={handleSelectPatient} />)
          }
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            // go to registration page
            navigate(
              `/patients/registration?id_token=${queryParams.get('id_token')}&via_check_in=1`
            )
          } else {
            setMainContents(<UnexpectedError />)
          }
        })
    })()
  })

  const handleSelectPatient = (patient: Patient) => {
    setPatient(patient)
  }

  // get today's reservations
  useEffect(() => {
    ;(async () => {
      if (!patient) {
        return
      }
      axios
        .get<{ entries: Reservation[] }>(
          `${config.apiBaseURL}/api/patients/uuid/${
            patient.patient_uuid
          }/reservations?clinic_id=${queryParams.get('clinic_id')}`,
          {
            headers: {
              authorization: `bearer ${queryParams.get('id_token')}`,
            },
          }
        )
        .then((res) => {
          setReservations(res.data.entries)
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            // go to registration page
            navigate(
              `/patients/registration?id_token=${queryParams.get('id_token')}&via_check_in=1`
            )
          } else {
            setMainContents(<UnexpectedError />)
          }
        })
    })()
  }, [patient])

  // trigged by array of reservations changed
  useEffect(() => {
    if (!patient) {
      //unexpected
      return
    }
    if (reservations.length === 0) {
      // post without reservation
      handleNoReservation()
    } else if (reservations.length === 1) {
      // post
      handleSelectReservation(reservations[0])
    } else if (2 <= reservations.length) {
      setMainContents(
        <SelectReservation reservations={reservations} handler={handleSelectReservation} />
      )
    }
  }, [reservations])

  // check in without reservation
  const handleNoReservation = () => {
    if (!patient) {
      // unexpected
      return
    }
    axios
      .post(
        `${config.apiBaseURL}/api/clinics/${queryParams.get('clinic_id')}/check-in`,
        {
          patient_uuid: patient.patient_uuid,
        },
        {
          headers: {
            authorization: `bearer ${queryParams.get('id_token')}`,
          },
        }
      )
      .then((res) => {
        setMainContents(<CheckedInWithoutReservation patient={patient} />)
      })
      .catch((err) => {
        setMainContents(<UnexpectedError />)
      })
  }

  // check in
  const handleSelectReservation = (reservation: Reservation) => {
    if (!patient) {
      // unexpected
      return
    }
    axios
      .post(
        `${config.apiBaseURL}/api/clinics/${queryParams.get('clinic_id')}/check-in`,
        {
          patient_uuid: patient.patient_uuid,
          reservation_id: reservation.reservation_id,
        },
        {
          headers: {
            authorization: `bearer ${queryParams.get('id_token')}`,
          },
        }
      )
      .then((res) => {
        setMainContents(<CheckedIn />)
      })
      .catch((err) => {
        if (err.response?.status === 409) {
          setMainContents(<AlreadyCheckedIn patient={patient} reservation={reservation} />)
        } else {
          setMainContents(<UnexpectedError />)
        }
      })
  }

  return <>{mainContents || <></>}</>
}

type Patient = {
  patient_uuid: string
  patient_id?: string
  full_name: string
  birthday: string
  has_sokushin: boolean
}

type Family = {
  primary: Patient
  secondaries: Patient[]
}

type Reservation = {
  reservation_id: string
  reservation_datetime: string | Date
  department: {
    code: string
    display_name: string
  }
}

const useGetClinic = () => {
  const search = useLocation().search
  const queryParams = new URLSearchParams(search)
  const clinics = useGetClinics()

  const clinicID = queryParams.get('clinic_id')
  return clinics.find((clinic) => clinic.clinicID === clinicID)
}

const requiredRules = {
  required: '選択してください',
}

type SelectPatientFormValue = {
  patient_id: string
}
type SelectPatientProps = {
  family: Family
  handler: (patient: Patient) => void
}
const SelectPatient = (props: SelectPatientProps) => {
  const methods = useForm<SelectPatientFormValue>({})
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = methods
  const patients = [props.family.primary, ...props.family.secondaries]

  const onSubmit = async (data: SelectPatientFormValue) => {
    const patient = patients.find((p) => p.patient_uuid === data.patient_id)
    if (patient) {
      props.handler(patient)
    }
  }

  return (
    <CheckInTemplate>
      <Logo />
      <Typography variant="h5">受付のお願い</Typography>
      <Typography color="primary.main" sx={{ fontWeight: 700 }}>
        本日受診される方を選択してください
      </Typography>
      <FormProvider {...methods}>
        <Stack
          component="form"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          spacing={3}
          sx={{ width: '80%' }}
        >
          <RadioGroup name="patient_id">
            {(() => {
              return patients.map((patient) => {
                return (
                  <FormControlLabel
                    {...register('patient_id', requiredRules)}
                    value={patient.patient_uuid}
                    control={<Radio />}
                    label={<Typography>{patient.full_name} 様</Typography>}
                  />
                )
              })
            })()}
          </RadioGroup>
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid}
            disableElevation
            fullWidth
            size="large"
            color="primary"
            sx={{
              py: 1.5,
              fontSize: 14,
              fontWeight: 700,
              '&.Mui-disabled': {
                background: '#eaeaea',
                color: '#fff',
              },
            }}
          >
            次へ
          </Button>
        </Stack>
      </FormProvider>
    </CheckInTemplate>
  )
}

type SelectReservationFormValue = {
  reservation_id: string
}
type SelectReservationProps = {
  reservations: Reservation[]
  handler: (reservation: Reservation) => void
}
const SelectReservation = (props: SelectReservationProps) => {
  const methods = useForm<SelectReservationFormValue>({})
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = methods
  const clinic = useGetClinic()

  const onSubmit = async (data: SelectReservationFormValue) => {
    const reservation = props.reservations.find((r) => r.reservation_id === data.reservation_id)
    if (!reservation) {
      return
    }
    props.handler(reservation)
  }

  return (
    <CheckInTemplate>
      <Logo />
      <Typography variant="h5">受付のお願い</Typography>
      <Typography color="primary.main" sx={{ fontWeight: 700 }}>
        今から受診する予約を選択してください
      </Typography>
      <Typography>{clinic?.clinicName}での当日予約</Typography>
      <FormProvider {...methods}>
        <Stack
          component="form"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          spacing={3}
          sx={{ width: '80%' }}
        >
          <RadioGroup name="reservation_id">
            {(() => {
              return props.reservations.map((reservation) => {
                return (
                  <FormControlLabel
                    {...register('reservation_id', requiredRules)}
                    value={reservation.reservation_id}
                    control={<Radio />}
                    label={
                      <Box>
                        {formatInTimeZone(
                          new Date(reservation.reservation_datetime),
                          'Asia/Tokyo',
                          'HH:mm'
                        )}{' '}
                        {reservation.department.display_name}
                      </Box>
                    }
                  />
                )
              })
            })()}
          </RadioGroup>
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid}
            disableElevation
            fullWidth
            size="large"
            color="primary"
            sx={{
              py: 1.5,
              fontSize: 14,
              fontWeight: 700,
              '&.Mui-disabled': {
                background: '#eaeaea',
                color: '#fff',
              },
            }}
          >
            受付する
          </Button>
        </Stack>
      </FormProvider>
    </CheckInTemplate>
  )
}

type CheckedInWithoutReservationProps = {
  patient: Omit<Patient, 'patient_uuid' | 'has_sokushin'>
}
export const CheckedInWithoutReservation = (props: CheckedInWithoutReservationProps) => {
  const clinic = useGetClinic()
  return (
    <CheckInTemplate>
      <LogoWhite />
      <ChangeBodyBgColor />
      <Typography>{clinic?.clinicName}での予約が確認できませんでした</Typography>
      <Typography sx={{ fontWeight: 700, textAlign: 'center' }}>
        本日の診察をご希望の方は
        <br />
        この画面を受付にご提示ください
      </Typography>
      <Stack
        alignItems="center"
        sx={{ backgroundColor: '#FFFFFF', color: '#6C0000', padding: '10px', width: '80%' }}
      >
        <Typography sx={{ fontSize: '2.0em', fontWeight: 600 }}>予約なし</Typography>
        <Typography sx={{ fontSize: '2.0em', fontWeight: 600 }}>
          {props.patient.full_name}
        </Typography>
        <Typography>{convertToWareki(new Date(props.patient.birthday))}</Typography>
        <Typography>{props.patient.patient_id || '患者番号なし'}</Typography>
      </Stack>
      <Typography>※予約優先のためしばらくお待ちいただく場合があります</Typography>
      <GoToLineButton colorType="inverse" sx={{ width: '80%' }} />
    </CheckInTemplate>
  )
}
const convertToWareki = (date: Date): string => {
  const dateTimeFormat = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {
    era: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  return dateTimeFormat.format(date)
}

type CheckedInProps = {}
const CheckedIn = (props: CheckedInProps) => {
  return (
    <CheckInTemplate>
      <Logo />
      <Typography color="primary" sx={{ fontSize: '1.5em', fontWeight: 600, pt: '30px' }}>
        受付が完了しました。
      </Typography>
      <Typography>マイナンバーカードの読み取り、または健康保険証を受付に提出ください。</Typography>
      <GoToLineButton sx={{ width: '80%' }} />
    </CheckInTemplate>
  )
}

type AlreadyCheckedInProps = {
  patient: Patient
  reservation: Reservation
}
const AlreadyCheckedIn = (props: AlreadyCheckedInProps) => {
  return (
    <>
      <Logo />
      <Typography color="primary" sx={{ fontSize: '1.1em', fontWeight: 600 }}>
        すでに受付済の予約です
      </Typography>
      <Typography>
        {formatInTimeZone(
          props.reservation.reservation_datetime,
          'Asia/Tokyo',
          'yyyy年MM月dd日 HH:mm'
        )}
        <br />
        {props.reservation.department.display_name}
        <br />
        {props.patient.full_name} 様
      </Typography>
      <GoToLineButton sx={{ width: '80%' }} />
      <Typography>ご不明な点がある方は受付までお越し下さい</Typography>
    </>
  )
}

const ChangeBodyBgColor: React.FC = () => {
  useEffect(() => {
    // Change the body background color when the component mounts
    document.body.style.backgroundColor = '#da5e29'
    document.body.style.color = '#FFFFFF'
    // Cleanup function to reset the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = ''
    }
  }, [])

  return <></>
}

const UnexpectedError = () => {
  return (
    <CheckInTemplate>
      <Logo />
      <Typography>エラーが発生しました。受付までお越しください</Typography>
    </CheckInTemplate>
  )
}
