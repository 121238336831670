import { ThemeProvider } from '@mui/material/styles'
import Container from '@mui/material/Container'
import { TransferToFamilyForm } from '../components/TransferToFamilyForm'
import { theme } from './Registration'

export const TransferToFamilyPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <TransferToFamilyForm />
      </Container>
    </ThemeProvider>
  )
}
