import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import axiosRetry from 'axios-retry'

export type HTTPResponse<T> = {
  status: number
  data: T
}

export interface IHTTPClient {
  get<T>(
    url: string,
    headers?: Record<string, string>,
    params?: Record<string, string | number>
  ): Promise<HTTPResponse<T>>
  post<T>(
    url: string,
    data: any,
    headers?: Record<string, string>,
    params?: Record<string, string | number>
  ): Promise<HTTPResponse<T>>
}

export class HTTPClient implements IHTTPClient {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  get = async <T>(
    url: string,
    headers: Record<string, string> = {},
    params: Record<string, string | number> = {}
  ): Promise<HTTPResponse<T>> => {
    const config: AxiosRequestConfig = {
      headers,
      params,
    }
    const res = await this.instance.get<T>(url, config)
    return {
      status: res.status,
      data: res.data,
    }
  }

  post = async <T>(
    url: string,
    data: any,
    headers: Record<string, string> = {},
    params: Record<string, string | number> = {}
  ): Promise<HTTPResponse<T>> => {
    const config: AxiosRequestConfig = {
      headers,
      params,
    }
    const res = await this.instance.post<T>(url, data, config)
    return {
      status: res.status,
      data: res.data,
    }
  }

  static create(): HTTPClient {
    const instance = axios.create({
      timeout: 5000,
      validateStatus: (status: number) => status >= 200,
    })
    axiosRetry(instance, {
      retries: 5,
      retryDelay: axiosRetry.exponentialDelay,
    })
    return new HTTPClient(instance)
  }
}
