import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField } from '@mui/material'

type Inputs = {
  macAddress: string
  clinicID: string
  queueID: number | string
}

type OnSubmit = (data: Inputs) => Promise<void>

export function CreatePrintDeviceDialog(props: { onSubmit: OnSubmit }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    props.onSubmit(data)
    handleClose()
  }

  return (
    <>
      <Button onClick={handleClickOpen} color="primary" variant="contained">
        {t('Create')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="action-dialog-title"
        aria-describedby="action-dialog-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="action-dialog-title">{t('Create Print Device')}</DialogTitle>
          <DialogContent>
            <Box p={2}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label={t('Mac Address')}
                    fullWidth
                    {...register('macAddress', { required: true })}
                  />
                  {errors.macAddress && <span>This field is required</span>}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Clinic ID')}
                    fullWidth
                    {...register('clinicID', { required: true })}
                  />
                  {errors.clinicID && <span>This field is required</span>}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Queue ID')}
                    fullWidth
                    type="number"
                    {...register('queueID', { required: true })}
                  />
                  {errors.queueID && <span>This field is required</span>}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" sx={{ color: '#333' }}>
              {t('Cancel')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('Create')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
