import { Button, ButtonProps } from '@mui/material'
import { useConfig } from '../../common/contexts'

type GoToLineButtonProps = ButtonProps & {
  colorType?: 'default' | 'inverse'
  text?: string
}
const GoToLineButton = (props: GoToLineButtonProps) => {
  const { config } = useConfig()
  let color, backgroundColor
  if (props.colorType === 'inverse') {
    color = '#da5e29'
    backgroundColor = '#ffffff'
  } else {
    color = undefined
    backgroundColor = undefined
  }

  return (
    <Button
      href={config.lineChannelURL}
      size="large"
      variant="contained"
      fullWidth
      disableElevation
      color="primary"
      {...props}
      sx={{
        ...{
          py: 1.5,
          fontSize: 16,
          color,
          backgroundColor,
          fontWeight: 700,
          '&:hover': {
            backgroundColor,
          },
        },
        ...props.sx,
      }}
    >
      {props.text || '公式LINEに行く'}
    </Button>
  )
}

export default GoToLineButton
