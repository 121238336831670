import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { AdminTemplate } from '../templates'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../store'
import { usePrintDevicesActions, usePrintQueuesActions } from '../features'
import { ReactElement, useEffect, useState } from 'react'
import { useAuth } from '../../common/hooks'
import { useDeps, useNotification } from '../../common/contexts'
import { format } from 'date-fns'
import {
  CreatePrintDeviceDialog,
  CreatePrintQueueDialog,
  SubmitPrintJobDialog,
} from '../components'
import { isString } from 'lodash'
import { PrintDevice } from '../types'

export function CloudPrntPage() {
  const { notify } = useNotification()
  const { currentUser, getIDToken } = useAuth()
  const { api } = useDeps()
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const { createPrintDevice, getPrintDevices, deletePrintDevice, resetPrintDevice } =
    usePrintDevicesActions()
  const { createPrintQueue, getPrintQueues, deletePrintQueue, resetPrintQueue } =
    usePrintQueuesActions()
  const printDevices = useAppSelector((state) => state.printDevices.entries)
  const printQueues = useAppSelector((state) => state.printQueues.entries)
  const printDevicesError = useAppSelector((state) => state.printDevices.error)
  const printQueuesError = useAppSelector((state) => state.printQueues.error)
  const error = printDevicesError || printQueuesError

  const handleCreatePrintDevice = async ({
    macAddress,
    clinicID,
    queueID,
  }: {
    macAddress: string
    clinicID: string
    queueID: number | string
  }) => {
    if (currentUser) {
      try {
        const token = await getIDToken(currentUser)
        await dispatch(
          createPrintDevice({
            token,
            macAddress,
            clinicID,
            queueID: isString(queueID) ? parseInt(queueID, 10) : queueID,
          })
        )
        await handleGetPrintDevices()
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to create print device', { error }),
          severity: 'error',
        })
      }
    }
  }

  const handleGetPrintDevices = async () => {
    if (currentUser) {
      try {
        const token = await getIDToken(currentUser)
        await dispatch(getPrintDevices({ token }))
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to get print devices', { error }),
          severity: 'error',
        })
      }
    }
  }

  const handleDeletePrintDevice = async ({ macAddress }: { macAddress: string }) => {
    if (currentUser) {
      try {
        const token = await getIDToken(currentUser)
        await dispatch(deletePrintDevice({ token, macAddress }))
        await handleGetPrintDevices()
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to delete print device', { error }),
          severity: 'error',
        })
      }
    }
  }

  const handleResetPrintDevice = async ({ macAddress }: { macAddress: string }) => {
    if (currentUser) {
      try {
        const token = await getIDToken(currentUser)
        await dispatch(resetPrintDevice({ token, macAddress }))
        await handleGetPrintDevices()
        notify({
          message: t('Print job submitted successfully'),
          severity: 'success',
        })
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to reset print device', { error }),
          severity: 'error',
        })
      }
    }
  }

  const handleSubmitPrintJob = async ({
    clinicID,
    printingParams,
  }: {
    clinicID: string
    printingParams: {
      patient_id: string
      department_name: string
      reception_number: string
      reception_date: string
      reception_time: string
    }
  }) => {
    if (currentUser) {
      try {
        const token = await getIDToken(currentUser)
        const res = await api.submitPrintJob({ token, clinicID, printingParams })
        console.log('handleSubmitPrintJob', res)
        notify({
          message: t('Print job submitted successfully'),
          severity: 'success',
        })
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to submit print job', { error }),
          severity: 'error',
        })
      }
    }
  }

  const handleCreatePrintQueue = async ({
    name,
    position,
    designTemplate,
  }: {
    name: string
    position?: number | string
    designTemplate?: string
  }) => {
    if (currentUser) {
      try {
        const token = await getIDToken(currentUser)
        await dispatch(
          createPrintQueue({
            token,
            name,
            position: position ? (isString(position) ? parseInt(position, 10) : position) : 0,
            designTemplate: designTemplate || '',
          })
        )
        await handleGetPrintQueues()
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to create print queue', { error }),
          severity: 'error',
        })
      }
    }
  }

  const handleGetPrintQueues = async () => {
    if (currentUser) {
      try {
        const token = await getIDToken(currentUser)
        await dispatch(getPrintQueues({ token }))
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to get print queues', { error }),
          severity: 'error',
        })
      }
    }
  }

  const handleDeletePrintQueue = async ({ queueID }: { queueID: number }) => {
    if (currentUser) {
      try {
        const token = await getIDToken(currentUser)
        await dispatch(deletePrintQueue({ token, queueID }))
        await handleGetPrintQueues()
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to delete print queue', { error }),
          severity: 'error',
        })
      }
    }
  }

  const handleResetPrintQueue = async ({ queueID }: { queueID: number }) => {
    if (currentUser) {
      try {
        const token = await getIDToken(currentUser)
        await dispatch(resetPrintQueue({ token, queueID }))
        await handleGetPrintQueues()
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to reset print queue', { error }),
          severity: 'error',
        })
      }
    }
  }

  useEffect(() => {
    handleGetPrintDevices()
  }, [currentUser, dispatch])

  useEffect(() => {
    handleGetPrintQueues()
  }, [currentUser, dispatch])

  useEffect(() => {
    if (error) {
      notify({
        message: error.message,
        severity: 'error',
      })
    }
  }, [error])

  return (
    <AdminTemplate>
      <Stack spacing={3}>
        <Box sx={{ width: '100%' }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2" fontWeight="bold">
                {t('Print Devices')}
              </Typography>
            </Grid>
            <Grid item>
              <CreatePrintDeviceDialog onSubmit={handleCreatePrintDevice} />
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight="bold">{t('MAC Address')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">{t('Clinic Name')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">{t('Status')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">{t('Print Queue')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">{t('Printing')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">{t('Client Type')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">{t('Client Version')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">{t('Last Connection')}</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {printDevices.map((device) => (
                  <TableRow key={device.macAddress}>
                    <TableCell>
                      <Typography>{device.macAddress}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{device.clinicName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{device.status}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{device.queueID}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{device.printing}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{device.clientType}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{device.clientVersion}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {device.lastPoll
                          ? format(new Date(device.lastPoll * 1000), 'yyyy-MM-dd HH:mm:ss')
                          : null}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PrintDeviceActionMenu
                        printDevice={device}
                        handleSubmitPrintJob={handleSubmitPrintJob}
                        handleDeletePrintDevice={handleDeletePrintDevice}
                        handleResetPrintDevice={handleResetPrintDevice}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2" fontWeight="bold">
                {t('Print Queues')}
              </Typography>
            </Grid>
            <Grid item>
              <CreatePrintQueueDialog onSubmit={handleCreatePrintQueue} />
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight="bold">{t('ID')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">{t('Name')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">{t('Actions')}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {printQueues.map((queue) => (
                  <TableRow key={queue.id}>
                    <TableCell>
                      <Typography>{queue.id}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{queue.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <PrintQueueActionMenu
                        queueID={queue.id}
                        handleDeletePrintQueue={handleDeletePrintQueue}
                        handleResetPrintQueue={handleResetPrintQueue}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Stack>
    </AdminTemplate>
  )
}

function PrintDeviceActionMenu({
  printDevice,
  handleSubmitPrintJob,
  handleDeletePrintDevice,
  handleResetPrintDevice,
}: {
  printDevice: PrintDevice
  handleSubmitPrintJob: (params: { clinicID: string; printingParams: any }) => Promise<void>
  handleDeletePrintDevice: (params: { macAddress: string }) => Promise<void>
  handleResetPrintDevice: (params: { macAddress: string }) => Promise<void>
}) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="device-action-button"
        aria-controls={open ? 'device-action-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="device-action-menu"
        MenuListProps={{
          'aria-labelledby': 'device-action-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 216,
            width: '20ch',
          },
        }}
      >
        <SubmitPrintJobDialog
          clinicID={printDevice.clinicID}
          onSubmit={async (data: { clinicID: string; printingParams: Record<string, string> }) => {
            await handleSubmitPrintJob(data)
            handleClose()
          }}
          WrapperComponent={MenuItem}
        />
        <DialogWithButton
          buttonText={t('Delete')}
          titleText={t('Delete Print Device')}
          content={t('Are you sure you want to delete this print device?')}
          confirmText={t('Delete')}
          onClick={() => {
            handleDeletePrintDevice({ macAddress: printDevice.macAddress }).then(() =>
              handleClose()
            )
          }}
          WrapperComponent={MenuItem}
        />
        <DialogWithButton
          buttonText={t('Reset')}
          titleText={t('Reset Print Device')}
          content={t('Are you sure you want to reset this print device?')}
          confirmText={t('Reset')}
          onClick={() => {
            handleResetPrintDevice({ macAddress: printDevice.macAddress }).then(() => handleClose())
          }}
          WrapperComponent={MenuItem}
        />
      </Menu>
    </div>
  )
}

function PrintQueueActionMenu({
  queueID,
  handleDeletePrintQueue,
  handleResetPrintQueue,
}: {
  queueID: number
  handleDeletePrintQueue: (params: { queueID: number }) => Promise<void>
  handleResetPrintQueue: (params: { queueID: number }) => Promise<void>
}) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="queue-action-button"
        aria-controls={open ? 'queue-action-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="queue-action-menu"
        MenuListProps={{
          'aria-labelledby': 'queue-action-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 216,
            width: '20ch',
          },
        }}
      >
        <DialogWithButton
          buttonText={t('Delete')}
          titleText={t('Delete Print Queue')}
          content={t('Are you sure you want to delete this print queue?')}
          confirmText={t('Delete')}
          onClick={() => {
            handleDeletePrintQueue({ queueID }).then(() => handleClose())
          }}
          WrapperComponent={MenuItem}
        />
        <DialogWithButton
          buttonText={t('Reset')}
          titleText={t('Reset Print Queue')}
          content={t('Are you sure you want to reset this print queue?')}
          confirmText={t('Reset')}
          onClick={() => {
            handleResetPrintQueue({ queueID }).then(() => handleClose())
          }}
          WrapperComponent={MenuItem}
        />
      </Menu>
    </div>
  )
}

function DialogWithButton({
  buttonText,
  titleText,
  content,
  confirmText,
  confirmProps,
  onClick,
  WrapperComponent,
  WrapperProps,
}: {
  buttonText: string
  titleText?: string
  content: ReactElement
  confirmText: string
  confirmProps?: any
  onClick: () => void
  WrapperComponent: any
  WrapperProps?: any
  // WrapperComponent: React.MemoExoticComponent<
  //   ({ children, onClick }: { children: ReactNode, onClick: () => void }) => JSX.Element
  // >
}) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <WrapperComponent onClick={handleClickOpen} {...WrapperProps}>
        {buttonText}
      </WrapperComponent>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="action-dialog-title"
        aria-describedby="action-dialog-description"
      >
        {titleText ? <DialogTitle id="action-dialog-title">{titleText}</DialogTitle> : null}
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" sx={{ color: '#333' }}>
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              onClick()
              handleClose()
            }}
            variant="contained"
            color="error"
            {...confirmProps}
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
