import { FormControlLabel, Radio, RadioGroup, RadioProps } from '@mui/material'
import { useFormContext } from 'react-hook-form'

type GenderFieldProps = {
  name?: string
} & RadioProps

const rules = {
  required: '選択してください',
}

const GenderField = (props: GenderFieldProps) => {
  const { register } = useFormContext()
  const name = props.name || 'gender'

  const registerReturns = register(name, rules)

  return (
    <RadioGroup row>
      <FormControlLabel
        value="男性"
        control={<Radio {...registerReturns} inputProps={{ 'aria-label': name + 'Man' }} />}
        label="男性"
      />
      <FormControlLabel
        value="女性"
        control={<Radio {...registerReturns} inputProps={{ 'aria-label': name + 'Woman' }} />}
        label="女性"
      />
    </RadioGroup>
  )
}

export default GenderField
