import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  CircularProgress,
  ThemeProvider,
  Box,
} from '@mui/material'
import { createTheme } from '@mui/material'
import { Title } from '../../components/Title'
import { PaymentTemplate } from '../../templates'
import { useDeps, useReporter } from '../../../common/contexts'
import { LogoWhite } from '../../../common/components'

const defaultTheme = createTheme({
  palette: {
    background: {
      default: '#EA5505',
    },
    text: {
      primary: '#fff',
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: 1.75,
      letterSpacing: '-0.035em',
      color: '#fff',
    },
    h2: {
      fontSize: '1.65rem',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '-0.03em',
      color: '#fff',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '-0.025em',
      color: '#fff',
    },
    h4: {
      fontSize: '1.25rem',
      lineHeight: 1.5,
      letterSpacing: '-0.02em',
      color: '#fff',
    },
    h5: {
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '-0.02em',
      color: '#fff',
    },
    body1: {
      lineHeight: 1.7,
      letterSpacing: '0.05em',
      color: '#fff',
    },
    caption: {
      fontSize: '0.85rem',
      lineHeight: 1.75,
      letterSpacing: '0.075em',
      color: '#fff',
    },
    button: {
      fontSize: '0.85rem',
      fontWeight: 500,
    },
    fontFamily: "'Noto Sans JP', sans-serif",
  },
})

export function PaymentSuccessPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const deps = useDeps()
  const reporter = useReporter()
  const [clinicName, setClinicName] = useState<string | null>(null)
  const [issuedDate, setIssuedDate] = useState<string | null>(null)
  const [departmentName, setDepartmentName] = useState<string | null>(null)
  const [patientName, setPatientName] = useState<string | null>(null)

  const patientID = searchParams.get('patient_id')
  const clinicID = searchParams.get('clinic_id')
  const invoiceNumber = searchParams.get('invoice_number')
  const lineIDToken = searchParams.get('id_token')

  useEffect(() => {
    if (clinicID && invoiceNumber && lineIDToken && patientID) {
      deps.apiClient
        .getPayment({
          patientID,
          clinicID,
          invoiceNumber,
          lineIDToken,
        })
        .then((res) => {
          console.log(res)
          setClinicName(res.clinicName)
          setDepartmentName(res.departmentName)
          setIssuedDate(res.issuedDate)
          setPatientName(res.patientName)
        })
        .catch((err) => {
          console.error(err)
          reporter.reportException(err, {
            patientID,
            clinicID,
            invoiceNumber,
            lineIDToken,
          })
          navigate('/payment/sorry')
        })
    }
  }, [clinicID, deps, invoiceNumber, lineIDToken, patientID])

  if (!lineIDToken || !clinicID || !invoiceNumber || !patientID) {
    return <Navigate to="/payment/sorry" />
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <PaymentTemplate
        LogoComponent={LogoWhite}
        title={<Title variant="h3">受付までお越しください</Title>}
        backgroundColor={defaultTheme.palette.background.default}
        content={
          <Grid container alignItems="center" justifyContent="center" direction="column">
            <Grid item xs={12} sx={{ padding: '1em 0' }}>
              <Typography>この画面を受付に提示してください</Typography>
            </Grid>
            <Grid item xs={12} sx={{ padding: '1em 0' }}>
              <Typography fontWeight="bold" sx={{ textAlign: 'center' }}>
                {t('Prescription Token')}
              </Typography>
              <Card sx={{ marginTop: '1em' }} data-cy="prescription-token-content">
                {clinicName && patientName && patientID && issuedDate && departmentName ? (
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      data-cy="clinic-name"
                      color="#000"
                      fontWeight="bold"
                    >
                      {clinicName}
                    </Typography>
                    <Typography gutterBottom variant="h5" color="#000" fontWeight="bold">
                      {patientName} 様
                    </Typography>
                    <Typography gutterBottom variant="h5" color="#000" fontWeight="bold">
                      {t('PatientID')}: {patientID}
                    </Typography>
                    <Typography gutterBottom variant="h5" color="#000" fontWeight="bold">
                      {issuedDate ? `${t('Date')}: ${issuedDate}` : null}
                    </Typography>
                    <Typography gutterBottom variant="h5" color="#000" fontWeight="bold">
                      {departmentName}
                    </Typography>
                  </CardContent>
                ) : (
                  <CircularProgress color="inherit" />
                )}
              </Card>
            </Grid>
          </Grid>
        }
        footer={
          <Box>
            <Typography sx={{ mb: 2, textAlign: 'center' }}>
              保険証をお預けの際は、保険証を返却します
            </Typography>
            <Typography sx={{ mb: 2, textAlign: 'center' }}>
              処方箋がない場合もありますが、念のため処方箋の有無を受付で確認してください
            </Typography>
            <Typography>受付に提示が終わったらこの画面を閉じてください</Typography>
          </Box>
        }
      />
    </ThemeProvider>
  )
}
