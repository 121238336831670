import * as Sentry from '@sentry/react'

export interface Logger {
  debug(message?: any, ...optionalParams: any[]): void
  info(message?: any, ...optionalParams: any[]): void
  warn(message?: any, ...optionalParams: any[]): void
  error(message?: any, ...optionalParams: any[]): void
}

export class ConsoleLogger implements Logger {
  debug(message?: any, ...optionalParams: any[]): void {
    console.debug(message, ...optionalParams)
  }

  info(message?: any, ...optionalParams: any[]): void {
    console.info(message, ...optionalParams)
  }

  warn(message?: any, ...optionalParams: any[]): void {
    console.warn(message, ...optionalParams)
  }

  error(message?: any, ...optionalParams: any[]): void {
    console.error(message, ...optionalParams)
  }
}

export class LoggerWithSentry implements Logger {
  logger: Logger

  constructor({ logger }: { logger: Logger }) {
    this.logger = logger
  }

  debug(message?: any, ...optionalParams: any[]): void {
    this.logger.debug(message, ...optionalParams)
  }

  info(message?: any, ...optionalParams: any[]): void {
    this.logger.info(message, ...optionalParams)
  }

  warn(message?: any, ...optionalParams: any[]): void {
    this.logger.warn(message, ...optionalParams)
  }

  error(message?: any, ...optionalParams: any[]): void {
    if (message instanceof Error) {
      Sentry.captureException(message)
    } else {
      Sentry.captureMessage(message)
    }
    this.logger.error(message, ...optionalParams)
  }
}
