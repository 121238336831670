import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import { Title } from '../../components/Title'
import { PaymentTemplate } from '../../templates'
import { useDeps, useReporter } from '../../../common/contexts'

export function PaymentGuidePage() {
  const navigate = useNavigate()
  const deps = useDeps()
  const reporter = useReporter()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const [clinicName, setClinicName] = useState<string | null>(null)
  const [issuedDate, setIssuedDate] = useState<string | null>(null)
  const [departmentName, setDepartmentName] = useState<string | null>(null)
  const [patientName, setPatientName] = useState<string | null>(null)

  const patientID = searchParams.get('patient_id')
  const clinicID = searchParams.get('clinic_id')
  const invoiceNumber = searchParams.get('invoice_number')
  const lineIDToken = searchParams.get('id_token')

  useEffect(() => {
    if (lineIDToken && clinicID && invoiceNumber && patientID) {
      deps.apiClient
        .getPayment({
          patientID,
          lineIDToken,
          clinicID,
          invoiceNumber,
        })
        .then((res) => {
          console.log(res)
          setClinicName(res.clinicName)
          setDepartmentName(res.departmentName)
          setIssuedDate(res.issuedDate)
          setPatientName(res.patientName)
        })
        .catch((err) => {
          console.error(err)
          reporter.reportException(err, {
            patientID,
            clinicID,
            invoiceNumber,
            lineIDToken,
          })
          navigate('/payment/sorry')
        })
    }
  }, [clinicID, deps, invoiceNumber, lineIDToken, patientID])

  if (!lineIDToken || !clinicID || !invoiceNumber || !patientID) {
    return <Navigate to="/payment/sorry" />
  }

  return (
    <PaymentTemplate
      title={<Title>受付で支払う</Title>}
      content={
        <Grid container alignItems="center" justifyContent="center" direction="column">
          <Grid item xs={12} sx={{ padding: '1em 0' }}>
            <Typography>お呼び出しまでお待ちください</Typography>
          </Grid>
          <Grid item xs={12} sx={{ padding: '1em 0' }}>
            <Card sx={{ marginTop: '1em' }}>
              {clinicName && patientName && patientID && issuedDate && departmentName ? (
                <CardContent>
                  <Typography gutterBottom>{clinicName}</Typography>
                  <Typography gutterBottom>{patientName} 様</Typography>
                  <Typography gutterBottom>
                    {t('PatientID')}: {patientID}
                  </Typography>
                  <Typography gutterBottom>
                    {issuedDate ? `${t('Date')}: ${issuedDate}` : null}
                  </Typography>
                  <Typography gutterBottom>{departmentName}</Typography>
                </CardContent>
              ) : (
                <CircularProgress color="inherit" />
              )}
            </Card>
          </Grid>
        </Grid>
      }
      footer={<p>会計が終了後、この画面を閉じてください</p>}
    />
  )
}
