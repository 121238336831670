import { ThemeProvider } from '@mui/material/styles'
import Container from '@mui/material/Container'
import { RegistrationWithFamilyForm } from '../components/RegistrationWithFamilyForm'
import { theme } from './Registration'

export const RegistrationWithFamilyPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <RegistrationWithFamilyForm />
      </Container>
    </ThemeProvider>
  )
}
