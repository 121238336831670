import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ErrorMessage } from '../../types'
import { createContext, useContext } from 'react'
import { Staff } from '../../../ops/types'
import { toErrorMessage } from '../../utils/errors'
import { APIClient } from '../../../../api'
import { Page } from '../../../admin/types'

type StaffsState = {
  page: Page<Staff> | null
  current: Staff | null
  loading: boolean
  error: ErrorMessage | null
}

const initialState: StaffsState = {
  page: null,
  current: null,
  loading: false,
  error: null,
}

type ThunkAPI = {
  extra: {
    api: APIClient
  }
}

const getStaffs = createAsyncThunk<
  Page<Staff>,
  {
    token: string
    offset?: number
    limit?: number
  },
  ThunkAPI
>('staffs/getStaffs', async ({ token, offset, limit }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    const res = await api.getStaffs({ token, offset, limit })
    return res
  } catch (e) {
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const getStaff = createAsyncThunk<
  Staff,
  {
    token: string
    staffID: string
  },
  ThunkAPI
>('staffs/getStaff', async ({ token, staffID }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    const res = await api.getStaff({ token, staffID })
    return res
  } catch (e) {
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const updateStaff = createAsyncThunk<
  Staff,
  {
    token: string
    staffID: string
    clinics: Array<{ clinicID: string }>
  },
  ThunkAPI
>('staffs/updateStaff', async ({ token, staffID, clinics }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    const res = await api.patchStaff({ token, staffID, clinics })
    return res
  } catch (e) {
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const initStaff = createAsyncThunk<
  void,
  {
    token: string
  },
  ThunkAPI
>('staffs/init', async ({ token }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    await api.initStaff({ token })
  } catch (e) {
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const staffsSlice = createSlice({
  name: 'staffs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStaffs.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getStaffs.fulfilled, (state, action) => {
        state.loading = false
        state.page = action.payload
        state.error = null
      })
      .addCase(getStaffs.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(getStaff.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getStaff.fulfilled, (state, action) => {
        state.loading = false
        state.current = action.payload
        state.error = null
      })
      .addCase(getStaff.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(updateStaff.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateStaff.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(updateStaff.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(initStaff.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(initStaff.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(initStaff.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })
  },
})

export const staffsActions = {
  getStaffs,
  getStaff,
  updateStaff,
  initStaff,
}

export const staffsSliceReducer = staffsSlice.reducer

export const StaffsActionsContext = createContext(staffsActions)

export const useStaffsActions = () => useContext(StaffsActionsContext)

export const StaffsActionsProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <StaffsActionsContext.Provider value={staffsActions}>{children}</StaffsActionsContext.Provider>
  )
}
