import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Navigate, Route, Routes } from 'react-router-dom'
import { DrawerToggleProvider, NotificationProvider, useConfig, useDeps } from '../common/contexts'
import { NoMatch } from '../common/pages'
import { CloudPrntPage, LoginPage, StaffPage } from './pages'
import { ThemeProvider, createTheme } from '@mui/material'
import { createStore } from './store'
import { AuthGuard } from '../common/guards'
import { AdminGuard } from './guards'

export function AdminApp() {
  const { config } = useConfig()
  const deps = useDeps()
  const store = createStore(deps)

  const theme = createTheme({
    palette: {
      primary: {
        main: '#3d3a39',
      },
    },
    typography: {
      h1: {
        fontSize: '2rem',
        fontWeight: 500,
        lineHeight: 1.75,
        letterSpacing: '-0.035em',
      },
      h2: {
        fontSize: '1.65rem',
        fontWeight: 500,
        lineHeight: 1.6,
        letterSpacing: '-0.03em',
      },
      h3: {
        fontSize: '1.5rem',
        fontWeight: 500,
        lineHeight: 1.5,
        letterSpacing: '-0.025em',
      },
      h4: {
        fontSize: '1.25rem',
        lineHeight: 1.5,
        letterSpacing: '-0.02em',
      },
      h5: {
        fontSize: '1rem',
        lineHeight: 1.5,
        letterSpacing: '-0.02em',
      },
      body1: {
        lineHeight: 1.7,
        letterSpacing: '0.05em',
      },
      caption: {
        fontSize: '0.85rem',
        lineHeight: 1.75,
        letterSpacing: '0.075em',
      },
      button: {
        fontSize: '0.85rem',
        fontWeight: 500,
      },
      fontFamily: "'Noto Sans JP', sans-serif",
    },
  })

  return (
    <GoogleOAuthProvider clientId={config.oauth2ClientID}>
      <ThemeProvider theme={theme}>
        <NotificationProvider>
          <DrawerToggleProvider>
            <Provider store={store}>
              <Routes>
                <Route path="*" element={<NoMatch />} />
                <Route path="" element={<Navigate to="/admin/cloudprnt" />} />
                <Route path="login" element={<LoginPage />} />
                <Route
                  path="cloudprnt"
                  element={
                    <AuthGuard>
                      <AdminGuard>
                        <CloudPrntPage />
                      </AdminGuard>
                    </AuthGuard>
                  }
                />
                <Route
                  path="staffs"
                  element={
                    <AuthGuard>
                      <AdminGuard>
                        <StaffPage />
                      </AdminGuard>
                    </AuthGuard>
                  }
                />
              </Routes>
            </Provider>
          </DrawerToggleProvider>
        </NotificationProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  )
}
