import { PaymentTemplate } from '../../templates'
import { Title } from '../../components/Title'

export function PaymentFailurePage() {
  return (
    <PaymentTemplate
      title={<Title>支払いに失敗しました</Title>}
      content={<p>お手数ですが受付にこの画面を提示してお支払いをしてください</p>}
      footer={<p>受付に提示が終わったらこの画面を閉じてください</p>}
    />
  )
}
