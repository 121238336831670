export type InsuranceChipProps = {
  insuranceCombinationNumber: string
}

export function InsuranceChip({ insuranceCombinationNumber }: InsuranceChipProps) {
  // TODO: insuranceCombinationNumber は患者によって数字が表す意味が異なるため
  // 何かしら保険、自費などがわかるようにする必要がある
  // const { t } = useTranslation()
  // if (insuranceCombinationNumber === '0001')
  //   return <Chip label={t('Insurance')} variant="outlined" />
  // if (insuranceCombinationNumber === '0002')
  //   return <Chip label={t('Self-pay')} variant="outlined" />
  return null
}
