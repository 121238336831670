import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks'
import { Loading } from '../components'
import { useTranslation } from 'react-i18next'

export function AuthGuard({ children }: { children: ReactNode }) {
  const { currentUser, loading } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (loading) {
    return <Loading message={t('logging in ...')} />
  }

  if (currentUser === null) {
    navigate('/ops/login')
    return null
  }

  return <>{children}</>
}
