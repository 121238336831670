import axios from 'axios'
import { useEffect, useState } from 'react'
import { getRequiredEnvVar } from '../../../utils/env'

export type Address = {
  postcode: string
  pref: string
  city: string
  town: string
}

const useFindAddress = (postalCode: string) => {
  const [address, setAddress] = useState<Address | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const func = async () => {
      if (!(postalCode && postalCode.match(/^[0-9]{7}$/))) {
        return
      }
      try {
        const res = await axios.get<Address[]>(
          getRequiredEnvVar('REACT_APP_POSTCODES_API_V5_URL') + postalCode,
          {
            headers: {
              apiKey: getRequiredEnvVar('REACT_APP_POSTCODES_API_V5_API_KEY'),
            },
          }
        )
        if (res.data.length === 0) {
          setAddress(null)
          setError(`Nonexistent postal code: ${postalCode}`)
          return
        }
        const { postcode, pref, city, town } = res.data[0]
        setAddress({ postcode, pref, city, town })
        setError(null)
      } catch (e) {
        setAddress(null)
        setError(e instanceof Error ? e.message : `unknown error`)
      }
    }
    func()
  }, [postalCode])

  return { address, error }
}

export default useFindAddress
