import { parse } from 'date-fns'
import { useLocation } from 'react-router-dom'
import { FormValue } from '../components/RegistrationForm'
import { CheckedInWithoutReservation } from './CheckIn'

export const RegistrationCheckedInPage = () => {
  const location = useLocation()
  const data = location.state as FormValue
  return (
    // to use this component, forcely create 'patient type' property required by the component
    <CheckedInWithoutReservation
      patient={{
        full_name: `${data.lastName} ${data.firstName}`,
        birthday: parse(data.birthday, 'yyyyMMdd', new Date()).toDateString(),
      }}
    />
  )
}
