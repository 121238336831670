export interface FullNameFormattable {
  firstName: string
  lastName: string
}

export interface FullNameFormatter {
  format(v: FullNameFormattable): string
}

export class JPFullNameFormatter implements FullNameFormatter {
  format(v: FullNameFormattable): string {
    return `${v.lastName} ${v.firstName}`
  }
}
