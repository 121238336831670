'use client'

import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import MuiFormLabel, { FormLabelProps } from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import { zodResolver } from '@hookform/resolvers/zod'
import { ErrorMessage } from './ErrorMessage'
import PostalCodeField, { Address } from './PostalCodeField'
import GenderField from './GenderField'
import BirthdayField from './BirthdayField'
import { SelectClinicField } from './SelectClinicField'
import { Logo } from '../../common/components/Logo'
import { useDeps } from '../../common/contexts'
import { z } from 'zod'

const dateStringConverter = (arg: string): number => {
  const inputYear = arg.substring(0, 4)
  const inputMonth = arg.substring(4, 6)
  const inputDate = arg.substring(6, 8)
  return new Date(`${inputYear}-${inputMonth}-${inputDate}T00:00:00+0900`).getTime()
}

const birthdayFormatValidator = (arg: string): boolean => {
  const inputMonth = arg.substring(4, 6)
  const input = dateStringConverter(arg)
  return !(Number.isNaN(input) || new Date(input).getMonth() !== Number(inputMonth) - 1)
}

const birthdayTooPastValidator = (arg: string): boolean => {
  const min = new Date(`1900-01-01T00:00:00+0900`).getTime()
  const input = dateStringConverter(arg)
  return min <= input
}

const birthdayTooFutureValidator = (arg: string): boolean => {
  const input = dateStringConverter(arg)
  const max = new Date().getTime()
  return input <= max
}

export const nameSchema = z
  .string()
  .regex(
    /^[々ぁ-ゖァ-ヺーＡ-Ｚａ-ｚ\u4E00-\u9FAF\uF91D-\uFA6A]+$/g,
    '漢字・カタカナ・ひらがなで入力してください'
  )
export const kanaNameSchema = z.string().regex(/^[ァ-ヶー]+$/, 'カタカナで入力してください')
export const birthdaySchema = z
  .string()
  .refine(birthdayFormatValidator, { message: '日付の入力に誤りがあります' })
  .refine(birthdayTooPastValidator, { message: '1900年より前の日付は入力できません' })
  .refine(birthdayTooFutureValidator, { message: '未来の日付は入力できません' })
export const requiredStringSchema = z.string().min(1, { message: '入力してください' })

const baseSchema = z.object({
  formID: z.string(),
  line_uid: z.string().optional(),
  patient_uuid: z.string().optional(),
  trace_id: z.string().optional(),
  id_token: z.string().optional(),
  clinicID: z.string().min(1, { message: '選択してください' }),
  lastName: nameSchema,
  firstName: nameSchema,
  lastNameKana: kanaNameSchema,
  firstNameKana: kanaNameSchema,
  birthday: birthdaySchema,
  gender: z.string().min(1, { message: '選択してください' }),
  phoneNumber: z.string().regex(/^[0-9]{10,11}$/, '数字のみを10または11文字で入力してください'),
  postalCode: z.string().regex(/^[0-9]{7}$/, '数字のみを7文字で入力してください'),
  prefecture: requiredStringSchema,
  city: requiredStringSchema,
  address1: requiredStringSchema,
  address2: z.string().optional(),
})

const withFamilySchema = z.discriminatedUnion('withFamily', [
  z.object({
    withFamily: z.literal('0'),
    familyLastName: z.string().optional(),
    familyFirstName: z.string().optional(),
    familyLastNameKana: z.string().optional(),
    familyFirstNameKana: z.string().optional(),
    familyBirthday: z.string().optional(),
    familyGender: z.union([z.null(), z.string()]),
  }),
  z.object({
    withFamily: z.literal('1'),
    familyLastName: nameSchema,
    familyFirstName: nameSchema,
    familyLastNameKana: kanaNameSchema,
    familyFirstNameKana: kanaNameSchema,
    familyBirthday: birthdaySchema,
    familyGender: z.string().min(1, { message: '選択してください' }),
  }),
])

export const schema = baseSchema.and(withFamilySchema)
export type FormValue = z.infer<typeof schema>

const FormLabel = styled(MuiFormLabel)<FormLabelProps>(() => ({
  fontWeight: 700,
  fontSize: '14px',
}))

export function RegistrationWithFamilyForm() {
  const search = useLocation().search
  const queryParams = new URLSearchParams(search)
  const { apiClient } = useDeps()

  const methods = useForm<FormValue>({ mode: 'onTouched', resolver: zodResolver(schema) })
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    trigger,
    formState: { errors, isValid },
  } = methods

  const navigate = useNavigate()

  const labelPrefix = watch('withFamily') === '1' ? 'ご自身の' : ''

  const handlePostalCodeSuccess = async (address: Address | null) => {
    if (address) {
      setValue('prefecture', address.pref)
      setValue('city', address.city)
      setValue('address1', address.town)
      setError('postalCode', { type: 'custom' })
      await trigger('address1')
    }
  }
  const handlePostalCodeError = (message: string | null) => {
    if (message) {
      setValue('prefecture', '')
      setValue('city', '')
      setError('postalCode', { type: 'custom', message })
    }
  }

  const onSubmit = async (data: FormValue) => {
    try {
      // post
      await apiClient.sendWebhook(data)
      // change next page
      if (queryParams.get('via_check_in')) {
        // the patient came here via check-in
        navigate(`/patients/registration/checked-in?clinic_id=${data.clinicID}`, { state: data })
      } else {
        navigate('/patients/registration/complete', { replace: true })
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction={'column'} alignItems="center" rowSpacing={0.5} sx={{ my: 1 }}>
          <Grid item>
            <Logo />
          </Grid>
          <Grid item>
            <Typography variant="h5">患者さま情報の入力</Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} sx={{ my: 1 }}>
            <Typography>
              今後、保健所への申請や、クリニックからの連絡にこちらの登録情報を利用します。
              <br />
              正確な情報入力のご協力をよろしくお願いします。
            </Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12}>
            <FormLabel>受診クリニックを選択</FormLabel>
            <ErrorMessage errors={errors} name="clinicID" />
            <SelectClinicField name="clinicID" />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12}>
            <FormLabel>どなたが受診しますか？</FormLabel>
            <RadioGroup row defaultValue={'0'}>
              <FormControlLabel
                value="0"
                label="ご自身"
                data-cy={`withFamily-option-0`}
                control={<Radio {...register('withFamily')} />}
              />
              <FormControlLabel
                value="1"
                label="ご自身/ご家族"
                data-cy={`withFamily-option-1`}
                control={<Radio {...register('withFamily')} />}
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12}>
            <FormLabel>{labelPrefix}姓</FormLabel>
            <Typography>例）山田</Typography>
            <ErrorMessage errors={errors} name="lastName" />
            <TextField
              {...register('lastName')}
              error={!!errors.lastName}
              fullWidth
              variant="outlined"
              margin="dense"
              id="lastNameInput"
              inputProps={{
                'aria-label': 'lastName',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>{labelPrefix}名</FormLabel>
            <Typography>例）太郎</Typography>
            <ErrorMessage errors={errors} name="firstName" />
            <TextField
              {...register('firstName')}
              error={!!errors.firstName}
              fullWidth
              variant="outlined"
              margin="dense"
              id="firstNameInput"
              inputProps={{
                'aria-label': 'firstName',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>{labelPrefix}姓(カナ)</FormLabel>
            <Typography>例）ヤマダ</Typography>
            <ErrorMessage errors={errors} name="lastNameKana" />
            <TextField
              {...register('lastNameKana')}
              error={!!errors.lastNameKana}
              fullWidth
              variant="outlined"
              margin="dense"
              id="lastNameKanaInput"
              inputProps={{
                'aria-label': 'lastNameKana',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>{labelPrefix}名(カナ)</FormLabel>
            <Typography>例）タロウ</Typography>
            <ErrorMessage errors={errors} name="firstNameKana" />
            <TextField
              {...register('firstNameKana')}
              error={!!errors.firstNameKana}
              fullWidth
              variant="outlined"
              margin="dense"
              id="firstNameKanaInput"
              inputProps={{
                'aria-label': 'firstNameKana',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>{labelPrefix}生年月日</FormLabel>
            <Typography>
              例）1986年12月1日の場合 → 19861201
              <br />
              ※数字のみを入力してください
            </Typography>
            <ErrorMessage errors={errors} name="birthday" />
            <BirthdayField name="birthday" />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>{labelPrefix}性別</FormLabel>
            <Typography>※生物学上の性別にチェックを入れてください</Typography>
            <ErrorMessage errors={errors} name="gender" />
            <GenderField name="gender" />
          </Grid>
          <Grid container item rowSpacing={0}>
            <Grid item xs={12}>
              <FormLabel>{labelPrefix}携帯電話番号</FormLabel>
              <Typography>
                例）09012341234
                <br />
                ※数字のみを入力してください
              </Typography>
              <ErrorMessage errors={errors} name="phoneNumber" />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register('phoneNumber')}
                error={!!errors.phoneNumber}
                variant="outlined"
                margin="dense"
                id="phoneNumberInput"
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '^[0-9]{10,11}$',
                  'aria-label': 'phoneNumber',
                }}
              />
            </Grid>
          </Grid>
          <Grid container item rowSpacing={0}>
            <Grid item xs={12} sx={{ marginBottom: 1 }}>
              <FormLabel>郵便番号</FormLabel>
              <Typography>
                例)1550033
                <br />
                ※数字のみを入力してください
              </Typography>
              <ErrorMessage errors={errors} name="postalCode" />
            </Grid>
            <Grid item xs={6}>
              <PostalCodeField
                name="postalCode"
                onSuccess={handlePostalCodeSuccess}
                onFailure={handlePostalCodeError}
                variant="outlined"
                margin="dense"
                id="postalCodeInput"
              />
            </Grid>
          </Grid>
          <Grid container item columnSpacing={2}>
            <Grid item xs={6} sx={{ marginBottom: 1 }}>
              <FormLabel>都道府県</FormLabel>
              <TextField
                {...register('prefecture')}
                inputProps={{
                  readOnly: true,
                  'aria-label': 'prefecture',
                }}
                fullWidth
                variant="outlined"
                margin="dense"
                id="prefectureInput"
              />
            </Grid>
            <Grid item xs={6} sx={{ marginBottom: 1 }}>
              <FormLabel>市区町村</FormLabel>
              <TextField
                {...register('city')}
                inputProps={{
                  readOnly: true,
                  'aria-label': 'city',
                }}
                fullWidth
                variant="outlined"
                margin="dense"
                id="cityInput"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: 1 }}>
            <FormLabel>番地</FormLabel>
            <Typography>例）北沢2丁目24-45</Typography>
            <ErrorMessage errors={errors} name="address1" />
            <TextField
              {...register('address1')}
              error={!!errors.address1}
              fullWidth
              variant="outlined"
              margin="dense"
              id="address1Input"
              inputProps={{
                'aria-label': 'address1',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>建物名・部屋番号</FormLabel>
            <Typography>
              例）シモキタフロント201
              <br />
              ※該当しない場合、空欄のままにしてください
            </Typography>
            <ErrorMessage errors={errors} name="address2" />
            <TextField
              {...register('address2')}
              error={!!errors.address2}
              fullWidth
              variant="outlined"
              margin="dense"
              id="address2Input"
              inputProps={{
                'aria-label': 'address2',
              }}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            rowSpacing={1}
            sx={watch('withFamily') === '1' ? { display: 'block' } : { display: 'none' }}
          >
            <Grid item xs={12}>
              <FormLabel>ご家族の姓</FormLabel>
              <Typography>例）山田</Typography>
              <ErrorMessage errors={errors} name="familyLastName" />
              <TextField
                {...register('familyLastName')}
                error={!!errors.familyLastName}
                fullWidth
                variant="outlined"
                margin="dense"
                id="familyLastNameInput"
                inputProps={{
                  'aria-label': 'familyLastName',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>ご家族の名</FormLabel>
              <Typography>例）花子</Typography>
              <ErrorMessage errors={errors} name="familyFirstName" />
              <TextField
                {...register('familyFirstName')}
                error={!!errors.familyFirstName}
                fullWidth
                variant="outlined"
                margin="dense"
                id="familyFirstNameInput"
                inputProps={{
                  'aria-label': 'familyFirstName',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>ご家族の姓(カナ)</FormLabel>
              <Typography>例）ヤマダ</Typography>
              <ErrorMessage errors={errors} name="familyLastNameKana" />
              <TextField
                {...register('familyLastNameKana')}
                error={!!errors.familyLastNameKana}
                fullWidth
                variant="outlined"
                margin="dense"
                id="familyLastNameKanaInput"
                inputProps={{
                  'aria-label': 'familyLastNameKana',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>ご家族の名(カナ)</FormLabel>
              <Typography>例）ハナコ</Typography>
              <ErrorMessage errors={errors} name="familyFirstNameKana" />
              <TextField
                {...register('familyFirstNameKana')}
                error={!!errors.familyFirstNameKana}
                fullWidth
                variant="outlined"
                margin="dense"
                id="familyFirstNameKanaInput"
                inputProps={{
                  'aria-label': 'familyFirstNameKana',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>ご家族の生年月日</FormLabel>
              <Typography>
                例）1986年12月1日の場合 → 19861201
                <br />
                ※数字のみを入力してください
              </Typography>
              <ErrorMessage errors={errors} name="familyBirthday" />
              <BirthdayField name="familyBirthday" />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>ご家族の性別</FormLabel>
              <Typography>※生物学上の性別にチェックを入れてください</Typography>
              <ErrorMessage errors={errors} name="familyGender" />
              <GenderField name="familyGender" />
            </Grid>
          </Grid>
          <Grid
            container
            item
            style={{
              backgroundColor: '#fbefea',
              margin: '30px calc(50% - 50vw) 0',
              padding: '0 calc(50vw - 50%)',
              width: '100vw',
            }}
          >
            <Grid
              container
              item
              xs={2}
              justifyContent={'flex-end'}
              alignItems={'center'}
              paddingRight={'10px'}
            >
              <InfoIcon color="warning" sx={{ fontSize: 20 }} />
            </Grid>
            <Grid item xs={10} sx={{ py: 2 }}>
              <Typography color="primary" fontWeight={700}>
                今後の連絡にこちらの登録情報を利用します。
                <br />
                正確な情報入力か、今一度ご確認ください。
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pb: 3 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={!isValid}
                fullWidth
                size="large"
                color="primary"
                sx={{
                  py: 1.5,
                  fontSize: 14,
                  fontWeight: 700,
                  '&.Mui-disabled': {
                    background: '#eaeaea',
                    color: '#fff',
                  },
                }}
              >
                内容を確認したので登録する
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <input type="hidden" {...register('formID')} value="patientRegistrationWithFamilyForm" />
        {
          /* pass all query parameters to this form */
          // @ts-ignore
          (() => {
            const inputs: any = []
            queryParams.forEach((value, key) => {
              inputs.push(
                <input
                  type="hidden"
                  {
                    //@ts-ignore as 'key' here may be undefined in FormValue type
                    ...register(key)
                  }
                  key={`hidden-input-${key}`}
                  name={key}
                  value={value}
                  data-testid={`hidden-input-${key}`}
                />
              )
            })
            return inputs
          })()
        }
      </form>
    </FormProvider>
  )
}
