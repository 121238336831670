import { parseISO } from 'date-fns'
import {
  BroadcastMessage,
  BroadcastMessagePage,
  CheckInPage,
  ClinicPage,
  ClinicPatientPage,
  Interview,
  InterviewPage,
  PatientPage,
} from './types'
import { CheckIn, Clinic, ClinicPatient, Patient } from '../../modules/common/types'

type Page<T> = {
  count: number
  offset: number
  entries: Array<T>
}

export type CheckInResponse = {
  id: string
  clinic_id: string
  patient_uuid: string
  timestamp_in_sec: number
  expires_at: number
  clinic: ClinicResponse
  patient: PatientResponse
}

export type CheckInPageResponse = Page<CheckInResponse>

export type ClinicResponse = {
  clinic_id: string
  display_name: string
  address: string
  access: string
  phone_number: string
  business_hours: Array<any>
  reservationa_department_url: string
  created_at: string
  updated_at: string
}

export type ClinicPageResponse = Page<ClinicResponse>

export type PatientResponse = {
  birthday: string
  full_address: string
  full_name: string
  full_name_kana: string
  gender: string
  last_name: string
  last_name_kana: string
  first_name: string
  first_name_kana: string
  line_user_id: string
  parent_patient_uuid?: string
  patient_uuid: string
  phone_number: string
  created_at: string
  updated_at: string
}

export type PatientPageResponse = Page<PatientResponse>

export type ClinicPatientResponse = PatientResponse & {
  patient_id?: string
}

export type ClinicPatientPageResponse = Page<ClinicPatientResponse>

export type InterviewResponse = {
  clinic_id: string
  created_at: string
  department_code: string
  patient_uuid: string
  reservation_id: string
  response: string
  updated_at: string
}

export type InterviewPageResponse = Page<InterviewResponse>

export type BroadcastMessageResponse = {
  id: string
  content: string
  patient_uuids: Array<string>
  created_at: string
  updated_at: string
}

export type BroadcastMessagePageResponse = Page<BroadcastMessageResponse>

export const convertResponseToCheckIn = (res: CheckInResponse): CheckIn => {
  return {
    id: res.id,
    clinicID: res.clinic_id,
    patientUUID: res.patient_uuid,
    timestampInSec: res.timestamp_in_sec,
    expiresAt: res.expires_at,
    clinic: convertResponseToClinic(res.clinic),
    patient: convertResponseToPatient(res.patient),
  }
}

export const convertResponseToCheckInPage = (res: CheckInPageResponse): CheckInPage => {
  return {
    count: res.count,
    entries: res.entries.map(convertResponseToCheckIn),
    offset: res.offset,
  }
}

export const convertResponseToClinic = (res: ClinicResponse): Clinic => {
  return {
    clinicID: res.clinic_id,
    displayName: res.display_name,
    address: res.address,
    access: res.access,
    phoneNumber: res.phone_number,
    businessHours: res.business_hours,
    reservationDepartmentURL: res.reservationa_department_url,
    createdAt: res.created_at,
    updatedAt: res.updated_at,
  }
}

export const convertResponseToClinicPage = (res: ClinicPageResponse): ClinicPage => {
  return {
    count: res.count,
    entries: res.entries.map(convertResponseToClinic),
    offset: res.offset,
  }
}

export const convertResponseToPatient = (res: PatientResponse): Patient => {
  return {
    birthday: res.birthday,
    fullAddress: res.full_address,
    fullName: res.full_name,
    fullNameKana: res.full_name_kana,
    firstName: res.first_name,
    firstNameKana: res.first_name_kana,
    gender: res.gender,
    lastName: res.last_name,
    lastNameKana: res.last_name_kana,
    lineUserID: res.line_user_id,
    parentPatientUUID: res.parent_patient_uuid,
    patientUUID: res.patient_uuid,
    phoneNumber: res.phone_number,
    createdAt: parseISO(res.created_at),
    updatedAt: parseISO(res.updated_at),
  }
}

export const convertResponseToPatientPage = (res: PatientPageResponse): PatientPage => {
  return {
    count: res.count,
    entries: res.entries.map(convertResponseToPatient),
    offset: res.offset,
  }
}

export const convertResponseToClinicPatient = (res: ClinicPatientResponse): ClinicPatient => {
  return {
    birthday: res.birthday,
    fullAddress: res.full_address,
    fullName: res.full_name,
    fullNameKana: res.full_name_kana,
    firstName: res.first_name,
    firstNameKana: res.first_name_kana,
    gender: res.gender,
    lastName: res.last_name,
    lastNameKana: res.last_name_kana,
    lineUserID: res.line_user_id,
    parentPatientUUID: res.parent_patient_uuid,
    patientUUID: res.patient_uuid,
    phoneNumber: res.phone_number,
    patientID: res.patient_id,
    createdAt: parseISO(res.created_at),
    updatedAt: parseISO(res.updated_at),
  }
}

export const convertResponseToClinicPatientPage = (
  res: ClinicPatientPageResponse
): ClinicPatientPage => {
  return {
    count: res.count,
    entries: res.entries.map(convertResponseToClinicPatient),
    offset: res.offset,
  }
}

export const convertResponseToInterview = (res: InterviewResponse): Interview => {
  return {
    clinicID: res.clinic_id,
    createdAt: res.created_at,
    departmentCode: res.department_code,
    patientUUID: res.patient_uuid,
    reservationID: res.reservation_id,
    response: res.response,
    updatedAt: res.updated_at,
  }
}

export const convertResponseToInterviewPage = (res: InterviewPageResponse): InterviewPage => {
  return {
    count: res.count,
    entries: res.entries.map(convertResponseToInterview),
    offset: res.offset,
  }
}

export const convertResponseToBroadcastMessage = (
  res: BroadcastMessageResponse
): BroadcastMessage => {
  return {
    id: res.id,
    content: res.content,
    patientUUIDs: res.patient_uuids,
    messages: [], // TODO:
    createdAt: parseISO(res.created_at),
    updatedAt: parseISO(res.updated_at),
  }
}

export const convertResponseToBroadcastMessagePage = (
  res: BroadcastMessagePageResponse
): BroadcastMessagePage => {
  return {
    count: res.count,
    offset: res.offset,
    entries: res.entries.map(convertResponseToBroadcastMessage),
  }
}
