import { useMemo } from 'react'
import { Viewer } from '@react-pdf-viewer/core'
import { Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'

export type PDFViewerProps = {
  b64str?: string
}

export function PDFViewer({ b64str }: PDFViewerProps) {
  const blob = useMemo(() => {
    if (!b64str) {
      return null
    }
    const bytes = atob(b64str)
    let length = bytes.length
    let out = new Uint8Array(length)

    while (length--) {
      out[length] = bytes.charCodeAt(length)
    }

    return new Blob([out], { type: 'application/pdf' })
  }, [b64str])

  if (!blob) {
    return null
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Viewer fileUrl={URL.createObjectURL(blob)} />
    </Worker>
  )
}
