import { Config } from '../config'
import { isString } from '../typeguards'
import { Context, FallbackFunction, StrategySetting } from './interface'
import { createFallbackFunction } from './utils'

export interface Strategy {
  isEnabled(name: string, context?: Context, fallback?: FallbackFunction | boolean): boolean
}

export class BooleanStrategy implements Strategy {
  name: string
  enabled: boolean

  constructor({ name, enabled }: { name: string; enabled: boolean }) {
    this.name = name
    this.enabled = enabled
  }

  isEnabled = (
    name: string,
    context: Context = {},
    fallback: boolean | FallbackFunction | undefined = undefined
  ): boolean => {
    const fallbackFn = createFallbackFunction(fallback)
    return (this.name === name && this.enabled) || fallbackFn(name, context)
  }
}

export class EnvStrategy implements Strategy {
  name: string
  currentEnv: string
  enabledEnv: Array<string>

  constructor({
    name,
    currentEnv,
    enabledEnv,
  }: {
    name: string
    currentEnv: string
    enabledEnv: Array<string>
  }) {
    this.name = name
    this.currentEnv = currentEnv
    this.enabledEnv = enabledEnv
  }

  isEnabled = (
    name: string,
    context: Context = {},
    fallback: boolean | FallbackFunction | undefined = undefined
  ): boolean => {
    const fallbackFn = createFallbackFunction(fallback)
    return (
      (this.name === name && this.enabledEnv.includes(this.currentEnv)) || fallbackFn(name, context)
    )
  }
}

export class ClinicsStrategy implements Strategy {
  name: string
  currentEnv: string
  enabled: Array<{
    clinicID: string
    env: string
  }>

  constructor({
    name,
    currentEnv,
    enabled,
  }: {
    name: string
    currentEnv: string
    enabled: Array<{
      clinicID: string
      env: string
    }>
  }) {
    this.name = name
    this.currentEnv = currentEnv
    this.enabled = enabled
  }

  isEnabled = (
    name: string,
    context: Context = {},
    fallback: boolean | FallbackFunction | undefined = undefined
  ): boolean => {
    const fallbackFn = createFallbackFunction(fallback)
    if (this.name !== name) {
      return fallbackFn(name, context)
    }
    const { clinicID } = context
    if (!clinicID || !isString(clinicID)) {
      return fallbackFn(name, context)
    }
    return (
      this.enabled.some((v) => v.clinicID === clinicID && v.env === this.currentEnv) ||
      fallbackFn(name, context)
    )
  }
}

export const buildStrategiesFromSettings = (
  config: Config,
  settings: Array<StrategySetting>
): Array<Strategy> => {
  const strategies: Array<Strategy> = []
  settings.forEach((setting) => {
    if (setting.strategy === 'env') {
      strategies.push(
        new EnvStrategy({
          name: setting.name,
          currentEnv: config.env,
          enabledEnv: setting.enabledEnv,
        })
      )
    } else if (setting.strategy === 'clinic') {
      strategies.push(
        new ClinicsStrategy({
          name: setting.name,
          currentEnv: config.env,
          enabled: setting.enabled,
        })
      )
    } else {
      throw new Error(
        `strategy ${(setting as any).strategy} is not supported, ${JSON.stringify(
          setting,
          null,
          2
        )}`
      )
    }
  })
  return strategies
}
