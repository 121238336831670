import * as Sentry from '@sentry/react'
import { CaptureContext } from '@sentry/types'

type Context = {
  [key: string]: string | undefined
  patientID?: string
  clinicID?: string
  invoiceNumber?: string
  lineIDToken?: string
}

const toCaptureContext = (context?: Context): CaptureContext | undefined => {
  if (!context) {
    return
  }
  return { extra: context }
}

export interface Reporter {
  reportException: (err: any, context: Context | undefined) => void
}

export const createSentryReporter = (): Reporter => ({
  reportException: (err: any, context: Context | undefined = undefined) => {
    Sentry.captureException(err, toCaptureContext(context))
  },
})

export const createFakeReporter = (): Reporter => ({
  reportException: (err: any, context: Context | undefined = undefined) => {
    console.error(err, context)
  },
})
