import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Card, CardContent, Grid, Typography, CircularProgress } from '@mui/material'
import { QRCodeImage } from '../../components/QRCodeImage'
import { Title } from '../../components/Title'
import { PaymentTemplate } from '../../templates'
import { useDeps, useFeatureFlag, useReporter } from '../../../common/contexts'
import { Loading } from '../../../common/components'

export function PaymentSelfCheckoutPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const { apiClient } = useDeps()
  const reporter = useReporter()
  const { loading, featureFlag } = useFeatureFlag()

  const [clinicName, setClinicName] = useState<string | null>(null)
  const [issuedDate, setIssuedDate] = useState<string | null>(null)

  const errorMessage = searchParams.get('error')
  const patientID = searchParams.get('patient_id')
  const clinicID = searchParams.get('clinic_id')
  const invoiceNumber = searchParams.get('invoice_number')
  const lineIDToken = searchParams.get('id_token')

  useEffect(() => {
    if (clinicID && invoiceNumber && lineIDToken && patientID) {
      apiClient
        .getPayment({
          patientID,
          clinicID,
          invoiceNumber,
          lineIDToken,
        })
        .then((res) => {
          setClinicName(res.clinicName)
          setIssuedDate(res.issuedDate)
        })
        .catch((err) => {
          console.error(err)
          reporter.reportException(err, {
            patientID,
            clinicID,
            invoiceNumber,
            lineIDToken,
          })
          navigate('/payment/sorry')
        })
    }
  }, [clinicID, invoiceNumber, lineIDToken, patientID])

  if (loading) {
    return <Loading />
  }

  if (!lineIDToken || !clinicID || !invoiceNumber || !patientID) {
    return <Navigate to="/payment/sorry" />
  }

  const isReady = clinicName && patientID && issuedDate

  if (!featureFlag.isEnabled('consultation', { clinicID })) {
    return <Navigate to="/payment/sorry" />
  }

  return (
    <PaymentTemplate
      title={
        errorMessage && (
          <Grid container>
            <Grid item xs={12}>
              <Title variant="h5" sx={{ textAlign: 'center' }} data-cy="error-message">
                {errorMessage}
              </Title>
            </Grid>
            <Grid item xs={12} sx={{ padding: '1em 0' }}>
              <Typography gutterBottom data-cy="sorry-message" textAlign="center">
                お手数ですが自動会計機に
                <br />
                この画面を提示して
                <br />
                お支払いをしてください
              </Typography>
            </Grid>
          </Grid>
        )
      }
      content={
        <Grid container alignItems="center" justifyContent="center" direction="column">
          {isReady ? (
            <>
              <Grid item xs={12} sx={{ p: 0 }} data-cy={`qrcode-${patientID}`}>
                <QRCodeImage value={patientID} />
              </Grid>
              <Grid item xs={12} sx={{ padding: '1em 0' }}>
                <Typography variant="h5">
                  <Box sx={{ fontWeight: 'bold', textAlign: 'center' }} data-cy="title">
                    自動会計機
                    <br />
                    読み取りバーコード
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ padding: '1em 0' }}>
                <Card sx={{ marginTop: '1em' }}>
                  {isReady ? (
                    <CardContent style={{ textAlign: 'center' }}>
                      <Typography gutterBottom data-cy="clinic-name">
                        {clinicName}
                      </Typography>
                      <Typography gutterBottom>
                        {t('PatientID')}: {patientID}
                      </Typography>
                      <Typography gutterBottom data-cy="issued-date">
                        {issuedDate ? issuedDate : null} {'お会計'}
                      </Typography>
                    </CardContent>
                  ) : (
                    <CircularProgress color="inherit" data-cy="circular-progress" />
                  )}
                </Card>
              </Grid>
            </>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', minHeight: '200px' }}
            >
              <Grid item>
                <CircularProgress color="inherit" />
              </Grid>
            </Grid>
          )}
        </Grid>
      }
      footer={
        <Box>
          <Typography textAlign="center">
            お支払い完了後
            <br />
            この画面を閉じてください
          </Typography>
        </Box>
      }
    />
  )
}
