import { APIError } from '../../../../api'
import { ErrorMessage } from '../../types'

export const toErrorMessage = (e: any): ErrorMessage => {
  if (e instanceof APIError) {
    return {
      status: e.status,
      message: e.message,
    }
  }
  if (e instanceof Error) {
    return {
      message: e.message,
    }
  }
  return {
    message: 'unknown error',
  }
}
