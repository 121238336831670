import { format } from 'date-fns'
import { useState } from 'react'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { CardCvcElement, useElements } from '@stripe/react-stripe-js'
import { useStripe } from '../contexts'
import { Box, Button, Container, FormControl, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../common/components'
import { useDeps, useReporter } from '../../common/contexts'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

type PaymentFormProps = {
  lineIDToken: string
  patientID: string
  clinicID: string
  invoiceNumber: string
  paymentIntent: any
  paymentMethod: any
  onSuccess?: () => void
  onFailure?: () => void
}

export const PaymentForm = ({
  lineIDToken,
  patientID,
  clinicID,
  invoiceNumber,
  paymentIntent,
  paymentMethod,
  onSuccess,
  onFailure,
}: PaymentFormProps) => {
  const { stripe } = useStripe()
  const elements = useElements()
  const [cvcError, setCvcError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const deps = useDeps()
  const reporter = useReporter()
  const { t } = useTranslation()

  const { card } = paymentMethod

  const expiry = new Date(`${card.expYear}-${('00' + card.expMonth).slice(-2)}-01`)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (stripe && elements) {
      stripe
        .createToken('cvc_update', elements.getElement(CardCvcElement)!)
        .then((result) => {
          console.log(result)
          if (result.error) {
            setCvcError(result.error.message || '')
          } else {
            setLoading(true)
            return deps.apiClient
              .confirmPayment({
                lineIDToken,
                patientID,
                clinicID,
                invoiceNumber,
                paymentIntentID: paymentIntent.id,
                paymentMethodID: paymentMethod.id,
              })
              .then((res) => {
                console.log(res)
                if (onSuccess) {
                  onSuccess()
                }
              })
              .catch((err) => {
                throw err
              })
              .finally(() => setLoading(false))
          }
        })
        .catch((err) => {
          console.error(err)
          reporter.reportException(err, {
            patientID,
            clinicID,
            invoiceNumber,
            lineIDToken,
          })
          if (onFailure) {
            onFailure()
          }
        })
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Container sx={{ padding: '2em' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container direction="column">
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography fontWeight="bold">{t('Card Number')}</Typography>
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <CreditCardIcon />
                <span style={{ marginLeft: '0.5em' }}>{`**** **** **** ${card.last4}`}</span>
              </p>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography fontWeight="bold">{t('Expiration')}</Typography>
              <p>{format(expiry, 'MM/yyyy')}</p>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography fontWeight="bold">{t('CVC')}</Typography>
              <Box sx={{ margin: '16px 0' }}>
                <CardCvcElement
                  id="cvc"
                  options={ELEMENT_OPTIONS}
                  onChange={() => {
                    setCvcError(null)
                  }}
                />
              </Box>
              <p>{cvcError}</p>
            </FormControl>
          </Grid>

          <Box sx={{ width: '100%' }}>
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              fullWidth
              data-cy="make-payment"
            >
              {t('Make Payment')}
            </Button>
          </Box>
        </Grid>
      </form>
    </Container>
  )
}
