import axios from 'axios'
import { useState } from 'react'
import { useConfig } from '../../common/contexts'
import { useEffectOnce } from 'react-use'

export type Clinic = {
  clinicID: string
  clinicName: string
  clinicShortName: string
}

const useGetClinics = () => {
  const { config } = useConfig()
  const [clinics, setClinics] = useState<Clinic[]>([])

  useEffectOnce(() => {
    const func = async () => {
      const res = await axios.get<Clinic[]>(`${config.apiBaseURL}/clinics/all`)
      setClinics(res.data)
    }
    func()
  })

  return clinics
}

export default useGetClinics
