import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ErrorMessage } from '../../../common/types'
import { APIClient } from '../../../../api'
import { PrintDevice } from '../../types'
import { toErrorMessage } from '../../../common/utils/errors'
import { createContext, useContext } from 'react'

type PrintDevicesState = {
  entries: Array<PrintDevice>
  loading: boolean
  error: ErrorMessage | null
}

const initialState: PrintDevicesState = {
  entries: [],
  loading: false,
  error: null,
}

type ThunkAPI = {
  extra: {
    api: APIClient
  }
}

const createPrintDevice = createAsyncThunk<
  void,
  {
    token: string
    clinicID: string
    macAddress: string
    queueID: number
  },
  ThunkAPI
>('printDevices/createPrintDevice', async ({ token, clinicID, macAddress, queueID }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    await api.createPrintDevice({
      token,
      clinicID,
      macAddress,
      queueID,
    })
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.log('rejected ...', e.cause)
    }
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const getPrintDevices = createAsyncThunk<
  { entries: Array<PrintDevice> },
  {
    token: string
  },
  ThunkAPI
>('printDevices/getPrintDevices', async ({ token }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    const res = await api.getPrintDevices({
      token,
    })
    return res
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.log('rejected ...', e.cause)
    }
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const deletePrintDevice = createAsyncThunk<
  void,
  {
    token: string
    macAddress: string
  },
  ThunkAPI
>('printDevices/deletePrintDevice', async ({ token, macAddress }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    await api.deletePrintDevice({
      token,
      macAddress,
    })
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.log('rejected ...', e.cause)
    }
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const resetPrintDevice = createAsyncThunk<
  void,
  {
    token: string
    macAddress: string
  },
  ThunkAPI
>('printDevices/resetPrintDevice', async ({ token, macAddress }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    await api.resetPrintDevice({
      token,
      macAddress,
    })
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.log('rejected ...', e.cause)
    }
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const printDevicesSlice = createSlice({
  name: 'printDevices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPrintDevice.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createPrintDevice.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(createPrintDevice.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(getPrintDevices.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getPrintDevices.fulfilled, (state, action) => {
        state.loading = false
        state.entries = action.payload.entries
        state.error = null
      })
      .addCase(getPrintDevices.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(deletePrintDevice.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deletePrintDevice.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(deletePrintDevice.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(resetPrintDevice.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(resetPrintDevice.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(resetPrintDevice.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })
  },
})

export const printDevicesActions = {
  createPrintDevice,
  getPrintDevices,
  deletePrintDevice,
  resetPrintDevice,
}

export const printDevicesSliceReducer = printDevicesSlice.reducer

export const PrintDevicesActionsContext = createContext(printDevicesActions)

export const usePrintDevicesActions = () => useContext(PrintDevicesActionsContext)

export const PrintDevicesActionsProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <PrintDevicesActionsContext.Provider value={printDevicesActions}>
      {children}
    </PrintDevicesActionsContext.Provider>
  )
}
