import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ErrorMessage } from '../../../common/types'
import { createContext, useContext } from 'react'
import { toErrorMessage } from '../../../common/utils/errors'
import { APIClient } from '../../../../api'

type ConsultationReceiptState = {
  current: {
    encodedPDF: string
  } | null
  loading: boolean
  error: ErrorMessage | null
}

const initialState: ConsultationReceiptState = {
  current: null,
  loading: false,
  error: null,
}

type ThunkAPI = {
  extra: {
    api: APIClient
  }
}

const downloadReceipt = createAsyncThunk<
  { encodedPDF: string },
  {
    token: string
    consultationID: string
  },
  ThunkAPI
>('consultations/downloadReceipt', async ({ token, consultationID }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    const res = await api.downloadConsultationReceipt({
      token: token,
      consultationID,
    })
    return res
  } catch (e) {
    console.error(e)
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const generateReceipt = createAsyncThunk<
  void,
  {
    token: string
    consultationID: string
  },
  ThunkAPI
>('consultations/generateReceipt', async ({ token, consultationID }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    await api.generateConsultationReceipt({
      token: token,
      consultationID,
    })
    await thunkAPI.dispatch(downloadReceipt({ token, consultationID }))
  } catch (e) {
    console.error(e)
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const consultationReceiptSlice = createSlice({
  name: 'consultationReceipt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadReceipt.pending, (state) => {
        state.current = null
        state.loading = true
        state.error = null
      })
      .addCase(downloadReceipt.fulfilled, (state, action) => {
        state.current = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(downloadReceipt.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(generateReceipt.pending, (state) => {
        state.current = null
        state.loading = true
        state.error = null
      })
      .addCase(generateReceipt.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(generateReceipt.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })
  },
})

export const consultationReceiptActions = {
  downloadReceipt,
  generateReceipt,
}

export const consultationReceiptSliceReducer = consultationReceiptSlice.reducer

export const ConsultationReceiptActionsContext = createContext(consultationReceiptActions)

export const useConsultationReceiptActions = () => useContext(ConsultationReceiptActionsContext)

export const ConsultationReceiptActionsProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ConsultationReceiptActionsContext.Provider value={consultationReceiptActions}>
      {children}
    </ConsultationReceiptActionsContext.Provider>
  )
}
