import { assertNotNull } from '../typeguards'
import { getRequiredEnvVar } from '../utils/env'

export type Env = 'local' | 'stg' | 'prod' | 'testing'

export class Config {
  env: Env
  apiBaseURL: string
  stripePublishableKey: string
  lineChannelURL: string
  gtmID: string
  oauth2ClientID: string
  liffID: string
  liffBaseRedirectURL: string

  constructor({
    env,
    apiBaseURL,
    stripePublishableKey,
    lineChannelURL,
    gtmID,
    oauth2ClientID,
    liffID,
    liffBaseRedirectURL,
  }: {
    env: Env
    apiBaseURL: string
    stripePublishableKey: string
    lineChannelURL: string
    gtmID: string
    oauth2ClientID: string
    liffID: string
    liffBaseRedirectURL: string
  }) {
    this.env = env
    this.apiBaseURL = apiBaseURL
    this.stripePublishableKey = stripePublishableKey
    this.lineChannelURL = lineChannelURL
    this.gtmID = gtmID
    this.oauth2ClientID = oauth2ClientID
    this.liffID = liffID
    this.liffBaseRedirectURL = liffBaseRedirectURL
  }

  static local(): Config {
    console.log(' ***** local config ***** ')
    console.log(process.env)
    const stripePublishableKey = getRequiredEnvVar('REACT_APP_STRIPE_PUBLISHABLE_KEY')
    return new this({
      env: 'local',
      apiBaseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000',
      stripePublishableKey,
      lineChannelURL: createLineChannelURL(getRequiredEnvVar('REACT_APP_LINE_BOT_BASIC_ID')),
      gtmID: getRequiredEnvVar('REACT_APP_GTM_ID'),
      oauth2ClientID: getRequiredEnvVar('REACT_APP_OAUTH2_CLIENT_ID'),
      liffID: getRequiredEnvVar('REACT_APP_LIFF_ID'),
      liffBaseRedirectURL: getRequiredEnvVar('REACT_APP_LIFF_BASE_REDIRECT_URL'),
    })
  }

  static testing(): Config {
    return new this({
      env: 'testing',
      apiBaseURL: 'http://localhost:3000',
      stripePublishableKey: 'xxx',
      lineChannelURL: 'https://example.com',
      gtmID: '',
      oauth2ClientID: '',
      liffID: '',
      liffBaseRedirectURL: '',
    })
  }

  static prod(): Config {
    console.log(' ***** prod config ***** ')
    const apiBaseURL = process.env.REACT_APP_API_BASE_URL
    assertNotNull(apiBaseURL)
    const stripePublishableKey = getRequiredEnvVar('REACT_APP_STRIPE_PUBLISHABLE_KEY')
    return new this({
      env: (process.env.REACT_APP_ENV as Env | undefined) || 'stg',
      apiBaseURL,
      stripePublishableKey,
      lineChannelURL: createLineChannelURL(getRequiredEnvVar('REACT_APP_LINE_BOT_BASIC_ID')),
      gtmID: getRequiredEnvVar('REACT_APP_GTM_ID'),
      oauth2ClientID: getRequiredEnvVar('REACT_APP_OAUTH2_CLIENT_ID'),
      liffID: getRequiredEnvVar('REACT_APP_LIFF_ID'),
      liffBaseRedirectURL: getRequiredEnvVar('REACT_APP_LIFF_BASE_REDIRECT_URL'),
    })
  }

  static fromEnv(): Config {
    if (process.env.NODE_ENV !== 'production') {
      return this.local()
    }
    return this.prod()
  }
}

const createLineChannelURL = (botBasicID: string): string => {
  return 'https://line.me/R/ti/p/' + encodeURIComponent(botBasicID)
}
