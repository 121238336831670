import { useTranslation } from 'react-i18next'
import { AppBar, Box, Button, Container, Grid, Paper, Toolbar, Typography } from '@mui/material'
import { useAuth } from '../../common/hooks'
import { Navigate } from 'react-router-dom'
import { useNotification } from '../../common/contexts'

export function LoginPage() {
  const { currentUser, error, login } = useAuth()
  const { notify } = useNotification()
  const { t } = useTranslation()

  const handleLogin = () => {
    const handle = async () => {
      try {
        await login()
      } catch (e) {
        const error = e instanceof Error ? e.message : t('unknown error')
        notify({
          message: t('Failed to login', { error }),
          severity: 'error',
        })
      }
    }
    handle()
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  if (currentUser) {
    return <Navigate to="/ops" />
  }

  return (
    <Container>
      <Box>
        <AppBar
          variant="elevation"
          elevation={4}
          position="sticky"
          color="default"
          sx={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
            marginBottom: '1rem',
          }}
        >
          <Toolbar>
            <Typography
              variant="h2"
              gutterBottom
              component="div"
              sx={{ fontSize: '21px', fontWeight: 'bold' }}
            >
              {t('Clinicplus Admin')}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant="elevation" elevation={1} sx={{ p: '2rem' }}>
              <Box sx={{ marginTop: '1rem' }}>
                <Button variant="contained" onClick={() => handleLogin()}>
                  {t('Sign in')}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
