import { Chip } from '@mui/material'

export type GenderChipProps = {
  gender: string
}

export function GenderChip({ gender }: GenderChipProps) {
  if (gender === '男性') {
    return <Chip label={gender} color="info" size="small" />
  }
  if (gender === '女性') {
    return <Chip label={gender} color="error" size="small" />
  }
  return <Chip label={gender} size="small" />
}
