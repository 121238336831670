import { Conflict, IDTokenExpiredError, NotFound, Unauthenticated } from './errors'
import { HTTPClient, IHTTPClient } from '../httpclient'
import {
  APIClient,
  VerifyInput,
  VerifyOutput,
  GetPatientsInput,
  GetPatientsOutput,
  BroadcastInput,
  GetPatientMessageInput,
  GetPatientMessageOutput,
  UnicastInput,
  GetBroadcastHistoriesInput,
  GetBroadcastHistoriesOutput,
  GetClinicsInput,
  GetClinicsOutput,
  GetPatientInput,
  GetPatientOutput,
  GetUserInfoInput,
  GetUserInfoOutput,
  GetStaffClinicInput,
  GetStaffClinicOutput,
  GetStaffClinicsInput,
  GetStaffClinicsOutput,
  AddStaffToClinicsInput,
  GetClinicPatientsInput,
  GetClinicPatientsOutput,
  GetClinicPatientInterviewsInput,
  GetClinicPatientInterviewsOutput,
  GetClinicPatientInput,
  GetClinicPatientOutput,
  GetClinicCheckInsInput,
  GetClinicCheckInsOutput,
  BroadcastMessagePage,
  CreatePaymentInput,
  CreatePaymentOutput,
  ConfirmPaymentInput,
  ConfirmPaymentOutput,
  GetPatientMeInput,
  GetPatientMeOutput,
  GetPaymentInput,
  GetPaymentOutput,
  GetFeatureFlagSettingsInput,
  GetFeatureFlagSettingsOutput,
  UploadImageInput,
  UploadImageOutput,
  RequestTransferToFamilyInput,
  RequestTransferToFamilyOutput,
} from './types'
import {
  BroadcastMessagePageResponse,
  CheckInPageResponse,
  ClinicPageResponse,
  ClinicPatientPageResponse,
  ClinicPatientResponse,
  ClinicResponse,
  InterviewPageResponse,
  convertResponseToBroadcastMessagePage,
  convertResponseToCheckInPage,
  convertResponseToClinic,
  convertResponseToClinicPage,
  convertResponseToClinicPatient,
  convertResponseToClinicPatientPage,
  convertResponseToInterviewPage,
  convertResponseToPatientPage,
} from './converters'

const handleError = (status: number, data: any) => {
  if (status >= 400) {
    console.log('handleError', status, data)
  }
  if (status === 401) {
    if ((data as any).message === 'id_token_expired') {
      throw new IDTokenExpiredError()
    }
    throw new Unauthenticated()
  }
  if (status === 409) {
    throw new Conflict()
  }
  if (status === 404) {
    const err = new NotFound('not found')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    err.cause = data
    throw err
  }
  if (status !== 200) {
    throw new Error(`api call failed, res: ${JSON.stringify(data)}`)
  }
}

export class ClinicplusAPIClient implements APIClient {
  baseURL: string
  httpClient: IHTTPClient

  constructor({ baseURL, httpClient }: { baseURL: string; httpClient: IHTTPClient }) {
    this.baseURL = baseURL
    this.httpClient = httpClient
  }

  static create({
    baseURL,
    httpClient,
  }: {
    baseURL: string
    httpClient?: IHTTPClient
  }): ClinicplusAPIClient {
    return new this({
      baseURL,
      httpClient: httpClient || HTTPClient.create(),
    })
  }

  verify = async (input: VerifyInput): Promise<VerifyOutput> => {
    const res = await this.httpClient.post<{
      clinics: Array<{
        clinic_id: string
        clinic_name: string
      }>
    }>(`${this.baseURL}/verify`, { id_token: input.idToken })
    const { status, data } = res
    handleError(status, data)
    const { clinics } = data
    return {
      clinics: clinics.map((c) => ({ clinicID: c.clinic_id, clinicName: c.clinic_name })),
    }
  }

  getFeatureFlagSettings = async (
    input: GetFeatureFlagSettingsInput
  ): Promise<GetFeatureFlagSettingsOutput> => {
    const res = await this.httpClient.get<any>(`${this.baseURL}/feature_flag/settings`)
    const { status, data } = res
    handleError(status, data)
    console.log(data)
    return data
  }

  getUserInfo = async (input: GetUserInfoInput): Promise<GetUserInfoOutput> => {
    const res = await this.httpClient.get<any>(`${this.baseURL}/admin/me`, {
      Authorization: `Bearer ${input.idToken}`,
    })
    const { status, data } = res
    handleError(status, data)
    console.log(data)
    return data
  }

  getClinics = async (input: GetClinicsInput): Promise<GetClinicsOutput> => {
    const res = await this.httpClient.get<ClinicPageResponse>(`${this.baseURL}/admin/clinics`, {
      Authorization: `Bearer ${input.idToken}`,
    })
    const { status, data } = res
    handleError(status, data)
    console.log(data)
    return convertResponseToClinicPage(data)
  }

  getClinicCheckIns = async (input: GetClinicCheckInsInput): Promise<GetClinicCheckInsOutput> => {
    const url = new URL(`${this.baseURL}/admin/clinics/${input.clinicID}/check-ins`)
    const res = await this.httpClient.get<CheckInPageResponse>(url.toString(), {
      Authorization: `Bearer ${input.idToken}`,
    })
    const { status, data } = res
    handleError(status, data)
    return convertResponseToCheckInPage(data)
  }

  getClinicPatients = async (input: GetClinicPatientsInput): Promise<GetClinicPatientsOutput> => {
    const url = new URL(`${this.baseURL}/admin/clinics/${input.clinicID}/patients`)
    if (input.patientIDs) {
      url.searchParams.append('patient_ids', input.patientIDs.join(','))
    }
    const res = await this.httpClient.get<ClinicPatientPageResponse>(url.toString(), {
      Authorization: `Bearer ${input.idToken}`,
    })
    const { status, data } = res
    handleError(status, data)
    console.log(data)
    return convertResponseToClinicPatientPage(data)
  }

  getClinicPatient = async (input: GetClinicPatientInput): Promise<GetClinicPatientOutput> => {
    const res = await this.httpClient.get<ClinicPatientResponse>(
      `${this.baseURL}/admin/clinics/${input.clinicID}/patients/${input.patientUUID}`,
      {
        Authorization: `Bearer ${input.idToken}`,
      }
    )
    const { status, data } = res
    handleError(status, data)
    return convertResponseToClinicPatient(data)
  }

  getClinicPatientInterviews = async (
    input: GetClinicPatientInterviewsInput
  ): Promise<GetClinicPatientInterviewsOutput> => {
    const res = await this.httpClient.get<InterviewPageResponse>(
      `${this.baseURL}/admin/clinics/${input.clinicID}/patients/${input.patientUUID}/inquiries`,
      {
        Authorization: `Bearer ${input.idToken}`,
      }
    )
    const { status, data } = res
    handleError(status, data)
    console.log(data)
    return convertResponseToInterviewPage(data)
  }

  getStaffClinic = async (input: GetStaffClinicInput): Promise<GetStaffClinicOutput> => {
    const res = await this.httpClient.get<ClinicResponse>(
      `${this.baseURL}/admin/staffs/${input.uid}/clinics/${input.clinicID}`,
      {
        Authorization: `Bearer ${input.idToken}`,
      }
    )
    const { status, data } = res
    handleError(status, data)
    console.log(data)
    return convertResponseToClinic(data)
  }

  getStaffClinics = async (input: GetStaffClinicsInput): Promise<GetStaffClinicsOutput> => {
    const res = await this.httpClient.get<ClinicPageResponse>(
      `${this.baseURL}/admin/staffs/${input.uid}/clinics`,
      {
        Authorization: `Bearer ${input.idToken}`,
      }
    )
    const { status, data } = res
    handleError(status, data)
    return convertResponseToClinicPage(data)
  }

  addStaffToClinics = async (input: AddStaffToClinicsInput): Promise<void> => {
    const res = await this.httpClient.post(
      `${this.baseURL}/admin/staffs/${input.uid}/clinics`,
      {
        clinics: input.clinics.map((c) => ({
          clinic_id: c.clinicID,
        })),
      },
      {
        Authorization: `Bearer ${input.idToken}`,
      }
    )
    const { status, data } = res
    handleError(status, data)
  }

  getPatient = async (input: GetPatientInput): Promise<GetPatientOutput> => {
    const res = await this.httpClient.get<GetPatientOutput>(
      `${this.baseURL}/patients/uuid/${input.patientUUID}`,
      {
        Authorization: `Bearer ${input.idToken}`,
      }
    )
    const { status, data } = res
    handleError(status, data)
    return data
  }

  getPatientMessages = async (input: GetPatientMessageInput): Promise<GetPatientMessageOutput> => {
    const res = await this.httpClient.get<any>(
      `${this.baseURL}/patients/${input.patientUUID}/messages`,
      {
        Authorization: `Bearer ${input.idToken}`,
      }
    )
    const { status, data } = res
    handleError(status, data)
    return data
  }

  getPatients = async (input: GetPatientsInput): Promise<GetPatientsOutput> => {
    const params: Record<string, string> = {}
    if (input.patientUUIDs && input.patientUUIDs?.length > 0) {
      params['patient_uuids'] = input.patientUUIDs.join(',')
    }
    const res = await this.httpClient.get<any>(
      `${this.baseURL}/admin/patients`,
      {
        Authorization: `Bearer ${input.idToken}`,
      },
      params
    )
    const { status, data } = res
    handleError(status, data)
    return convertResponseToPatientPage(data)
  }

  unicast = async (input: UnicastInput): Promise<void> => {
    const res = await this.httpClient.post<any>(
      `${this.baseURL}/unicast`,
      {
        patient_uuid: input.patientUUID,
        messages: input.messages,
      },
      {
        Authorization: `Bearer ${input.idToken}`,
      }
    )
    const { status } = res
    handleError(status, undefined)
  }

  broadcast = async (input: BroadcastInput): Promise<void> => {
    const res = await this.httpClient.post<any>(
      `${this.baseURL}/admin/broadcasts`,
      {
        patient_uuids: input.patientUUIDs,
        content: input.content,
      },
      {
        Authorization: `Bearer ${input.idToken}`,
      }
    )
    const { status } = res
    handleError(status, undefined)
  }

  getBroadcastMessages = async (input: { idToken: string }): Promise<BroadcastMessagePage> => {
    const res = await this.httpClient.get<BroadcastMessagePageResponse>(
      `${this.baseURL}/admin/broadcasts`,
      {
        Authorization: `Bearer ${input.idToken}`,
      }
    )
    const { status, data } = res
    handleError(status, data)
    return convertResponseToBroadcastMessagePage(data)
  }

  getBroadcastHistories = async (
    input: GetBroadcastHistoriesInput
  ): Promise<GetBroadcastHistoriesOutput> => {
    const res = await this.httpClient.get<{
      broadcasts: Array<{
        id: string
        patient_uuids: Array<string>
        messages: Array<{
          type: 'text'
          text: string
        }>
        timestamp: number
      }>
    }>(`${this.baseURL}/broadcast/histories`, {
      Authorization: `Bearer ${input.idToken}`,
    })
    const { status, data } = res
    handleError(status, data)
    return {
      broadcasts: data.broadcasts.map((b) => ({
        id: b.id,
        patientUUIDs: b.patient_uuids,
        messages: b.messages,
        timestamp: b.timestamp,
      })),
    }
  }

  getPayment = async (input: GetPaymentInput): Promise<GetPaymentOutput> => {
    const res = await this.httpClient.get<{
      clinic_name: string
      department_name: string
      issued_date: string
      patient_id: string
      patient_name: string
    }>(
      `${this.baseURL}/payments?clinic_id=${input.clinicID}&invoice_number=${input.invoiceNumber}&patient_id=${input.patientID}`,
      {
        Authorization: `Bearer ${input.lineIDToken}`,
      }
    )
    const { status, data } = res
    handleError(status, data)
    console.log(data)
    return {
      clinicName: data.clinic_name,
      patientID: data.patient_id,
      patientName: data.patient_name,
      departmentName: data.department_name,
      issuedDate: data.issued_date,
    }
  }

  createPayment = async (input: CreatePaymentInput): Promise<CreatePaymentOutput> => {
    const res = await this.httpClient.post<{
      payment_intent: {
        id: string
        amount: number
        client_secret: string
        currency: string
      }
      payment_method: {
        id: string
        card: {
          brand: string
          country: string
          exp_month: string
          exp_year: string
          fingerprint: string
          funding: string
          last4: string
        }
      }
    }>(
      `${this.baseURL}/payments/create`,
      {
        id_token: input.lineIDToken,
        patient_id: input.patientID,
        clinic_id: input.clinicID,
        invoice_number: input.invoiceNumber,
      },
      {}
    )
    const { status, data } = res
    handleError(status, data)
    console.log(data)
    return {
      paymentIntent: {
        id: data.payment_intent.id,
        amount: data.payment_intent.amount,
        clientSecret: data.payment_intent.client_secret,
        currency: data.payment_intent.currency,
      },
      paymentMethod: {
        id: data.payment_method.id,
        card: {
          brand: data.payment_method.card.brand,
          country: data.payment_method.card.country,
          expMonth: data.payment_method.card.exp_month,
          expYear: data.payment_method.card.exp_year,
          fingerprint: data.payment_method.card.fingerprint,
          funding: data.payment_method.card.funding,
          last4: data.payment_method.card.last4,
        },
      },
    }
  }

  confirmPayment = async (input: ConfirmPaymentInput): Promise<ConfirmPaymentOutput> => {
    const res = await this.httpClient.post<{
      message: string
    }>(
      `${this.baseURL}/payments/confirm`,
      {
        id_token: input.lineIDToken,
        clinic_id: input.clinicID,
        patient_id: input.patientID,
        invoice_number: input.invoiceNumber,
        payment_method_id: input.paymentMethodID,
        payment_intent_id: input.paymentIntentID,
      },
      {}
    )
    const { status, data } = res
    handleError(status, data)
    console.log(data)
  }

  getPatientMe = async (input: GetPatientMeInput): Promise<GetPatientMeOutput> => {
    const res = await this.httpClient.get<{
      first_name: string
      last_name: string
      address: string
      city: string
      phone_number: string
      prefecture: string
      zipcode: string
    }>(`${this.baseURL}/patients/me`, {
      Authorization: `Bearer ${input.lineIDToken}`,
    })
    const { status, data } = res
    handleError(status, data)
    console.log(data)
    return {
      firstName: data.first_name,
      lastName: data.last_name,
      address: data.address,
      city: data.city,
      phoneNumber: data.phone_number,
      prefecture: data.prefecture,
      zipcode: data.zipcode,
    }
  }

  uploadImage = async (input: UploadImageInput): Promise<UploadImageOutput> => {
    const formData = new FormData()
    formData.append('file', input.imageFile)
    formData.append('fileName', input.fileName)

    const res = await this.httpClient.post<UploadImageOutput>(
      `${this.baseURL}/documents/fileupload`,
      formData,
      {
        Authorization: `Bearer ${input.lineIDToken}`,
      }
    )
    const { status, data } = res
    handleError(status, data)

    return { fileKey: data.fileKey }
  }

  requestTransferToFamily = async (
    input: RequestTransferToFamilyInput
  ): Promise<RequestTransferToFamilyOutput> => {
    const res = await this.httpClient.post<{
      message: string
    }>(
      `${this.baseURL}/api/patients/transfer-to-family`,
      {
        last_name_kana: input.lastNameKana,
        first_name_kana: input.firstNameKana,
        birthday: input.birthday,
        gender: input.gender,
        phone_number: input.phoneNumber,
      },
      {
        Authorization: `Bearer ${input.lineIDToken}`,
      }
    )
    const { status, data } = res

    return {
      status,
      data,
    }
  }

  sendWebhook = async <T>(input: T): Promise<void> => {
    const res = await this.httpClient.post(`${this.baseURL}/webhook`, input)
    const { status, data } = res
    handleError(status, data)
  }
}
