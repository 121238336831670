import { Container, createTheme, Grid, ThemeProvider, Typography, Button } from '@mui/material'
import { Logo } from '../../common/components/Logo'
import { useConfig } from '../../common/contexts'

const theme = createTheme({
  typography: {
    fontSize: 11,
  },
})

export const UpdateCompletePage = () => {
  const { config } = useConfig()
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Grid container direction={'column'} alignItems="center" rowSpacing={0.5} sx={{ my: 1 }}>
          <Grid item>
            <Logo />
          </Grid>
          <Grid item>
            <Typography variant="h5">変更完了</Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} sx={{ my: 1 }}>
            <Typography>患者様の情報の変更が完了しました。</Typography>
          </Grid>
          <Grid item xs={12} sx={{ my: 1 }}>
            <Button
              href={config.lineChannelURL}
              size="large"
              variant="outlined"
              fullWidth
              sx={{
                borderColor: '#00B900',
                color: '#00B900',
                fontWeight: 700,
                '&:active': {
                  borderColor: '#00B900',
                },
                '&:focus': {
                  borderColor: '#00B900',
                },
              }}
            >
              LINEに戻る
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}
