import { Box } from '@mui/material'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useTranslation } from 'react-i18next'

export type DatePickerProps = {
  value: Date | null
  onChange: (newValue: Date | null) => void
}

export function DatePicker({ value, onChange }: DatePickerProps) {
  const { t } = useTranslation()
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ m: 2, width: '25ch' }}>
        <MuiDatePicker label={t('Select Date')} value={value} onChange={onChange} />
      </Box>
    </LocalizationProvider>
  )
}
