import { Box } from '@mui/material'
import React from 'react'
import { Consultation } from '../types'
import { DialogProps } from './Dialogs/types'
import { DefaultCardProps } from './Cards'

export const CardModal = React.memo(
  ({
    consultation,
    receipt,
    handleToggleBlocked,
    handleDownloadReceipt,
    handleGenerateReceipt,
    receiptError,
    handleDispatchConsultationAction,
    handleReserveWithImpersonation,
    DialogComponent,
    CardComponent,
  }: {
    consultation: Consultation
    receipt: { encodedPDF: string } | null
    handleToggleBlocked: (params: {
      consultationID: string
      blocked: boolean
      blockedReason?: string
    }) => void
    handleDownloadReceipt: (params: { consultationID: string }) => void
    handleGenerateReceipt: (params: { consultationID: string }) => void
    receiptError: string | null
    handleDispatchConsultationAction: (params: { consultationID: string; action: string }) => void
    handleReserveWithImpersonation: (params: { patientUUID: string }) => void
    DialogComponent: React.ForwardRefExoticComponent<
      Omit<DialogProps, 'ref'> & React.RefAttributes<HTMLDivElement>
    >
    CardComponent: React.MemoExoticComponent<
      ({ onClick, consultation, component }: DefaultCardProps) => JSX.Element
    >
  }) => {
    return (
      <Box key={consultation.consultationID} sx={{ pb: 1 }}>
        <DialogComponent
          consultation={consultation}
          receipt={receipt}
          handleToggleBlocked={handleToggleBlocked}
          handleDownloadReceipt={handleDownloadReceipt}
          handleGenerateReceipt={handleGenerateReceipt}
          receiptError={receiptError}
          handleDispatchConsultationAction={handleDispatchConsultationAction}
          handleReserveWithImpersonation={handleReserveWithImpersonation}
        >
          {({ handleClick }) => <CardComponent consultation={consultation} onClick={handleClick} />}
        </DialogComponent>
      </Box>
    )
  }
)
