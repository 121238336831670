import { Context, FallbackFunction, IFeatureFlag } from './interface'
import { Strategy } from './strategy'
import { createFallbackFunction } from './utils'

export class DefaultFeatureFlag implements IFeatureFlag {
  isEnabled = (
    name: string,
    context: Context = {},
    fallback?: FallbackFunction | boolean
  ): boolean => {
    return false
  }
}

export class FeatureFlag implements IFeatureFlag {
  strategies: Array<Strategy>

  constructor({ strategies }: { strategies: Array<Strategy> }) {
    this.strategies = strategies
  }

  isEnabled = (
    name: string,
    context: Context = {},
    fallback?: FallbackFunction | boolean
  ): boolean => {
    for (const strategy of this.strategies) {
      if (strategy.isEnabled(name, context, fallback)) {
        return true
      }
    }
    const fallbackFn = createFallbackFunction(fallback)
    return fallbackFn(name, context)
  }
}
