import { createContext, useEffect, useContext, useState } from 'react'

export const DrawerToggleContext = createContext<{
  open: boolean
  setOpen: (v: boolean) => void
}>({
  open: false,
  setOpen: (v: boolean) => console.log(`default setOpen is called, ${v}`),
})

export const DrawerToggleProvider = ({ children }: { children: any }) => {
  const localStorageDrawerOpenKey = 'drawerOpen'

  const defaultOpen = localStorage.getItem(localStorageDrawerOpenKey) === 'true'

  const [open, setOpen] = useState(defaultOpen)

  useEffect(() => {
    localStorage.setItem(localStorageDrawerOpenKey, JSON.stringify(open))
  }, [open])

  return (
    <DrawerToggleContext.Provider value={{ open, setOpen }}>
      {children}
    </DrawerToggleContext.Provider>
  )
}

export const useDrawerToggle = () => {
  const context = useContext(DrawerToggleContext)
  if (context.open === undefined) {
    throw new Error('need to use useDrawerToggle inside DrawerToggleProvider')
  }
  if (context.setOpen === undefined) {
    throw new Error('need to use useDrawerToggle inside DrawerToggleProvider')
  }
  return context
}
