import { useClinics } from '../hooks/useClinics'
import { useSearchParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { styled, Theme, ThemeProvider, useTheme } from '@mui/material/styles'
import { Toolbar, CssBaseline, Box, AppBar, createTheme } from '@mui/material'
import { keyframes } from '@mui/system'
import { useCallback, useEffect, useState, useRef } from 'react'
import { useAuth } from '../../common/hooks/useAuth'
import { endOfDay, startOfDay } from 'date-fns'
import { useDeps } from '../../common/contexts/Deps'
import useMediaQuery from '@mui/material/useMediaQuery'

const createInnerTheme = (theme: Theme) =>
  createTheme({
    ...theme,
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: '8rem',
          },
          h1: {
            fontSize: '9.5rem',
            fontWeight: 800,
            lineHeight: 1.6,
          },
          h2: {
            fontSize: '8rem',
            fontWeight: 600,
            lineHeight: 1.3,
          },
          h3: {
            fontSize: '4rem',
            fontWeight: 600,
            color: '#ffffff',
          },
        },
      },
    },
  })

export function SignageTempate({
  children,
  clinicName,
}: {
  children: any
  clinicName: string | undefined
}) {
  const theme = useTheme()

  return (
    <ThemeProvider theme={createInnerTheme(theme)}>
      <Box
        sx={{
          height: '100vh',
          overflow: 'hidden',
          display: 'flex',
          flexFlow: 'column',
          backgroundColor: '#585858',
        }}
      >
        <CssBaseline />
        <AppBar sx={{ position: 'static', height: '200px' }}>
          <Toolbar sx={{ height: '200px' }}>
            <Typography variant="h1" sx={{ flexGrow: 1 }}>
              お会計の準備ができました
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ height: 'calc( 100% - 300px)' }}>{children}</Box>
        <Typography variant="h3" sx={{ padding: '0 50px', textAlign: 'right' }}>
          {clinicName}
        </Typography>
      </Box>
    </ThemeProvider>
  )
}

const NumberBox = styled('div')({
  marginLeft: '-5px',
  marginTop: '-5px',
  width: '100%',
  height: '350px',
  lineHeight: '350px',
  border: 'solid 5px #EBEBEB',
  color: '#FFFFFF',
  fontSize: '15rem',
  fontWeight: 800,
  textAlign: 'center',
})

const blink = keyframes`
  0%  { background-color: rgba(0,0,0,0); }
  50% { background-color: rgba(234,85,4,1.0); }
`

const BlinkedNumberBox = styled(NumberBox)({
  animation: `${blink} 1.5s steps(1,start) 5`,
})

type ReceptionNumber = {
  number: string | number
  hasSokushin: boolean
  isNew: boolean
}

type PanelProps = {
  receptionNumbers: ReceptionNumber[]
  maxNumberOfItems: number
  maxNumberOfItemsInRow: number
  message: string
}

export function Panel(props: PanelProps) {
  const [posNeg, setPosNeg] = useState(1) // posNeg means "positive and negative"
  const [displayList, setDisplayList] = useState<ReceptionNumber[]>([])

  const tick = useCallback(() => {
    setPosNeg(posNeg * -1)
  }, [posNeg, setPosNeg])

  useEffect(() => {
    const timer = setInterval(tick, 10000)
    return () => clearInterval(timer)
  }, [tick])

  const pageRef = useRef(1)
  const listRef = useRef<ReceptionNumber[]>([])

  useEffect(() => {
    const numInPages = props.maxNumberOfItems

    // on first page
    if (pageRef.current === 1) {
      // save the latest list to display
      // it is held untill all pages are displayed
      listRef.current = extractLatestReceptionNumbers(listRef.current, props.receptionNumbers)
    }

    // culcurate the number of pages
    const numOfPages = Math.ceil(listRef.current.length / numInPages)

    setDisplayList(
      listRef.current.slice((pageRef.current - 1) * numInPages, pageRef.current * numInPages)
    )

    // on the last page
    if (numOfPages <= pageRef.current) {
      // go to the first page
      pageRef.current = 1
    } else {
      // go to the next page
      pageRef.current++
    }
  }, [posNeg, props.maxNumberOfItems])

  return (
    <div style={{ padding: '20px 50px', height: '100%' }}>
      <div style={{ paddingBottom: '21px', color: '#FFF' }}>
        <Typography variant="h2">{props.message}</Typography>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill,minmax(calc(100% / ${props.maxNumberOfItemsInRow}), 1fr)`,
        }}
      >
        {displayList.map((reception) => {
          return reception.isNew ? (
            <BlinkedNumberBox key={`bnb${reception.number}`}>{reception.number}</BlinkedNumberBox>
          ) : (
            <NumberBox key={`nb${reception.number}`}>{reception.number}</NumberBox>
          )
        })}
      </div>
    </div>
  )
}

const extractLatestReceptionNumbers = (
  currrentReceptionNumbers: ReceptionNumber[],
  newReceptionNumbers: ReceptionNumber[]
): ReceptionNumber[] => {
  return newReceptionNumbers.map((newOne) => {
    return {
      ...newOne,
      isNew: !currrentReceptionNumbers.some((cur) => cur.number === newOne.number),
    }
  })
}

export function SignagePage() {
  const [searchParams] = useSearchParams()
  const clinicID = searchParams.get('clinic_id')
  const { currentClinic } = useClinics({ clinicID })
  const { currentUser, getIDToken } = useAuth()
  const deps = useDeps()
  const [receptionNumbers, setReceptionNumbers] = useState<ReceptionNumber[]>([])

  // screen width
  const over2160px = useMediaQuery('(min-width:2160px)')
  const over3840px = useMediaQuery('(min-width:3638px)')
  const maxNumOfItemsInRow = over2160px && !over3840px ? 3 : 6

  const fetchReceptionNumber = useCallback(async () => {
    if (currentUser && currentClinic) {
      const token = await getIDToken(currentUser)
      const utcnow = new Date()
      const from = startOfDay(utcnow).toISOString()
      const to = endOfDay(utcnow).toISOString()
      try {
        return (
          await deps.api.getConsultations({ clinicID: currentClinic.clinicID, token, from, to })
        ).entries
          .filter(
            (consultation) =>
              consultation.status === 'in payment' &&
              consultation.patient.hasLineAccount &&
              !consultation.blocked
          )
          .map((consultation): ReceptionNumber => {
            return {
              number: parseInt(consultation.receptionNumber),
              hasSokushin: consultation.patient.hasSokushin,
              isNew: true,
            }
          })
      } catch (e) {
        console.error(e)
      }
    }
    return []
  }, [currentUser, currentClinic])

  useEffect(() => {
    let delay: NodeJS.Timeout
    const func = () => {
      delay = setTimeout(() => {
        fetchReceptionNumber().then((receptionNumbers) => {
          setReceptionNumbers(receptionNumbers)
        })
      }, 6000)
    }
    func()
    return () => delay && clearTimeout(delay)
  })

  return (
    <SignageTempate clinicName={currentClinic?.displayName}>
      <Box sx={{ height: '50%' }}>
        <Panel
          message="LINEの即診からお支払いください"
          maxNumberOfItems={12}
          maxNumberOfItemsInRow={maxNumOfItemsInRow}
          receptionNumbers={receptionNumbers.filter((v) => v.hasSokushin)}
        />
      </Box>
      <Box sx={{ height: '50%' }}>
        <Panel
          message="番号札をお持ちになり受付にお越しください"
          maxNumberOfItems={12}
          maxNumberOfItemsInRow={maxNumOfItemsInRow}
          receptionNumbers={receptionNumbers.filter((v) => !v.hasSokushin)}
        />
      </Box>
    </SignageTempate>
  )
}
