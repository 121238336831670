import { ThemeProvider, createTheme } from '@mui/material/styles'
import Container from '@mui/material/Container'
import { RegistrationForm } from '../components/RegistrationForm'

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#da5e29',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        fontSize: 11,
      },
    },
  },
  typography: {
    fontSize: 11,
  },
})

export const RegistrationPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <RegistrationForm />
      </Container>
    </ThemeProvider>
  )
}
