'use client'

import React, { useEffect, useState } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import useFindAddress, { Address } from '../hooks/useFindAddress'
import { useFormContext } from 'react-hook-form'
export * from '../hooks/useFindAddress'

const postalCodeRules = {
  pattern: {
    value: /^[0-9]{7}$/,
    message: '数字のみを7文字で入力してください',
  },
}

type PostCodeFieldProps = {
  name?: string
  disabled?: boolean
  onSuccess: (address: Address | null) => void
  onFailure: (message: string | null) => void
} & TextFieldProps

const PostalCodeField: React.FC<PostCodeFieldProps> = React.forwardRef((props, ref) => {
  const { disabled, onSuccess, onFailure } = props
  const [postalCode, setPostalCode] = useState('')
  const {
    register,
    trigger,
    formState: { errors },
  } = useFormContext()
  const { address, error: addressError } = useFindAddress(postalCode)
  const name = props.name || 'postalCode'

  useEffect(() => {
    ;(async () => {
      if (addressError || address) {
        await trigger(name)
      }
      if (address) {
        onSuccess(address)
      } else if (addressError) {
        onFailure(addressError)
      }
    })()
  }, [address, addressError])

  const rules = {
    required: postalCodeRules.pattern.message,
    ...postalCodeRules,
    validate: () => {
      if (addressError) {
        return '該当する住所が見つかりません'
      }
      return true
    },
  }

  const registerReturn = register(name, rules)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostalCode(e.target.value)
    if (registerReturn.onChange) {
      registerReturn.onChange(e)
    }
  }

  return (
    <TextField
      {...registerReturn}
      onChange={handleChange}
      error={!!errors[name]}
      disabled={disabled}
      inputRef={ref}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        maxLength: 7,
        'aria-label': name,
      }}
      sx={{
        '.Mui-disabled': {
          background: '#eaeaea',
        },
      }}
    />
  )
})

export default PostalCodeField
