import { Typography } from '@mui/material'
import { FieldErrors } from 'react-hook-form'

type ErrorMessageProps = {
  errors: FieldErrors
  name: string
}

export const ErrorMessage = (props: ErrorMessageProps) => {
  const error = props.errors[props.name]

  return (
    <Typography>
      {error && (
        <span style={{ color: 'red', fontWeight: 700, margin: 0 }} role="alert">
          {error.message as string}
        </span>
      )}
    </Typography>
  )
}
