import { useQRCode } from 'next-qrcode'

export type QRCodeImageProps = {
  value: string
}

export function QRCodeImage({ value }: QRCodeImageProps) {
  const { Canvas } = useQRCode()

  return (
    <Canvas
      text={value}
      options={{
        type: 'image/jpeg',
        quality: 0.92,
        errorCorrectionLevel: 'Q',
        margin: 3,
        scale: 4,
        width: 300,
      }}
    />
  )
}
