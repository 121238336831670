import { Container, createTheme, Grid, ThemeProvider, Typography, Button } from '@mui/material'
import { Logo } from '../../common/components/Logo'
import { useConfig } from '../../common/contexts'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormValue } from '../components/TransferToFamilyForm'

const theme = createTheme({
  palette: {
    primary: {
      main: '#EA5504',
    },
  },
  typography: {
    fontSize: 11,
  },
})

export const TransferToFamilyFailurePage = () => {
  const search = useLocation().search
  const queryParams = new URLSearchParams(search)

  const location = useLocation()
  const formValue = location.state as FormValue

  const navigate = useNavigate()

  const { config } = useConfig()
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Grid container direction={'column'} alignItems="center" rowSpacing={0.5} sx={{ my: 1 }}>
          <Grid item sx={{ mb: 3 }}>
            <Logo />
          </Grid>
          <Grid item>
            <Typography variant="h5" fontWeight={700} align="center" color="primary">
              クリニックプラスでの登録が
            </Typography>
            <Typography variant="h5" fontWeight={700} align="center" color="primary">
              確認できませんでした。
            </Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} sx={{ my: 1 }}>
            <Typography>
              お手数ですが、入力された氏名（カナ）、生年月日、性別、移行元の電話番号が正しいかご確認の上、登録をお願いします
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ my: 1 }}>
            <Button
              variant="contained"
              onClick={() => {
                navigate('/patients/transfer-to-family?' + queryParams.toString(), {
                  replace: true,
                  state: formValue,
                })
              }}
              size="large"
              fullWidth
            >
              もう一度入力する
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ my: 1 }}>
            <Button
              href={config.lineChannelURL}
              size="large"
              variant="outlined"
              fullWidth
              sx={{
                borderColor: '#00B900',
                color: '#00B900',
                fontWeight: 700,
                '&:active': {
                  borderColor: '#00B900',
                },
                '&:focus': {
                  borderColor: '#00B900',
                },
              }}
            >
              公式LINEに戻る
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}
