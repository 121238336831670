import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Inputs = {
  patientID: string
  departmentName: string
  receptionNumber: string
  receptionDate: string
  receptionTime: string
}

type OnSubmit = (data: {
  clinicID: string
  printingParams: Record<string, string>
}) => Promise<void>

export function SubmitPrintJobDialog(props: {
  clinicID: string
  onSubmit: OnSubmit
  WrapperComponent?: any
}) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    props.onSubmit({
      clinicID: props.clinicID,
      printingParams: {
        patient_id: data.patientID,
        department_name: data.departmentName,
        reception_number: data.receptionNumber,
        reception_date: data.receptionDate,
        reception_time: data.receptionTime,
      },
    })
    handleClose()
  }

  console.log(errors)

  const WrapperComponent = props.WrapperComponent || Button

  return (
    <>
      <WrapperComponent onClick={handleClickOpen} color="primary" variant="contained">
        {t('Submit Print Job')}
      </WrapperComponent>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="action-dialog-title"
        aria-describedby="action-dialog-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="action-dialog-title">{t('Submit Print Job')}</DialogTitle>
          <DialogContent>
            <Box p={2}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <TextField label={t('Patient ID')} fullWidth {...register('patientID')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Department Name')}
                    fullWidth
                    {...register('departmentName')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Reception Number')}
                    fullWidth
                    {...register('receptionNumber')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField label={t('Reception Date')} fullWidth {...register('receptionDate')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField label={t('Reception Time')} fullWidth {...register('receptionTime')} />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" sx={{ color: '#333' }}>
              {t('Cancel')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('Create')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
