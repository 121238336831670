import { isEqual } from 'lodash'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ErrorMessage } from '../../../common/types'
import { createContext, useContext } from 'react'
import { Consultation } from '../../types'
import { toErrorMessage } from '../../../common/utils/errors'
import { APIClient } from '../../../../api'

type ConsultationsState = {
  entries: Array<Consultation>
  loading: boolean
  error: ErrorMessage | null
}

const initialState: ConsultationsState = {
  entries: [],
  loading: true,
  error: null,
}

type ThunkAPI = {
  extra: {
    api: APIClient
  }
}

const getConsultations = createAsyncThunk<
  { entries: Array<Consultation> },
  {
    token: string
    clinicID: string
    from?: string
    to?: string
  },
  ThunkAPI
>('consultations/getConsultations', async ({ token, clinicID, from, to }, thunkAPI) => {
  const { api } = thunkAPI.extra

  try {
    const res = await api.getConsultations({
      token: token,
      clinicID,
      from,
      to,
    })
    return res
  } catch (e) {
    return thunkAPI.rejectWithValue(toErrorMessage(e))
  }
})

const patchConsultation = createAsyncThunk<
  Consultation,
  {
    token: string
    consultationID: string
    blocked: boolean
    blockedReason?: string
  },
  ThunkAPI
>(
  'consultations/patchConsultation',
  async ({ token, consultationID, blocked, blockedReason }, thunkAPI) => {
    const { api } = thunkAPI.extra

    try {
      const res = await api.patchConsultation({
        token: token,
        consultationID,
        blocked,
        blockedReason,
      })
      return res
    } catch (e) {
      return thunkAPI.rejectWithValue(toErrorMessage(e))
    }
  }
)

const dispatchConsultationAction = createAsyncThunk<
  { consultationID: string; status: string },
  {
    token: string
    consultationID: string
    action: 'checkout' | 'notify_checkout'
  },
  ThunkAPI
>(
  'consultations/dispatchConsultationAction',
  async ({ token, consultationID, action }, thunkAPI) => {
    const { api } = thunkAPI.extra

    try {
      const res = await api.dispatchConsultationAction({
        token: token,
        consultationID,
        action,
      })
      return res
    } catch (e) {
      return thunkAPI.rejectWithValue(toErrorMessage(e))
    }
  }
)

const consultationsSlice = createSlice({
  name: 'consultations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsultations.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getConsultations.fulfilled, (state, action) => {
        state.loading = false
        // update only if entries are different
        if (!isEqual(state.entries, action.payload.entries)) {
          state.entries = action.payload.entries
        }
        state.error = null
      })
      .addCase(getConsultations.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(patchConsultation.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(patchConsultation.fulfilled, (state, action) => {
        state.loading = false
        const idx = state.entries.findIndex(
          (e) => e.consultationID === action.payload.consultationID
        )
        if (idx >= 0) {
          state.entries = [
            ...state.entries.slice(0, idx),
            action.payload,
            ...state.entries.slice(idx + 1),
          ]
        }
        state.error = null
      })
      .addCase(patchConsultation.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })

    builder
      .addCase(dispatchConsultationAction.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(dispatchConsultationAction.fulfilled, (state, action) => {
        state.loading = false
        const idx = state.entries.findIndex(
          (e) => e.consultationID === action.payload.consultationID
        )
        if (idx >= 0) {
          state.entries = [
            ...state.entries.slice(0, idx),
            {
              ...state.entries[idx],
              status: action.payload.status,
            },
            ...state.entries.slice(idx + 1),
          ]
        }
        state.error = null
      })
      .addCase(dispatchConsultationAction.rejected, (state, action) => {
        state.error = action.payload as ErrorMessage
        state.loading = false
      })
  },
})

export const consultationsActions = {
  getConsultations,
  patchConsultation,
  dispatchConsultationAction,
}

export const consultationsSliceReducer = consultationsSlice.reducer

export const ConsultationsActionsContext = createContext(consultationsActions)

export const useConsultationsActions = () => useContext(ConsultationsActionsContext)

export const ConsultationsActionsProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ConsultationsActionsContext.Provider value={consultationsActions}>
      {children}
    </ConsultationsActionsContext.Provider>
  )
}
